import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Typography, LinearProgress } from '@mui/material'
import { Container } from '@mui/system'
import { getDiscordToken } from '../../api'

function DiscordLogin() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading('loading')
    ;(async () => {
      try {
        const { redirectUrl } = await getDiscordToken(searchParams.get('sso'), searchParams.get('sig'))
        setLoading('false')
        if (redirectUrl) window.location.replace(redirectUrl)
      } catch (error) {
        setLoading('error')
      }
    })()
  }, [])

  return (
    <Box className='flex-center-center w-100vh h-100vh'>
      <Container className='flex-col gap-4 flex-center-center' maxWidth='sm'>
        {loading === 'loading' && (
          <>
            <Typography variant='h3' className='font-bold color-grey'>
              Loading
            </Typography>
            <Typography variant='h5'>Waiting for getting the token</Typography>
            <LinearProgress className='w-100' />
          </>
        )}
        {loading === 'error' && <Box>Unable to login, please contact support@viasocket.com</Box>}
      </Container>
    </Box>
  )
}

export default DiscordLogin
