import { createSlice } from '@reduxjs/toolkit'

// // reducer imports
import { initialState, reducers, extraReducers } from './projectsReducer'

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers,
  extraReducers
})

export const { add, remove, update, removeOne, changeStatus } = projectsSlice.actions

export default projectsSlice.reducer
