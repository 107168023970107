import React, { useEffect, useState } from 'react'
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useCustomSelector } from '../../../../../utils/deepCheckSelector'
import { ParamsEnums } from '../../../../../enums'
import addUrlDataHoc from '../../../../../hoc/addUrlDataHoc.tsx'
import CustomMarkdown from '../../../../Markdown/CustomMarkdown'

function splitByInput(str) {
  const inputStart = '{{input}}'
  const inputIndex = str.indexOf(inputStart)

  if (inputIndex === -1) {
    // "{{input}}" not found in the string
    return null
  }

  const leftPart = str.slice(0, inputIndex)
  const rightPart = str.slice(inputIndex + inputStart.length)

  return {
    leftPart,
    rightPart
  }
}

/**
 * Splits the input string into two parts based on the presence of "{{input}}" marker.
 *
 * @param {string} str - The input string to be split.
 * @returns {(Object|null)} - An object with 'leftPart' and 'rightPart' properties if "{{input}}" is found, otherwise null.
 */
function BasicInput(props) {
  const {
    field: { key, inputFormat, help, label, required, type, placeholder },
    sectionIdOrScriptId,
    versionIdOrStepId,
    tabName
  } = props
  const authData = useCustomSelector(
    (state) => state.stepsDataV3?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData.selectedValues.authData
  )
  const [value, setValue] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  // const dispatch = useDispatch()
  const dependency = authData ? authData[key] && '' : null

  useEffect(() => {
    setValue(authData ? authData[key] || '' : '')
  }, [dependency])

  let leftPart = ''
  let rightPart = ''

  // const setOneFieldData = (key, value) => {
  //   const prevAuth = { ...authData }
  //   // Object.preventExtensions(prevAuth);
  //   prevAuth[key] = value
  //   dispatch(setAuthData(prevAuth))
  //   // props.setAuthData(prevAuth);
  // }
  if (inputFormat) {
    const splited = splitByInput(inputFormat)
    leftPart = splited.leftPart
    rightPart = splited.rightPart
  }

  const handleInputTextChange = async (event) => {
    props?.setAuthData((prevState) => ({ ...prevState, [props?.field?.key]: event.target.value }))
    // const final = event.target.value
    setValue(event.target.value)
    // setOneFieldData(props?.field.key, final)
  }

  return (
    <Box className='w-100 mb-2 '>
      <Box className='AuthInputComponentForPlugin w-100 flex-col gap-1'>
        <Typography> {required ? `${label}*` : label}</Typography>
        {inputFormat && <p className='inputFieldDomainForPlugin'>{leftPart}</p>}
        <TextField
          size='small'
          width='medium'
          key={key}
          placeholder={required && placeholder ? `${placeholder}*` : placeholder || ''}
          type={type?.toLowerCase() === 'password' && !showPassword ? 'password' : 'text'}
          value={value}
          className='w-100'
          onChange={async (e) => {
            handleInputTextChange(e)
          }}
          autoComplete='new-password'
          InputProps={
            type?.toLowerCase() === 'password' && {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword((prev) => !prev)} edge='end' className='mr-1'>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }
          }
        />
        {inputFormat && <p className='inputFieldDomainForPlugin'>{rightPart}</p>}
      </Box>
      {help && <CustomMarkdown help={help} />}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(BasicInput), [ParamsEnums.tabName, ParamsEnums.versionIdOrStepId, ParamsEnums.sectionIdOrScriptId])
)
