import axios from '../../interceptor/interceptor'
// import { actionDataType, pluginDataType } from '../../store/plugin/allPluginsData/allPluginReduxType.ts'
import { errorToast } from '../../components/customToast'

const URL = process.env.REACT_APP_API_BASE_URL

export async function getPluginApi(currentOrgId: string, categories: string[] = [], filteredPlugins: string[] = []) {
  try {
    let categoryFilter = ''
    if (categories?.length > 0) {
      const categoriesString = categories.map((category) => `category@>'["${category}"]'`).join(' or ')
      categoryFilter = `categoryFilter=${categoriesString}`
    }
    let pluginFilter = ''
    if (filteredPlugins?.length > 0) {
      pluginFilter = `&pluginFilter=${JSON.stringify(filteredPlugins)}`
    }
    return (await axios.get(`${URL}/dbdash/getplugin?${categoryFilter}${pluginFilter}`)).data?.data?.data?.rows || []
  } catch (error: any) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

export async function getInitialPluginWithActionsApi(filteredActions: string[] = []) {
  try {
    let pluginFilter = ''
    if (filteredActions?.length > 0) {
      pluginFilter = `&actionFilter=${JSON.stringify(filteredActions)}`
    }
    return (await axios.get(`${URL}/dbdash/getpluginsWithEvents?${pluginFilter}`)).data?.data || []
  } catch (error: any) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}
