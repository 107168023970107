import { getPluginData, isAuthValid } from '../../api/index'
import { getPluginApi } from '../../api/pluginApis/pluginApis.ts'
import { BlockTypes } from '../../enums'
import { authDataType } from '../../types/Invocation.ts'
import { actionDataType, pluginDataType } from '../../types/Plugin.ts'
import { getInfoParamtersFromUrl } from '../../utils/utilities'

export async function getPluginsQueryFn(
  currentOrgId: string,
  getPluginsWithTriggers: boolean,
  categories: string[] = [],
  filteredPlugins: string[] = []
): Promise<{ [key: string]: pluginDataType }> {
  try {
    let response: pluginDataType[] = await getPluginApi(currentOrgId, categories, filteredPlugins)
    if (getPluginsWithTriggers) {
      response = response.filter((obj: pluginDataType) => obj.istriggeravailable === true)
    }
    const plugins: { [key: string]: pluginDataType } = response.reduce(
      (pluginsObject: { [key: string]: pluginDataType }, item: pluginDataType) => {
        pluginsObject[item.rowid] = item
        return pluginsObject
      },
      {}
    )
    return plugins
  } catch (error) {
    return {}
  }
}

const stepTypeToActionTypesMap = {
  [BlockTypes.TRIGGER]: [BlockTypes.TRIGGER],
  actions: ['action', 'custom_action'],
  both: ['action', 'custom_action', 'trigger']
}
/**
 * Asynchronously fetches actions based on the provided plugin ID, step type, and table name.
 * Filters actions based on their status and type, and organizes them into a dictionary keyed by their row ID.
 *
 * @param {string} pluginId - The ID of the plugin for which actions are being fetched.
 * @param {string} currentStepType - The current step type, used to determine the type of actions to fetch.
 * @param {string} tableName - The name of the table from which to fetch actions.
 * @returns {Promise<{[key: string]: actionDataType}>} A promise that resolves to an object mapping row IDs to action data.
 */
export async function getActionsQueryFn(
  pluginId: string,
  currentStepType: string,
  tableName: string = 'action',
  allowedEvents: string[] = []
): Promise<{ [key: string]: actionDataType }> {
  try {
    const filter = {}
    const { orgId } = getInfoParamtersFromUrl()
    if (tableName === 'action') {
      // filter['status'] = 'published',
      filter['orgId'] = orgId
    }
    filter['type'] = stepTypeToActionTypesMap[currentStepType]
    filter['pluginrecordid'] = pluginId || ''
    if (allowedEvents?.length) filter['rowid'] = allowedEvents
    const response = await getPluginData(filter, tableName)
    const actions: { [key: string]: actionDataType } = response.reduce(
      (actionObject: { [key: string]: actionDataType }, item: actionDataType) => {
        actionObject[item.rowid] = item
        return actionObject
      },
      {}
    )
    return actions
  } catch (error) {
    return {}
  }
}

/**
 * Asynchronously fetches actions or triggers based on the provided plugin ID and table name.
 * Filters the results based on their status (if the table is 'action', only 'published' items are fetched)
 * and type (either 'action', 'custom_action', or 'trigger'). The results are then organized into a dictionary
 * keyed by their row ID.
 *
 * @param {string} pluginId - The ID of the plugin for which actions or triggers are being fetched.
 * @param {string} tableName - The name of the table from which to fetch actions or triggers.
 * @returns {Promise<{[key: string]: actionDataType}>} A promise that resolves to an object mapping row IDs to action data.
 */

export async function getActionsOrTriggersQueryForAuthFn(
  pluginId: string,
  tableName: string,
  addRandomId: boolean
): Promise<{ [key: string]: actionDataType }> {
  try {
    const filter = { addRandomId: addRandomId ? 'true' : 'false' }
    if (tableName === 'action') {
      filter['status'] = 'published'
    }
    filter['type'] = ['action', 'custom_action', 'trigger']
    filter['pluginrecordid'] = pluginId || ''
    const response = await getPluginData(filter, tableName)
    const actions: { [key: string]: actionDataType } = response.reduce(
      (actionObject: { [key: string]: actionDataType }, item: actionDataType) => {
        actionObject[item.rowid] = item
        return actionObject
      },
      {}
    )
    return actions
  } catch (error) {
    return {}
  }
}

/**
 * Asynchronously fetches a single plugin's data based on the provided filter criteria.
 *
 * @param {object} plugin - An object containing the filter criteria and other necessary details.
 * @param {string} plugin.filterField - The field name to filter by in the database.
 * @param {string} plugin.plugId - The ID of the plugin to fetch.
 * @param {string} plugin.tableName - The name of the table from which to fetch the plugin data.
 * @param {boolean} plugin.isUserOnDH - Indicates if the user is on DataHub (not used in the current function logic).
 * @returns {Promise<authDataType|[]>} A promise that resolves to the first plugin data fetched or an empty array if an error occurs.
 */
export async function getOnePlugin(
  plugin: { filterField: string; plugId: string; tableName: string; isUserOnDH: boolean },
  addRandomId: boolean
) {
  try {
    const response: authDataType[] = await getPluginData(
      {
        [plugin.filterField]: plugin.plugId ?? '',
        addRandomId: addRandomId ? 'true' : 'false'
      },
      plugin.tableName
    )
    return response[0]
  } catch (error) {
    console.error(error)
    return []
  }
}
/**
 * Asynchronously fetches authentication details based on the provided action object.
 * The function constructs a filter using the plugId and authId from the action object,
 * querying the specified table. It returns the first result or an empty array if an error occurs.
 *
 * @param {object} action - An object containing the necessary details for the query.
 * @param {string} action.filterField - The field name to filter by plugId in the database.
 * @param {string} action.plugId - The ID of the plugin used for filtering.
 * @param {string} action.tableName - The name of the table from which to fetch data.
 * @param {string} action.secondFilterField - The field name to filter by authId in the database.
 * @param {string} action.authId - The ID of the auth record used for filtering.
 * @param {boolean} action.isUserOnDH - Indicates if the user is on DataHub (not used in the current function logic).
 * @returns {Promise<authDataType|[]>} A promise that resolves to the first authentication data fetched or an empty array if an error occurs.
 */
export async function getAuthDetailsQueryFn(
  action: {
    filterField: string
    plugId: string
    tableName: string
    secondFilterField: string
    authId: string
    isUserOnDH: boolean
  },
  addRandomId: boolean
) {
  try {
    const response: authDataType[] = await getPluginData(
      {
        [action?.filterField]: action.plugId ?? '',
        [action?.secondFilterField]: action?.authId ?? '',
        addRandomId: addRandomId ? 'true' : 'false'
      },
      action.tableName
    )
    return response[0]
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function isAuthValidFn(authIdentifier: string) {
  try {
    const response: any = await isAuthValid(authIdentifier)
    return response
  } catch (error) {
    console.error(error)
    return []
  }
}

/**
 * Asynchronously fetches action or trigger data based on the provided filter criteria.
 * This function queries the 'action' table and returns the fetched data or an empty array if an error occurs.
 *
 * @param {object} filter - The filter criteria used to fetch the data from the action table.
 * @returns {Promise<any>} A promise that resolves to the fetched data or an empty array in case of an error.
 */
export async function getActionTrigger(filter: object) {
  try {
    const response: any = await getPluginData(filter, 'action')
    return response
  } catch (error) {
    console.log(error)
    return []
  }
}
