// refactored version of sliderLayout component
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import './sliderLayoutV2.scss'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import ScriptSliderBodyV3 from '../projectdashboard/Slider/scriptSliderBodyV3.tsx'
// import AppIntegrationAndCronSlider from '../whenSlider/appIntegrationAndCronSlider.tsx'
// import TemplateGallery from './TemplateGallery.tsx'
const AppIntegrationAndCronSlider = lazy(() => import('../whenSlider/appIntegrationAndCronSlider.tsx'))
const TemplateGallery = lazy(() => import('./TemplateGallery.tsx'))

function SliderLayoutV2({ projectId, scriptId, interfaceId, serviceId, eventId, orgId }) {
  // USE-DISPATCH.
  const dispatch = useDispatch()
  const [openHoverSlider, setOpenHoverSlider] = useState(false)
  // USE-LOCATION.
  const location = useLocation()
  // PARAMETER.
  const { isProjectSliderOpen } = useCustomSelector((state) => ({
    isProjectSliderOpen: state.appInfo.isProjectSliderOpen
  }))

  useEffect(() => {
    dispatch(
      updateAppInfoState({
        isPluginActionSelectOpen: false,
        isProjectSliderOpen: !scriptId && !interfaceId ? Boolean(serviceId) || Boolean(projectId) : isProjectSliderOpen
      })
    )
  }, [projectId, serviceId])

  window.addEventListener('mousemove', function (event) {
    if (event.clientX <= 16) setOpenHoverSlider(true)
    else if (openHoverSlider && event.clientX > 300) setOpenHoverSlider(false)
  })
  return (
    <Box className='flex w-100'>
      {projectId && (
        <Box>
          <Box
            className={` ${isProjectSliderOpen ? 'h-100' : 'drawer'} ${openHoverSlider ? 'drawer-open' : ''}  script_slider  ${
              projectId === `proj${orgId}` ? `w-0 d-none` : 'flex'
            }`}
          >
            <ScriptSliderBodyV3 />
          </Box>
        </Box>
      )}
      {serviceId && !scriptId && !eventId && !location.pathname.includes('appsexplore') && (
        <Suspense fallback={<div>Loading...</div>}>
          <AppIntegrationAndCronSlider />
        </Suspense>
      )}
      {eventId && !scriptId && !location.pathname.includes('appsexplore') && (
        <Suspense fallback={<div>Loading...</div>}>
          <TemplateGallery />
        </Suspense>
      )}
      <Outlet />
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(SliderLayoutV2), [
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.interfaceId,
    ParamsEnums.embedding,
    ParamsEnums.serviceId,
    ParamsEnums.eventId,
    ParamsEnums.orgId
  ])
)
