const config = {
  workflowBaseUrl: '/workflows',
  functionBaseUrl: '/function',
  signupBaseUrl: '/signup',
  notFoundBaseUrl: '/notFound',
  projectsBaseUrl: '/projects',
  orgBaseUrl: '/org',
  scriptBaseUrl: '/script',
  redirectBaseUrl: '/redirect',
  developerBaseUrl: '/developer',
  ssoBaseUrl: '/sso',
  template: '/template',
  authBaseUrl: '/auth',
  packageBaseUrl: '/package',
  oauthBaseUrl: '/oauth/v1/auth',
  authCongoPage: '/auth/congratulationScreen',
  interface: '/interface',
  oauth: '/oauth/auth',
  verficationEmbed: '/embed',
  servicePage: '/auth/service',
  integrations: '/integrations'
}

export const API_URL = {
  functionAskAi: 'https://flow.sokt.io/func/scriObGkvsHy',
  getThreadHistory: 'https://flow.sokt.io/func/scripuqDANXZ',
  apiSliderAskAi: 'https://flow.sokt.io/func/scriHG59f6kA',
  apiGetDynamicVariable: 'https://flow.sokt.io/func/scriCz0dImTZ',
  ResponseByAI: 'https://flow.sokt.io/func/scriwMqUbNZn',
  encryptCodebyAI: 'https://flow.sokt.io/func/scrixfJr8TtJ'
}
export default config
