import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'

function OnboardingPageTwo() {
  const { orgId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    function navigateInside(e) {
      if (e.data?.type === 'interfaceLoaded') window.openChatbot()
      if (e.data?.message?.navigateInside) {
        window.closeChatbot()
        navigate(`/projects/${orgId}`)
      }
    }
    window.addEventListener('message', navigateInside)
    return () => {
      window.removeEventListener('message', navigateInside)
    }
  }, [])

  return (
    <Box className='h-100 flex-center-center' width='70vw'>
      <Typography className='color-white' variant='h3'>
        Welcome to Viasocket
      </Typography>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(OnboardingPageTwo), []))
