/* eslint-disable-next-line import/no-cycle */
import getAllUsers from './orgUsersThunk'

export const extraReducers = (builder) => {
  builder
    .addCase(getAllUsers.pending, (state) => {
      state.orgUsers.status = 'loading'
    })
    .addCase(getAllUsers.fulfilled, (state, payload) => {
      state.orgUsers.status = 'idle'
      const obj = {}
      payload.payload?.users?.forEach((element) => {
        obj[element.id] = element
      })
      state.data = obj
    })
    .addCase(getAllUsers.rejected, (state) => {
      state.orgUsers.status = 'error'
    })
}
