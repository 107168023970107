import { createSlice } from '@reduxjs/toolkit'
import actionType from '../../../types/utility.ts'
import { $AllPluginsType } from '../../../types/reduxCore.ts'
import { PluginInfoAirtableType } from '../../../types/Plugin.ts'

const initialState: $AllPluginsType = {
  isLoading: true,
  allTriggersList: [],
  allPluginList: []
}
export const allTriggers = createSlice({
  name: 'allTriggers',
  initialState,
  reducers: {
    resetAllTriggers: () => {
      return { ...initialState }
    },
    fetchTriggersData: (state) => {
      state.isLoading = true
    },
    onFetchTriggersFetchSuccess: (state, action: actionType<PluginInfoAirtableType[]>) => {
      state.allTriggersList = action.payload
      state.isLoading = false
    },
    onFetchTriggersFetchRejected: (state) => {
      state.isLoading = false
    }
  }
})
export const { resetAllTriggers, fetchTriggersData, onFetchTriggersFetchSuccess, onFetchTriggersFetchRejected } = allTriggers.actions
export default allTriggers.reducer
