import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import React, { useState } from 'react'
import '../../pages/interface/components/allInterfaceList/allInterfaceList.scss'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import { ViaocketWhiteIcon } from '../../assests/assestsIndex.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { isThreeMonthOld } from '../../pages/interface/utils/InterfaceUtils.ts'

function AllTableList({ allTablesList = [], orgId }) {
  const [isExpanded, setisExpanded] = useState(false)
  const orgCreatedAt = useCustomSelector((state: $ReduxCoreType) => state.orgs?.orgs?.[orgId]?.created_at)
  const isThreeMonthOldOrg = isThreeMonthOld(orgCreatedAt)

  const navigateToTable = (tableId: string) => {
    window.open(`${process.env.REACT_APP_TABLE_FRONTEND_URL}/${orgId}/db/${tableId}`, '_blank')
  }

  return (
    isThreeMonthOldOrg &&
    allTablesList?.length > 0 && (
      <Accordion onClick={() => setisExpanded(!isExpanded)} sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className='items-center' />}
          sx={{ border: 'none', background: 'transparent', padding: '0', margin: '0', '&:hover': { background: 'transparent' } }}
          aria-controls='panel1-content'
          id='panel1-header'
          className='p-0 m-0 pr-2 dh-accordian-sidebar w-fitcontent'
        >
          <List>
            <ListItem disablePadding>
              <div className='flex-start-center '>
                <Typography variant='base' darkbg='true' className='flex-start-center accordion-typo'>
                  Tables
                </Typography>
              </div>
            </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails sx={{ border: 'none', padding: '0' }}>
          <List sx={{ color: '#c3c3c3' }}>
            {allTablesList?.map((tableObject) => {
              return (
                <ListItem
                  disablePadding
                  key={tableObject?._id}
                  onClick={() => {
                    navigateToTable(tableObject?._id)
                  }}
                  className=''
                >
                  <ListItemButton className='list-item px-1'>
                    <ListItemIcon>
                      <img src={ViaocketWhiteIcon} alt='' />
                    </ListItemIcon>
                    <ListItemText primary={tableObject?.name} />
                  </ListItemButton>
                </ListItem>
              )
            })}
            {/* <NotificationSlider /> */}
          </List>
        </AccordionDetails>
      </Accordion>
    )
  )
}

export default React.memo(addUrlDataHoc(React.memo(AllTableList), [ParamsEnums?.orgId]))
