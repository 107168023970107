import { call, put } from 'redux-saga/effects'
import NotificationApiService from '../../api/notificationApi.ts'
import { setAllNotification } from './notificationSlice'

export function* startSetAllNotificationSaga(action) {
  try {
    const notificationResponse = yield call(NotificationApiService.getAllNotification, action.payload)
    yield put(setAllNotification(notificationResponse))
  } catch (error) {
    console.error('Error from startSetAllNotificationSaga', error)
  }
}
