import socketIcon from './socketIcon.png'
import socketAIIcon from './Viasocket_AI.svg'
import masterCard from './aftersignupPage/mastercard.png'
import visa from './aftersignupPage/visa.png'
import paypal from './aftersignupPage/paypal.png'
import mi from './aftersignupPage/mi.png'
import americanExpress from './aftersignupPage/american-express.png'
import ixigo from './aftersignupPage/ixigo.png'
import unacadamy from './aftersignupPage/unacadamy.png'
import socket from './aftersignupPage/socket.svg'
import dream11 from './aftersignupPage/dream11.png'
import indeed from './aftersignupPage/indeed.png'
import logos from './Logos.png'
import surprise from './surpriseimg.png'
import defaultApp from './App.png'
import viasocketBlackImage from './viasocketImageBlack.svg'
import viasocketWhiteImage from './viasocketImageWhite.svg'
import aiStarLogo from './aiStarLogo.svg'
import newLogo from './newLogo.svg'

export const ChatBotIcon = socketAIIcon
export const SocketIcon = socketIcon
export const MasterCard = masterCard
export const Visa = visa
export const Paypal = paypal
export const AmericanExpress = americanExpress
export const Ixigo = ixigo
export const Dream11 = dream11
export const Unacadamy = unacadamy
export const Indeed = indeed
export const Mi = mi

export const Trusted = logos
export const Surprise = surprise

export const DefaultApp = defaultApp
export const ViaocketBlackIcon = viasocketBlackImage
export const ViaocketWhiteIcon = viasocketWhiteImage
export const AiStarLogo = aiStarLogo
export const newLabelLogo = newLogo
export const socketLogoWithImage = socket

function makeImageUrl(imageId: string): string {
  return `https://imagedelivery.net/Vv7GgOGQbSyClWJqhyP0VQ/${imageId}/public`
}

export const AI_WHITE_ICON = makeImageUrl('b1357e23-2fc6-4dc3-855a-7a213b1fa100')
export const AI_BLACK_ICON = makeImageUrl('91ee0bff-cfe3-4e2d-64e5-fadbd9a3a200')
