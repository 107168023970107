import React, { useEffect } from 'react'
import './scriptListV2.scss'
import { Box, Skeleton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { fetchScripts } from '../../store/scripts/scriptsThunk'
import { closeModalRedux } from '../../store/appInfo/appInfoSlice.ts' // v2 change by chanchal
import { ApiTypes, ParamsEnums } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import CustomModal from '../customModal/customModal'
import DuplicateFlowModal from '../functiondashboard/workFlow/duplicateFlowModal/duplicateFlowModal.tsx'
import ScriptListItemComponent from './ScriptListItemComponent.tsx'
import AllInterfaceList from '../../pages/interface/components/allInterfaceList/allInterfaceList.tsx'
import CreateNewPopover from './createNewPopover.tsx'
import { isThreeMonthOld } from '../../pages/interface/utils/InterfaceUtils.ts'
import FlowDelayModal from '../FlowDelayModal/FlowDelayModal.tsx'

/**
 * Component to display a list of scripts, their details, and modals for duplicating or moving scripts.
 *
 * @param {string} props.projectId - The ID of the current project.
 * @param {string} props.orgId - The ID of the current organization.
 * purpose :- these component
 *           show the list of scripts
 *           create new pop over (for creating table ,  interface , script ,  )
 *            AllInterfaceList
 *           duplicate model -> Used to make a flow duplicate or to move a flow from one project to another project
 */
function ScriptListV3({ projectId, orgId }) {
  const dispatch = useDispatch()
  const { isScriptListLoading, isModalOpen, modalType, scriptObject, allScripts } = useCustomSelector((state) => ({
    isScriptListLoading: state.scripts.isLoading,
    isModalOpen: state.appInfo.isModalOpen,
    modalType: state.appInfo.modalType,
    scriptObject: state?.scripts?.projects?.[projectId],
    allScripts: state?.scripts?.scripts
  }))
  const orgCreatedAt = useCustomSelector((state: $ReduxCoreType) => state.orgs?.orgs?.[orgId]?.created_at)
  const isThreeMonthOldOrg = isThreeMonthOld(orgCreatedAt)

  useEffect(() => {
    if (projectId) {
      setAllFunctions(projectId)
    }
  }, [projectId])

  const setAllFunctions = async (id) => {
    dispatch(fetchScripts({ id, type: ApiTypes.FLOW }))
  }

  return isScriptListLoading ? (
    <ScriptListSkeleton />
  ) : (
    <Box className='column gap-2'>
      <CreateNewPopover />
      <ScriptListItemComponent scriptObject={scriptObject} allScripts={allScripts} id={projectId} />
      {isThreeMonthOldOrg && <AllInterfaceList id={projectId} />}
      <CustomModal
        fullScreen={false}
        openModal={isModalOpen && (modalType === 'duplicateScript' || modalType === 'MoveScript')}
        onClose={() => dispatch(closeModalRedux())}
      >
        <Box className='dialogMinMax'>
          <DuplicateFlowModal title={modalType === 'duplicateScript' ? 'Duplicate Flow' : 'Move Flow'} />
        </Box>{' '}
        {/*  used to make a flow duplicate or to move a flow from one project to another project */}
      </CustomModal>
      <CustomModal fullScreen={false} openModal={isModalOpen && modalType === 'delayModal'} onClose={() => dispatch(closeModalRedux())}>
        <Box className='dialogMinMax'>
          <FlowDelayModal />
        </Box>
      </CustomModal>
    </Box>
  )
}

export function ScriptListSkeleton() {
  return (
    <Box className='column gap-3 p-4'>
      <Skeleton variant='rounded' className='w-100' height={30} />
      <Skeleton variant='text' className='w-25 mb-2 font-1rem' />
      <Box className='flex-col-start-center gap-1'>
        <Skeleton variant='text' className='w-50 font-1rem' />
        <Skeleton variant='text' className='w-50 font-1rem' />
        <Skeleton variant='text' className='w-50 font-1rem' />
        <Skeleton variant='text' className='w-50 font-1rem' />
        <Skeleton variant='text' className='w-50 font-1rem' />
        <Skeleton variant='text' className='w-50 font-1rem' />
        <Skeleton variant='text' className='w-50 font-1rem' />
        <Skeleton variant='text' className='w-50 font-1rem' />
      </Box>
      <Skeleton variant='rounded' className='w-25' height={30} />
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(ScriptListV3), [ParamsEnums.orgId, ParamsEnums.projectId, ParamsEnums.tabName]))
