// import cloneDeep from 'lodash.clonedeep'
import { SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import { $DHAuthenticationType } from '../../../types/reduxCore.ts'
import { AuthFieldsType, allAuthDataType } from '../../../types/DHAuthentication.ts'
import actionType from '../../../types/utility.ts'
import { getUsedVariableUtility, parseAuthDataUtility } from '../../../pages/developerHub/utils/utility.ts'
import {
  DH_AUTH_SLIDERS,
  KEYS_TO_GET_USEDVARIABLES,
  OBJECTS_TO_PARSE
} from '../../../pages/developerHub/constants/developerHubConstants.ts'
import isEqual from '../../../utils/deepCheckSelector'
import { fetchUsedVariables } from '../../../utils/utilities'

export const initialState: $DHAuthenticationType = {
  isLoading: false
}

const draftMarkInitialState = {
  revokeapicode: false,
  testcode: false,
  refreshtokencode: false,
  accesstokencode: false,
  authfields: false
}

export const reducers: ValidateSliceCaseReducers<$DHAuthenticationType, SliceCaseReducers<$DHAuthenticationType>> = {
  getAuthDetailStart(state) {
    state.isLoading = true
  },

  getAuthDetailSuccess(state, action: actionType<{ [id: string]: AuthFieldsType[] }>) {
    const { pluginId } = action.payload
    const tempAuthData: allAuthDataType = {}
    action.payload?.data?.forEach((authDetails: any) => {
      const dataToUpdateInState = parseAuthDataUtility(authDetails, OBJECTS_TO_PARSE.authKeysToParse)
      const usedVariableObj = getUsedVariableUtility(dataToUpdateInState, KEYS_TO_GET_USEDVARIABLES.authKeys)
      tempAuthData[authDetails.rowid] = {
        ...state?.[pluginId]?.[authDetails?.rowid],
        ...dataToUpdateInState,
        usedVariables: usedVariableObj,
        draftMark: draftMarkInitialState
      }
    })
    // Update the state
    state[pluginId] = { ...state[pluginId], ...tempAuthData }
    state.isLoading = false
  },

  getAuthDetailError(state) {
    state.isLoading = false
    return initialState
  },

  createAuthDetailsStart(state) {
    state.isLoading = true
  },

  createAuthDetailSuccess(state, action: actionType<AuthFieldsType>) {
    const { pluginId } = action.payload
    const dataToUpdateInState = parseAuthDataUtility(action.payload?.data, OBJECTS_TO_PARSE.authKeysToParse)
    const usedVariableObj = getUsedVariableUtility(dataToUpdateInState, KEYS_TO_GET_USEDVARIABLES.authKeys)

    state[pluginId][action.payload.data.rowid] = {
      ...dataToUpdateInState,
      draftMark: draftMarkInitialState,
      usedVariables: usedVariableObj
    }
    state.isLoading = false
  },

  createAuthDetailError(state) {
    // return initialState
    state.isLoading = false
  },

  updateAuthDetailInRedux(state, action: actionType<{ [key: string]: any }>) {
    const { pluginId, sectionId, slugName } = action.payload
    if (pluginId && sectionId) {
      state[pluginId] = {
        ...state?.[pluginId],
        [sectionId]: {
          ...state?.[pluginId]?.[sectionId],
          ...action.payload?.data,
          draftMark: {
            ...state?.[pluginId]?.[sectionId]?.draftMark,
            ...checkForDraftMark(action.payload?.data, DH_AUTH_SLIDERS, state?.[pluginId]?.[sectionId])
          },
          ...(action.payload?.data?.[slugName]?.source
            ? { usedVariables: { [slugName]: fetchUsedVariables(action.payload?.data?.[slugName]?.source, true) } }
            : {})
        }
      }
    }
  },

  updateUsedVariablesInRedux(state, action: actionType<{ [key: string]: any }>) {
    const { pluginId, sectionId, slugName, code } = action.payload
    if (pluginId && sectionId) {
      state[pluginId][sectionId] = {
        ...state?.[pluginId]?.[sectionId],
        usedVariables: { ...state?.[pluginId]?.[sectionId]?.usedVariables, [slugName]: fetchUsedVariables(code, true) }
      }
    }
  },

  updateResultInRedux(state, action: actionType<{ [key: string]: any }>) {
    const { pluginId, sectionId } = action.payload
    if (pluginId && sectionId) {
      state[pluginId][sectionId].result = {
        ...state?.[pluginId]?.[sectionId]?.result,
        ...action.payload?.data
      }
    }
  },

  updateAuthDetailStart(state, action: actionType<AuthFieldsType>) {
    state.isLoading = true
    // const keysToMarkDraft = Object.keys(action?.payload).filter((key) => OBJECTS_TO_PARSE?.authKeysToParse?.includes(key))
    // console.log(keysToMarkDraft, '--0-0-0-')
    // state.draftMark = {
    //   ...state.draftMark,
    //   ...keysToMarkDraft?.reduce((acc, item) => {
    //     acc[item] = false
    //     return acc
    //   }, {})
    // }
    const pluginId = action.urlData?.pluginId
    const sectionId = action?.urlData?.sectionId
    const data = { ...action.payload }
    const draftMark = state?.[pluginId]?.[sectionId]?.draftMark
    Object.keys(data).forEach((key) => {
      if (DH_AUTH_SLIDERS?.includes(key)) {
        draftMark[key] = false
      }
    })
    state[pluginId][sectionId] = {
      ...state[pluginId][sectionId],
      ...data,
      draftMark
    }
  },
  updateAuthDetailSuccess(state) {
    // const { pluginId } = action.payload
    // const data = { ...action.payload?.data }
    // const dataToUpdateInState = parseAuthDataUtility(data, OBJECTS_TO_PARSE.authKeysToParse)
    // state[pluginId][data.rowid] = { ...state[pluginId][data.rowid], ...dataToUpdateInState }
    state.isLoading = false
    // state.allAuthData[data.rowid] = { ...state.allAuthData[data?.rowid], ...data }
    // const dataToUpdateInState = parseAuthDataUtility(data, OBJECTS_TO_PARSE.authKeysToParse)
    // state.fields = dataToUpdateInState
  },
  updateAuthDetailError(state) {
    state.isLoading = false
    // const keysToSetToIntial = Object.keys(action.payload)
    // const objToResetToIntial = {}
    // keysToSetToIntial.forEach((key) => {
    //   objToResetToIntial[key] = initialState.fields[key]
    // })
    // state.fields = { ...state.fields, ...objToResetToIntial }
  },
  deleteAuthStart(state) {
    state.isLoading = true
  },
  deleteAuthSuccess(state, action) {
    const { pluginId } = action.urlData
    delete state[pluginId][action.payload.rowid]
  },
  deleteAuthError(state) {
    state.isLoading = false
  },
  setAuthDetailsToInitial() {
    return initialState
  },
  getActionIdAndAuthIdCountStart(state, action) {
    const { pluginId } = action.urlData
    state.actionIdAndCountMapping = {
      ...state.actionIdAndCountMapping,
      [pluginId]: {}
    }
  },
  getActionIdAndAuthIdCountSuccess(state, action: actionType<{ authid: string; count: string }[]>) {
    const { pluginId } = action.payload
    const map: { [key: string]: string } = {}
    action?.payload?.data?.forEach((ele) => {
      map[ele.authid] = ele.count
    })
    state.actionIdAndCountMapping = {
      ...state.actionIdAndCountMapping,
      [pluginId]: map
    }
  },

  getParticularSliderCodeAuthSuccess(state, action) {
    const { sectionId, pluginId, slugName, data } = action.payload
    const parsedData = { [slugName]: JSON.parse(data?.[slugName]) }
    state[pluginId][sectionId] = {
      ...state?.[pluginId]?.[sectionId],
      ...parsedData,
      draftMark: {
        ...state?.[pluginId]?.[sectionId]?.draftMark,
        [slugName]: false
      }
    }
  }
}

export const checkForDraftMark = (newObj, keysArr, prevObj) => {
  const objToReturn = {}
  const draftMark = prevObj?.draftMark
  Object.keys(newObj).forEach((key) => {
    if (keysArr.includes(key) && !draftMark?.[key]) {
      objToReturn[key] = !isEqual(newObj?.[key], prevObj?.[key])
    }
  })
  return objToReturn
}
