import { getKeyValueOfFromArrayOfNotification } from '../../utils/notificationUtility.ts'

export const reducers = {
  setAllNotification(state, action) {
    const notificationOrder = getKeyValueOfFromArrayOfNotification(action.payload)
    state.notificationKeyToMetadataMapping = notificationOrder
    const newObject = {}

    Object.keys(notificationOrder).forEach((key) => {
      const obj = notificationOrder[key]
      const combinedKey = `${obj.stepId}.${obj.templateId}`
      newObject[combinedKey] = { notificationId: obj._id, show: true }
    })

    state.localNotification = newObject
  },
  startSetAllNotification(state) {
    state.localNotification = {}
    state.notificationKeyToMetadataMapping = {}
  },
  setLocalNotification(state, action) {
    state.localNotification = { ...state.localNotification, ...action.payload }
  },
  cancelNotification(state, action) {
    delete state.localNotification[action.payload]
  }
}
