import React from 'react'
import { Navigate } from 'react-router-dom'
import { MiscTypes } from '../enums'
import { useCustomSelector } from '../utils/deepCheckSelector'
import { getFromCookies, getInfoParamtersFromUrl, getCurrentEnvironment, removeCookie, setInCookies } from '../utils/utilities'
// import { errorToast } from './customToast'
import addUrlDataHoc from '../hoc/addUrlDataHoc.tsx'

function Protected({ children }) {
  const { mode } = useCustomSelector((state) => ({
    mode: state.appInfo.mode
  }))
  const key = getCurrentEnvironment()
  let token = ''
  if (mode === MiscTypes.FLOW) {
    token = getFromCookies(key)
  } else if (mode === MiscTypes.EMBED_MODE || mode === MiscTypes.SSO_MODE) {
    token = sessionStorage.getItem('ssoEmbedToken')
  } else if (mode === MiscTypes.OAUTH_MODE) {
    token = getFromCookies(key)
  }

  if (!token) {
    localStorage.clear()
    removeCookie(key)

    if (mode === MiscTypes.FLOW || mode === MiscTypes.OAUTH_MODE) {
      const params = new URLSearchParams(window.location.search)
      params.delete('stepId')
      params.delete('slugName')
      const remainingSearchString = params.toString()

      const previousUrlData = {
        ...getInfoParamtersFromUrl(),
        previous_url: `${window.location.pathname}${remainingSearchString ? `?${remainingSearchString}` : ''}`
      }
      setInCookies(`${getCurrentEnvironment()}-previous_url`, JSON.stringify(previousUrlData))
      return process.env.REACT_APP_API_ENVIRONMENT === 'prod' ? (
        window.location.replace(process.env.REACT_APP_VIASOCKET_HOME_PAGE_URL)
      ) : (
        <Navigate to='/' />
      )
    }
    return <Navigate to='/embed' />
  }
  return children
}

export default React.memo(addUrlDataHoc(React.memo(Protected), []))
