import { Autocomplete, Avatar, Box, DialogActions, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPluginByPluginName } from '../../../api/index'
import { BlockTypes, ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { openAiSlider, openModalRedux, updateAppInfoState } from '../../../store/appInfo/appInfoSlice.ts'
import selectActiveUser from '../../../store/user/userSelector'
import { $ReduxCoreType } from '../../../types/reduxCore.ts'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { generateNewId, getProxyAuthToken } from '../../../utils/utilities'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import { actionDataType, pluginDataType } from '../../../types/Plugin.ts'
import { useFetchActionsTriggers } from '../../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { setAuthFieldsStart, setSelectedActionDetails } from '../../../store/stepsV2/stepSliceV3.ts'
import { addActionOrTriggerThunk } from '../../../store/flowJson/flowJsonThunkV2.ts'

function PluginRequestForm({ orgId, setSearchParams }: { orgId: string; setSearchParams: (params: { [key: string]: string }) => void }) {
  const dispatch = useDispatch()
  const [pluginInfo, setPluginInfo] = useState<pluginDataType>()
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const { modalType, userData } = useCustomSelector((state: $ReduxCoreType) => ({
    modalType: state.appInfo?.modalType,
    userData: selectActiveUser(state)
  }))
  const [dialog, setDialog] = useState<'first' | 'second' | ''>('first')

  const handleSubmit = () => {
    // e.preventDefault() // Prevent default form submission behavior
    const usecaseValue = document.getElementById('usecase')?.value
    const url = 'https://flow.sokt.io/func/scriPIvL7pBP'
    const content = {
      plugName: pluginInfo?.name || searchQuery,
      useCase: usecaseValue,
      environment: process.env.REACT_APP_API_ENVIRONMENT,
      userId: userData?.userId,
      userEmail: userData?.email
    }
    axios.post(url, content)
  }

  const closeDialog = () => {
    setDialog('first')
    setPluginInfo()
    setOptions([])
    dispatch(openModalRedux({ modalType: '' }))
  }
  const searchPlugins = async (event, newValue) => {
    setSearchQuery(newValue)
    setLoading(true)
    const plugins = await getPluginByPluginName(newValue, orgId)
    setOptions(plugins?.length ? plugins : [{ rowid: 'requestPlugin', name: newValue, isdummy: true }])
    setLoading(false)
  }

  const createActionByAi = () => {
    const preferedauthversion = pluginInfo?.preferedauthversion
    const domain = pluginInfo?.domain
    const clientGeneratedId = `func${generateNewId()}`
    const pluginIcon = pluginInfo?.iconurl
    if (window.openChatbot) {
      window.openChatbot()
    }
    dispatch(
      setSelectedActionDetails({
        pluginDataFromAction: {
          preferedauthversion,
          serviceId: pluginInfo?.rowid,
          pluginDomain: domain,
          selectedValues: { authData: {}, inputData: {} }
        },
        stepId: clientGeneratedId,
        tabName: 'draft'
      })
    )
    setSearchParams({ stepId: clientGeneratedId, slugName: 'Action_By_AI' })
    dispatch(updateAppInfoState({ currentStepType: BlockTypes.PLUG }))
    dispatch(
      setAuthFieldsStart({
        pluginDomain: domain,
        filterField: 'pluginrecordid',
        plugId: pluginInfo?.rowid,
        tableName: 'auth',
        secondFilterField: 'rowid',
        setSearchParams: setSearchParams,
        pluginIcon: pluginIcon,
        orderGroup: 'root',
        stepId: clientGeneratedId,
        actionDetails: { type: 'action' }
      })
    )
    dispatch(openAiSlider())
    closeDialog()
  }
  return modalType === 'requestPlug' ? (
    <>
      <Dialog
        open={dialog === 'first'}
        PaperProps={{
          style: {
            width: '600px'
          }
        }}
        onClose={closeDialog}
      >
        <DialogContent>
          <Box component='form' className='column gap-4' onSubmit={handleSubmit}>
            <Box className='column gap-3'>
              <Autocomplete
                loading={loading}
                onChange={(event, newValue: pluginDataType) => {
                  setPluginInfo(newValue)
                }}
                onInputChange={searchPlugins}
                selectOnFocus
                clearOnBlur={false}
                handleHomeEndKeys
                id='free-solo-with-text-demo'
                options={options}
                getOptionLabel={(option) => {
                  return option?.name
                }}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props
                  return (
                    <li key={key} {...optionProps} className='flex-start-top gap-3 ml-3 mb-2 cursor-pointer'>
                      {option?.iconurl && <IconWrapper iconUrl={option.iconurl} size='30px' />}
                      <Typography>{option?.name}</Typography>
                    </li>
                  )
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={pluginInfo?.name}
                    label='Select Service'
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: pluginInfo?.name ? (
                        <Box display='flex' alignItems='center'>
                          <Avatar
                            src={options.find((option) => option.name === pluginInfo.name)?.iconurl || ''}
                            alt={pluginInfo.name}
                            sx={{ width: 25, height: 25 }}
                          />
                        </Box>
                      ) : null
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault() // Prevent default behavior on Enter
                      }
                    }}
                  />
                )}
                noOptionsText='No Integration Available'
              />
            </Box>
            {pluginInfo?.rowid && !pluginInfo?.isdummy && (
              <AvailableActions pluginId={pluginInfo?.rowid || ''} closeDialog={closeDialog} setSearchParams={setSearchParams} />
            )}
            {pluginInfo?.rowid && pluginInfo?.isdummy && (
              <Box className='column gap-3'>
                <Typography>
                  {`We’d love to learn more about how you’re planning to use ${pluginInfo?.name}. Share your specific needs or goals so we can prioritize your request and tailor our support to help you succeed. Your input helps us understand your vision and offer the best solutions to meet your needs.`}
                </Typography>
                <TextField required multiline minRows={6} fullWidth id='usecase' name='usecase' placeholder='Describe your use case' />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className='flex gap-2'>
            <Button variant='outlined' onClick={closeDialog}>
              Cancel
            </Button>
            {pluginInfo ? (
              pluginInfo?.rowid && pluginInfo?.isdummy ? (
                <Button
                  style={{ marginLeft: 'auto', marginTop: 'auto' }}
                  variant='contained'
                  onClick={() => {
                    setDialog('second')
                    handleSubmit()
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button style={{ marginLeft: 'auto', marginTop: 'auto' }} variant='contained' onClick={createActionByAi}>
                  Add Action By AI
                </Button>
              )
            ) : null}
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialog === 'second'}
        PaperProps={{
          style: {
            width: '600px'
          }
        }}
        onClose={closeDialog}
      >
        <DialogContent>
          <Box className='column gap-2'>
            <Typography fontWeight='600'>Thank you for submitting your request!</Typography>
            <Typography>
              We value your input and invite you to assist us in building, reviewing, and testing your integration. It will take around 40
              minutes of your time, and as a token of our appreciation, we’ll extend your subscription by 3 months at no extra cost.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={closeDialog}>
            No, I do not want this perk
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              if (pluginInfo?.rowid === 'requestPlugin') {
                window.open('https://calendly.com/rpaliwal71/15-mins', '_blank')
                closeDialog()
                return
              }
              if (window.openChatbot) {
                // window.openChatbot()
                window.SendDataToChatbot({
                  askAi: `Create Integration for service ${pluginInfo?.name}`,
                  bridgeName: 'DH-BasicAuth-bot',
                  threadId: pluginInfo?.rowid,
                  variables: {
                    pluginRecordId: pluginInfo?.rowid,
                    pluginName: pluginInfo?.name,
                    proxy_auth_token: getProxyAuthToken(),
                    environment: process.env.REACT_APP_API_ENVIRONMENT,
                    orgId
                  }
                })
                window.openChatbot()
              }

              closeDialog()
            }}
          >
            Yes, I will help
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null
}

export default React.memo(addUrlDataHoc(PluginRequestForm, [ParamsEnums.orgId]))

function AvailableActions({
  pluginId,
  closeDialog,
  setSearchParams
}: {
  pluginId: string
  closeDialog: () => void
  setSearchParams: (params: any) => void
}) {
  const availableActions: actionDataType[] = Object.values(useFetchActionsTriggers(pluginId)?.data || {})
  const dispatch = useDispatch()
  const createActionStep = (action: actionDataType) => {
    dispatch(
      addActionOrTriggerThunk({
        actionDetails: action,
        clientGeneratedId: `func${generateNewId()}`,
        setSearchParams,
        parent: 'root'
      })
    )
    closeDialog()
  }
  return (
    <Box>
      <Typography>{availableActions?.length ? 'Available Actions' : 'No Actions Available'}</Typography>
      {availableActions?.map((action: actionDataType) => (
        <Button key={action.rowid} onClick={() => createActionStep(action)}>
          {action.name}
        </Button>
      ))}
    </Box>
  )
}
