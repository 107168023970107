/* eslint-disable */
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import './Onboarding.scss'

export default function OnboardingWrapper() {
  return (
    <Box className='app_container flex w-100 h-100vh overflow-hide-y '>
      <Outlet />
    </Box>
  )
}
