import React from 'react'
import { Box, Skeleton } from '@mui/material'

function WorkspaceSkeleton() {
  return (
    <Box className='h-100 w-100' sx={{ bgcolor: '#2b2b2b', display: 'flex', justifyContent: 'center' }}>
      <Skeleton variant='rectangular' width='60%' height={50} />
      <Box className='px-4 py-5 flex-col-center gap-3' sx={{ display: 'flex', direction: 'column' }}>
        <Skeleton variant='rectangular' width='50%' height={400} />
      </Box>
    </Box>
  )
}

export default WorkspaceSkeleton
