import { Alarm, Email, Webhook } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, Typography } from '@mui/material'
import React from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames, THUNK_CALL_STATUS } from '../../enums'

import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { saveScripts } from '../../store/scripts/scriptsThunk'
import config from '../../config'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'

interface CustomOptionsToCreateFlowProps {
  orgId: string
  finalProjectId: string
}

/**
 * Component for creating custom options to create a flow.
 *
 * @param {CustomOptionsToCreateFlowProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
function CustomOptionsToCreateFlow({ orgId, finalProjectId }: CustomOptionsToCreateFlowProps): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /**
   * Navigate to a dedicated page and create a flow.
   *
   * @param {string} event - The event type.
   */
  const navigateToDedicatePage = (event: string): void => {
    dispatch(updateAppInfoState({ openModal: true, modalType: event }))
    createFlow(event)
  }

  /**
   * Create a flow with optional event ID.
   *
   * @param {string | false} [eventId=false] - The event ID.
   */
  const createFlow = (eventId: string | false = false): void => {
    let triggerDetails
    if (eventId) triggerDetails = { triggerType: eventId, type: 'add' }
    dispatch(updateAppInfoState({ modalType: eventId }))
    dispatch(
      saveScripts({
        project_id: finalProjectId,
        org_id: orgId,
        triggerDetails
      })
    ).then((e) => {
      if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED) {
        navigate(
          `${config.projectsBaseUrl}/${orgId}/${finalProjectId}${config.workflowBaseUrl}/${e.payload.id}/${Tabnames.DRAFT}${
            eventId ? '' : '?stepId=SET_TRIGGER&slugName=selecttrigger'
          }`
        )
      }
    })
  }

  return (
    <Box className='column gap-5 pt-5 w-100'>
      <Typography variant='h4'>Add a new flow</Typography>
      <Box className='column gap-3 w-100'>
        <Box className='flex-start-center gap-2 flex-wrap w-100'>
          <Card
            variant='outlined'
            className=' service-block service-block__no-description flex-start-center gap-2'
            onClick={() => navigateToDedicatePage('webhook')}
          >
            <IconWrapper component={<Webhook />} size='28px' />
            When trigger by API call or webhook
          </Card>
          <Card
            variant='outlined'
            className=' service-block service-block__no-description flex-start-center gap-2'
            onClick={() => navigateToDedicatePage('cron')}
          >
            <IconWrapper component={<Alarm />} size='28px' />
            Which repeats at regular intervals
          </Card>
          <Card
            variant='outlined'
            className=' service-block service-block__no-description flex-start-center gap-2'
            onClick={() => navigateToDedicatePage('email')}
          >
            <IconWrapper component={<Email />} size='28px' />
            When you receive certain email
          </Card>
        </Box>
        <Button variant='contained' onClick={() => createFlow()}>
          Create Blank Flow
        </Button>
      </Box>
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(CustomOptionsToCreateFlow), [ParamsEnums.orgId]))
