import { createSlice } from '@reduxjs/toolkit'

export const allChip = createSlice({
  name: 'allChip',
  initialState: {
    // pathsObject: {},
    isSuggestionOpen: false, // To open suggestions box
    currentStepId: '' // Stores the step id of opened step
  },
  reducers: {
    openSuggestionBox: (state, payload) => {
      state.isSuggestionOpen = payload.payload || true
    },
    closeSuggestionBox: (state) => {
      state.isSuggestionOpen = false
    },
    setStepId: (state, payload) => {
      state.currentStepId = payload.payload
    }
  }
})

export const { openSuggestionBox, closeSuggestionBox, setStepId } = allChip.actions
export default allChip.reducer
