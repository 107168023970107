import { createSlice } from '@reduxjs/toolkit'
import { reducers } from './notificationReducer'

export const initialState = {
  notificationKeyToMetadataMapping: {},
  localNotification: {}
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers
})

export const { startSetAllNotification, setAllNotification, setLocalNotification, cancelNotification } = notificationSlice.actions
export default notificationSlice.reducer
