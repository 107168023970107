import { getDataFromRedisDb } from '../api'

class AuthTokenSetterSingleton {
  constructor() {
    if (AuthTokenSetterSingleton.instance) {
      return
    }
    AuthTokenSetterSingleton.instance = this

    // Parse the URL search parameters into a JSON object
    const queryParams = new URLSearchParams(window.location.search)
    this.queryParamsJson = {}
    queryParams.forEach((value, key) => {
      this.queryParamsJson[key] = value
    })

    this.init()
  }

  async init() {
    try {
      // Use the queryParamsJson in the getDataFromRedisDb call
      const getDataFromRedis = await getDataFromRedisDb(this.queryParamsJson['_key'])
      if (getDataFromRedis) {
        sessionStorage.setItem('ssoEmbedToken', getDataFromRedis?.token)
      }
    } catch (error) {
      console.error('Failed to initialize WebSocket client:', error)
    }
  }
}

const authTokenSetter = new AuthTokenSetterSingleton()
export default authTokenSetter
