import { createSlice } from '@reduxjs/toolkit'
import { initialState, reducers } from './dhAuthenticationReducer.ts'

const developerHubAuthenticaitonSlice = createSlice({
  name: 'DHAuthentication',
  initialState,
  reducers
})

export const {
  getAuthDetailStart,
  getAuthDetailSuccess,
  getAuthDetailError,
  createAuthDetailsStart,
  createAuthDetailSuccess,
  createAuthDetailError,
  updateAuthDetailStart,
  updateAuthDetailSuccess,
  updateAuthDetailError,
  setAuthDetailsToInitial,
  setAuthDetailStart,
  updateAuthDetailInRedux,
  updateUsedVariablesInRedux,
  updateResultInRedux,
  deleteAuthStart,
  deleteAuthSuccess,
  deleteAuthError,
  getActionIdAndAuthIdCountStart,
  getActionIdAndAuthIdCountSuccess,
  getParticularSliderCodeAuthSuccess
} = developerHubAuthenticaitonSlice.actions
export default developerHubAuthenticaitonSlice.reducer
