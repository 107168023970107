import React from 'react'
import { MenuItem, FormControl, InputAdornment, Select } from '@mui/material'
import './CustomDropDown.css'

function CustomDropDown({
  fields,
  FormControlClassName,
  SelectClassName,
  value,
  handleSelectChange,
  isDisable = false,
  createField,
  startIcon
}) {
  const Fields = Object.entries(fields).sort((a, b) => a[1].localeCompare(b[1]))

  return (
    <FormControl className={`${FormControlClassName} `}>
      <Select
        defaultValue={Fields?.[0]?.[0]}
        className={`${SelectClassName} `}
        value={value}
        onChange={handleSelectChange}
        inputProps={{ 'aria-label': 'Without label' }}
        disabled={isDisable}
        startAdornment={<InputAdornment position='start'>{startIcon}</InputAdornment>}
      >
        {Fields.map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
        {createField && (
          <MenuItem key={createField} value={createField}>
            {`+ Create New ${createField}`}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
export default CustomDropDown
