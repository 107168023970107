import React, { useState, useRef, useEffect } from 'react'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Box from '@mui/material/Box'
import { useLocation, useNavigate } from 'react-router-dom'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { TextField, Skeleton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateProjects, changeProjectStatus } from '../../../store/projects/projectsThunk'
import './projectList.scss'
import { errorToast } from '../../customToast'
import FunctionsActionsButton from '../../functiondashboard/workFlow/workFlowComponent/functionsActionsButton/functionsActionsButton'
import { openModalRedux, setIsScriptDrawerOpen, updateAppInfoState } from '../../../store/appInfo/appInfoSlice.ts' // v2 change by chanchal
import { $ReduxCoreType } from '../../../types/reduxCore.ts'
import { ProjectsInfoType } from '../../../types/Projects.ts'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { ApiTypes, ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import CustomModal from '../../customModal/customModal'
import DuplicateFlowModal from '../../functiondashboard/workFlow/duplicateFlowModal/duplicateFlowModal.tsx'
import ScriptListItemComponent from '../ScriptListItemComponent.tsx'
import { fetchScripts } from '../../../store/scripts/scriptsThunk'
import AllInterfaceList from '../../../pages/interface/components/allInterfaceList/allInterfaceList.tsx'
import AllTableList from '../../tableComponents/tableList.tsx'
import { getAllTablesList } from '../../../api/tableApis'
import CreateOrgModal from '../../../pages/formbilling/createOrgModal.tsx'
import { isThreeMonthOld } from '../../../pages/interface/utils/InterfaceUtils.ts'
import CreateNewProjectButton from '../../AppsExplore/CreateNewProjectButton.tsx'

function Projectlist(props: any) {
  const { orgId, projectId: currentProjectId } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const projectRef = useRef()
  const navigate = useNavigate()
  const [newTitle, setNewTitle] = useState({ title: '', error: false })
  const { allProjects, isProjectListLoading, isModalOpen, modalType, scriptObject, allScripts } = useCustomSelector(
    (state: $ReduxCoreType) => ({
      allProjects: state.projects?.projects?.[orgId],
      isProjectListLoading: state?.projects?.isLoading,
      isModalOpen: state.appInfo.isModalOpen,
      modalType: state.appInfo.modalType,
      scriptObject: state?.scripts?.projects?.[`proj${orgId}`],
      allScripts: state?.scripts?.scripts
    })
  )
  const orgCreatedAt = useCustomSelector((state: $ReduxCoreType) => state.orgs?.orgs?.[orgId]?.created_at)
  const isThreeMonthOldOrg = isThreeMonthOld(orgCreatedAt)
  const activePauseProject = allProjects ? Object.values(allProjects['active'] || []).sort((a, b) => a?.title?.localeCompare(b.title)) : []
  const deleteProject = allProjects ? Object.values(allProjects['deleted'] || []).sort((a, b) => a?.title?.localeCompare(b.title)) : []
  const [projectId, setProjectId] = useState('')
  const [allTablesList, setAllTablesList] = useState([])

  const isProjectNameExists = (projectName: string) => {
    return (
      activePauseProject.find((project: ProjectsInfoType) => project.title === projectName) ||
      deleteProject.find((project: ProjectsInfoType) => project.title === projectName)
    )
  }

  const setDefaultFunctions = async (id) => {
    dispatch(fetchScripts({ id, type: ApiTypes.FLOW }))
  }
  const getAllTablesData = async () => {
    if (process.env.REACT_APP_API_ENVIRONMENT === 'local' || !isThreeMonthOldOrg) return
    const data = await getAllTablesList(orgId)
    setAllTablesList(data?.data?.data || [])
  }

  useEffect(() => {
    setDefaultFunctions(`proj${orgId}`)
    getAllTablesData()
  }, [orgId])

  const renameProject = async (title, id) => {
    const dataTosend = {
      title: newTitle.title || title,
      project_id: id,
      org_id: orgId
    }
    dispatch(updateProjects({ id, dataTosend }))
    setProjectId('')
    return null
  }

  const renderProjectItem = (value, index) => {
    const checkProjectTitle = () => {
      if (newTitle.title && newTitle.title !== value?.title)
        if (newTitle.title.trim().length > 4) {
          if (isProjectNameExists(newTitle.title.trim())) {
            setNewTitle({ ...newTitle, error: true })
            return errorToast('Folder name already exists')
          }
          renameProject(value?.title, value?.id)
        } else {
          setNewTitle({ ...newTitle, error: true })
          return errorToast('Folder name too short!')
        }
      return null
    }
    const handleOnClick = () => {
      dispatch(setIsScriptDrawerOpen(true))
      dispatch(updateAppInfoState({ currentProjectId: value.id }))
      createRouteForProject(value)
    }
    const changeStatus = async (projectInfo, status) => {
      dispatch(changeProjectStatus({ projectInfo, status }))
    }

    const options = ['Active', 'Rename', 'Pause', 'Move To Trash']
    const handleActionBtns = async (option, projectInfo) => {
      switch (option) {
        case 'Rename':
          setProjectId(projectInfo?.project?.id)
          break
        case 'Move To Trash':
          await changeStatus(projectInfo.project, '0')
          break
        case 'Pause':
          await changeStatus(projectInfo.project, '2')
          break
        case 'Active':
          await changeStatus(projectInfo.project, '1')
          break
        case 'Move':
          projectRef.current = { projectId: projectInfo.project.id, status: projectInfo.project.status }
          dispatch(openModalRedux({ modalType: 'MoveProject' }))
          break
        default:
          break
      }
    }
    const createRouteForProject = async (projectInfo) => {
      navigate(`/projects/${projectInfo.org_id}/${projectInfo.id}`)
    }

    return projectId === value.id ? (
      <Box className='flex-spaceBetween-center w-100' key={value?.id}>
        <TextField
          inputProps={{ maxLength: 50 }}
          size='small'
          fullWidth
          noborder='true'
          error={newTitle.error}
          autoFocus
          projectname='true'
          className='title-textfield'
          onBlur={() => {
            checkProjectTitle()
            setNewTitle({ ...newTitle, error: false })
            setProjectId('')
          }}
          defaultValue={value?.title}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkProjectTitle()
            }
          }}
          onChange={(e) => {
            setNewTitle({ title: e.target.value, error: false })
          }}
        />
      </Box>
    ) : (
      <ListItem
        disablePadding
        active={currentProjectId === value?.id}
        onClick={handleOnClick}
        key={value?.id}
        title={value.title}
        className='org-row m-0'
      >
        <ListItemButton className='list-item pl-2'>
          <ListItemText className='text-overflow-eclipse' primary={value?.title} />
          {value?.status === '2' ? (
            <>
              <FiberManualRecordIcon fontSize='small' className='status-type always-visible' />
              <div className='hover-visible'>
                <FunctionsActionsButton
                  parentId={value?.title}
                  options={options}
                  handleOptionsBtn={handleActionBtns}
                  componentDetails={{ index, project: value }}
                />
              </div>
            </>
          ) : (
            <FunctionsActionsButton
              parentId={value?.title}
              options={options}
              handleOptionsBtn={handleActionBtns}
              componentDetails={{ index, project: value }}
            />
          )}
        </ListItemButton>
      </ListItem>
    )
  }

  return isProjectListLoading ? (
    <Box className='flex-col'>
      <Box>
        <Skeleton width={80} variant='text' className='font-1rem' />
      </Box>

      <Box className='p-2 project_list__projects flex-col-start-center gap-2'>
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
      </Box>

      <Box className='flex w-100 mt-2'>
        <Skeleton className='ml-2' variant='rectangular' width={120} height={30} />
      </Box>
    </Box>
  ) : (
    <Box className='project_list my-3'>
      {isThreeMonthOldOrg && (
        <ListItem
          disablePadding
          onClick={() => navigate(`/projects/${orgId}/appsexplore/servicepage`)}
          className={`${
            location.pathname.startsWith(`/projects/${orgId}/appsexplore`) && !projectId && !props?.firstServiceId && !props?.scriptId
              ? 'selected-list-item'
              : ''
          } `}
        >
          <ListItemButton>
            <ListItemIcon className='lists__icon'>
              <ExtensionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='My all Apps' />
            <ChevronRightIcon className='transform-8px' />
          </ListItemButton>
        </ListItem>
      )}
      <ScriptListItemComponent scriptObject={scriptObject} allScripts={allScripts} defaultProjectId={`proj${orgId}`} id={`proj${orgId}`} />
      {/* create new interface and table will be shown to the user if the current org is three month only  */}
      {isThreeMonthOldOrg && (
        <>
          <AllInterfaceList defaultProjectId={`proj${orgId}`} id={`proj${orgId}`} />
          <AllTableList allTablesList={allTablesList} />
        </>
      )}
      <Box className='column w-100'>
        <Box className='flex-spaceBetween-center visible-child-hover p-2 pb-1 w-100'>
          <Typography variant='smallHeading'>Folders</Typography>
          <CreateNewProjectButton />
        </Box>
        {activePauseProject.map((value, index) => {
          return <>{renderProjectItem(value, index)}</>
        })}
      </Box>
      <Box className='column w-100 mt-3'>
        {deleteProject.length > 0 && (
          <>
            <Box className='flex-spaceBetween-center p-2 pb-1 w-100'>
              <Typography variant='smallHeading'>Deleted Folders</Typography>
            </Box>
            {deleteProject.map((value, index) => {
              return <>{renderProjectItem(value, index)}</>
            })}
          </>
        )}
      </Box>

      <CustomModal fullScreen={false} openModal={isModalOpen && modalType === 'MoveProject'} onClose={() => dispatch(closeModalRedux())}>
        <Box className='dialogMinMax'>
          <DuplicateFlowModal data={projectRef} title='Move Folder' />
        </Box>{' '}
        {/*  used  to move a folder from one org to another org */}
      </CustomModal>
      <CreateOrgModal />
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(Projectlist), [ParamsEnums.orgId, ParamsEnums.projectId, ParamsEnums.firstServiceId, ParamsEnums.serviceId])
)
