import axios from '../interceptor/interceptor'

const URL = process.env.REACT_APP_API_BASE_URL

class NotificationApiService {
  static getAllNotification = async (orgId: string) => {
    const data = await axios.get(`${URL}/alert/get/${orgId}`)
    return data?.data?.data
  }

  static markNotificationAsRead = async (notificationId: string) => {
    const data = await axios.get(`${URL}/alert/markAsRead/${notificationId}`)
    return data?.data?.data
  }

  static createNotificationOnNewActionPublish = async (
    bodyToSend: { serviceId?: string; actionId?: string; actionVersionId: string }[]
  ) => {
    try {
      const body = {
        templateType: 'plugin_update',
        alertJson: bodyToSend
      }
      const data = await axios.post(`${URL}/alert/create`, body)
      return data?.data?.data
    } catch (error) {
      console.error('error in createNotificationOnNewActionPublish', error)
      return null
    }
  }
}

export default NotificationApiService

// [
//   {
//     stepId: 'funcLI3vfv8A',
//     metaData: { slugName: 'Create_Entry' },
//     orgId: 'hell1o',
//     TemplateId: 'PLUGIN_UPDATE',
//     scriptId: 'hello2',
//     projectId: 'hello3',
//     _id: 'w343453'
//   },
//   {
//     stepId: 'funccCYmiddNn',
//     metaData: { slugName: 'Create_Entry' },
//     orgId: 'hell1o',
//     scriptId: 'hello2',
//     TemplateId: 'Update',
//     projectId: 'hello3',
//     _id: 'w34345356456'
//   },
//   {
//     stepId: 'funccCYmiddNn',
//     metaData: { slugName: 'Create_Entry' },
//     orgId: 'hell1o',
//     scriptId: 'hello2',
//     TemplateId: 'Error',
//     projectId: 'hello3',
//     _id: 'w34345dfsd356456'
//   }
// ]
