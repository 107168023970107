// import {saveAuth } from './authThunk';

export const initialState = {
  status: 'idle',
  auths: {},
  currentAuth: {}
}

export const reducers = {
  addCurrentAuth(state, payload) {
    state.currentAuth = payload.payload
  }
}
