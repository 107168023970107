import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Button, ButtonGroup, DialogTitle, TextField, Typography, FormHelperText } from '@mui/material'
import React, { useState, useCallback } from 'react'
import copy from 'copy-to-clipboard'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import './FlowDelayModal.scss'
import { closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import { createOrUpdateTriggerThunk } from '../../store/flowJson/flowJsonThunkV2.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'

/**
 * FlowDelayModal Component
 *
 * This component renders a modal for adding a delay to a flow.
 * It allows users to select predefined delay times or set a custom delay.
 * The component also displays and allows copying of a webhook URL with the delay parameter.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.scriptId - The ID of the script associated with the flow
 *
 * @returns {React.ReactElement} The rendered FlowDelayModal component
 */
function FlowDelayModal({ scriptId }: { scriptId: string }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [url, setUrl] = useState(() => `${process.env.REACT_APP_WEBHOOK_URL}/func/${scriptId}`)
  const [hasCustomDelay, setHasCustomDelay] = useState(false)
  const [tipForCopy, setTipForCopy] = useState(false)
  const [time, setTime] = useState<{ days?: number; hours?: number; minutes?: number }>({ days: 0, hours: 0, minutes: 0 })
  const [error, setError] = useState<string>('')

  const { triggerData } = useCustomSelector((state) => ({
    triggerData: state.flowJsonV2?.[scriptId]?.flowJson?.trigger
  }))

  const copyFunction = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      copy(url)
      setTipForCopy(true)
      setTimeout(() => setTipForCopy(false), 800)
    },
    [url]
  )

  const handleDelayChange = (delayInMin: number) => {
    dispatch(closeModalRedux())
    dispatch(createOrUpdateTriggerThunk({ ...triggerData, type: 'add', delay: delayInMin, navigate }))
  }

  const handleCustomDelayChange = useCallback(() => {
    const { days = 0, hours = 0, minutes = 0 } = time

    if (days > 30 || hours > 23 || minutes > 59) {
      setError('Please select a valid time: max 30 days, 23 hours, and 59 minutes.')
      return
    }
    setError('') // Reset error when adding custom delay
    const totalMinutes = days * 1440 + hours * 60 + minutes
    handleDelayChange(totalMinutes)
  }, [time]) // Memoized with dependency

  const handleTimeChange = useCallback(
    (key: 'days' | 'hours' | 'minutes', max: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value === '' ? undefined : Math.min(Number(e.target.value), max)
      if (value !== undefined) {
        const updatedTime = { ...time, [key]: value }
        setTime(updatedTime)
        const totalMinutes = (updatedTime.days ?? 0) * 1440 + (updatedTime.hours ?? 0) * 60 + (updatedTime.minutes ?? 0)
        setUrl(`${process.env.REACT_APP_WEBHOOK_URL}/func/${scriptId}?delay=${totalMinutes}`)
      } else {
        // Allow empty input
        setTime({ ...time, [key]: undefined })
      }
    },
    [time, scriptId]
  )

  return (
    <Box className='flex-col pb-3 pt-2'>
      <DialogTitle>Add delay to flow</DialogTitle>
      <Box className='mx-4 flex mb-4'>
        <ButtonGroup variant='outlined' aria-label='outlined button group' fullWidth>
          {[5, 7, 1440, 4320, 10080].map((min) => (
            <Button key={min} onClick={() => handleDelayChange(min)}>
              {min === 1440 ? '1 day' : min === 4320 ? '3 days' : min === 10080 ? '7 days' : `${min} min`}
            </Button>
          ))}
          <Button onClick={() => setHasCustomDelay(true)}>+</Button>
        </ButtonGroup>
      </Box>
      {hasCustomDelay && (
        <Box className='timepicker mx-4 mb-4' onClick={(e) => e.stopPropagation()}>
          <TextField
            value={time.days || ''}
            type='number'
            label='Days'
            inputProps={{ min: 0, max: 30 }}
            onChange={handleTimeChange('days', 30)}
          />
          <TextField
            value={time.hours || ''}
            type='number'
            label='Hours'
            inputProps={{ min: 0, max: 23 }}
            onChange={handleTimeChange('hours', 23)}
          />
          <TextField
            value={time.minutes || ''}
            type='number'
            label='Minutes'
            inputProps={{ min: 0, max: 59 }}
            onChange={handleTimeChange('minutes', 59)}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Box>
      )}
      <Button variant='contained' className='mx-4 mb-4' onClick={handleCustomDelayChange}>
        Add delay
      </Button>
      <Box className='flex-col gap-1 mx-4'>
        <Typography variant='body1' className='webhook-preview-text'>
          New webhook URL preview:
        </Typography>
        <Box display='flex' justifyContent='space-between' alignItems='center' className='url_box'>
          <span className='webhook-url'>{url}</span>
          <Button variant='outlined' onClick={copyFunction}>
            {tipForCopy ? 'Copied' : 'Copy'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(FlowDelayModal), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.actionId,
    ParamsEnums.triggerId,
    ParamsEnums.serviceId,
    ParamsEnums.scriptId
  ])
)
