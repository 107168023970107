import { Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { errorToast } from '../../components/customToast'

/**
 * Handles the change event for the text field.
 *
 * @param {React.ChangeEvent<HTMLInputElement>} e - The change event.
 */
export default function AuthTokenDescription({ setDescription }: { setDescription: React.Dispatch<React.SetStateAction<string>> }) {
  const [value, setValue] = useState('')

  const handleOnChange = (e) => {
    const newValue = e.target.value
    if (newValue.length > 50) {
      errorToast('Maximum 50 characters allowed')
    } else {
      setValue(newValue)
      setDescription(newValue)
    }
  }

  return (
    <Box>
      <Box className='flex-start-center w-100'>
        <Typography>Title</Typography>
      </Box>
      <TextField className='w-100' placeholder='Short Description for this Token' onChange={handleOnChange} value={value} size='small' />
    </Box>
  )
}
