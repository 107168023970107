import { useNavigate } from 'react-router-dom'
import { Send } from '@mui/icons-material'
import React, { useMemo, useRef, useState } from 'react'
import { Autocomplete, Box, IconButton, TextField, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums, Tabnames } from '../../enums'
import { getGlobalData } from '../../api/index'
import './search.scss'
import config from '../../config'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'

function Search({ orgId }) {
  const navigate = useNavigate()
  const [globalData, setGlobalData] = useState({ data: [], isSearching: false })
  const { allProjects } = useCustomSelector((state) => {
    return {
      allProjects: state?.projects?.projects[orgId]
    }
  })
  const textfieldRef = useRef('')

  const handleSearchBar = debounce(async (searchBarValue) => {
    if (searchBarValue.length === 0 || /^\s*$/.test(searchBarValue)) {
      return setGlobalData((prev) => {
        return { data: prev.data, isSearching: false }
      })
    }
    const getWords = searchBarValue.replace(/\s+/g, ' ')
    try {
      const data = await getGlobalData(getWords, orgId)
      setGlobalData({ data: data.data.data.data, isSearching: true })
    } catch (error) {
      setGlobalData((prev) => {
        return { data: prev.data, isSearching: false }
      })
    }
    return null
  }, 100)

  const options = useMemo(() => {
    return (
      globalData?.data?.flatMap((project) =>
        project.script_id.map((script) => ({
          ...script,
          project_id: project.project_id
        }))
      ) || []
    )
  }, [globalData])
  function handleAskAI() {
    window.askAi(textfieldRef.current?.querySelector('input').value)
    window.openChatbot()
  }
  return (
    <Box className='flex-start-center pointer-auto gap-2 w-100 py-2 px-3 bg-grey-white'>
      <Autocomplete
        options={options}
        autoFocus
        open
        fullWidth
        filterOptions={(options) => {
          return options.filter(() => true)
        }}
        groupBy={(option) => option.project_id}
        getOptionLabel={() => textfieldRef.current?.querySelector('input').value}
        // Prevent Autocomplete from clearing the TextField text on option select
        onChange={(_, value) => {
          if (value?.id) {
            // Navigate based on the selected option
            navigate(
              `${config.projectsBaseUrl}/${orgId}/${value?.project_id}/service/${value?.serviceId}${config.workflowBaseUrl}/${value?.id}/${Tabnames.DRAFT}`
            )
          }
        }}
        onInputChange={(_, value) => {
          // Update the input value and perform search
          handleSearchBar(value)
        }}
        renderInput={(params) => (
          <TextField
            ref={textfieldRef}
            autoFocus
            {...params}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleAskAI()
            }}
            placeholder='Search Flows Or Ask AI'
            variant='outlined'
          />
        )}
        clearIcon={null}
        freeSolo
        PaperComponent={options?.length ? undefined : null}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.title || `${option.serviceName || option.serviceId} Flow`}
          </li>
        )}
        renderGroup={(params) => (
          <li key={params.group} className='py-2'>
            <Typography variant='h7' marginInline='15px'>
              {allProjects?.active?.[params.group]?.title || allProjects?.deleted?.[params.group]?.title || params.group}
            </Typography>
            <ul style={{ paddingInlineStart: 0 }}>{params.children}</ul>
          </li>
        )}
      />
      <IconButton onClick={handleAskAI}>
        <Send />
      </IconButton>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(Search), [ParamsEnums.orgId]))
