import { createSlice } from '@reduxjs/toolkit'
import { initialState, reducers } from './dhPluginReducer.ts'

const developerHubPluginSlice = createSlice({
  name: 'DHPlugin',
  initialState,
  reducers
})

export const {
  updatePluginDetailsStart,
  updatePluginDetailsSuccess,
  updatePluginDetailsError,
  getAllPluginStart,
  getAllPluginSuccess,
  getAllPluginError,
  createNewPluginStart,
  createNewPluginSuccess,
  createNewPluginError,
  getPluginDetailStart,
  getPluginDetailSuccess,
  getPluginDetailError,
  deletePluginStart,
  deletePluginSuccess,
  deletePluginError,
  updatePluginDetailsInRedux
} = developerHubPluginSlice.actions
export default developerHubPluginSlice.reducer
