import { call, put } from 'redux-saga/effects'
import { getPlugDataV2 } from '../../../api'
import { onFetchPlugInFetchRejected, onFetchPlugInFetchSuccess } from './allPlugins.ts'

export function* startFetchPluginData() {
  try {
    const response = yield call(getPlugDataV2)
    const triggerData = response.filter((obj) => obj.istriggeravailable === true)
    yield put(onFetchPlugInFetchSuccess({ pluginData: response, triggerData: triggerData }))
  } catch (error) {
    console.error(error)
    yield put(onFetchPlugInFetchRejected({}))
  }
}
