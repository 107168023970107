/* eslint-disable-next-line import/no-cycle */

export const initialState = {
  modal: {}
}

export const reducers = {
  addModalData(state, payload) {
    if (payload.payload) {
      state.modal = {
        [payload.payload.modalType]: { ...payload.payload.plugData }
      }
    }
  }
}
