import React from 'react'
import { Box, Typography } from '@mui/material'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { useDispatch } from 'react-redux'
import { getProjectById } from '../../../store/projects/projectsSelector'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { toggleProjectSlider } from '../../../store/appInfo/appInfoSlice.ts'
// import AllInterfaceList from '../../../pages/interface/components/allInterfaceList/allInterfaceList.tsx'
import ScriptListV3 from '../scriptListV3.tsx'
import './scriptSliderBody.scss'

function ScriptSliderBodyV3({ orgId, projectId }) {
  const { projectDetails, isProjectSliderOpen } = useCustomSelector((state) => {
    return {
      projectDetails: getProjectById(state, projectId, orgId),
      isProjectSliderOpen: state.appInfo.isProjectSliderOpen
    }
  })
  const dispatch = useDispatch()
  const handleProjectTitleClick = () => {
    if (!isProjectSliderOpen) dispatch(toggleProjectSlider(true))
  }
  return (
    <Box className='slider__flow h-100 flex-col gap-3 p-3'>
      <Box className='flex-start-center gap-2 '>
        <FolderOutlinedIcon />
        <Typography onClick={handleProjectTitleClick} className='text-overflow-eclipse captilize-texttransform '>
          {projectDetails?.title || ''}
        </Typography>
      </Box>
      {/* Redering all the Active and Paused functions of a folder in a sliderbar */}
      <Box className='transition slider__maincotainer column gap-3 w-100 h-100'>
        <ScriptListV3 />
      </Box>
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(ScriptSliderBodyV3), [ParamsEnums.projectId, ParamsEnums.orgId]))
