import { all, fork } from 'redux-saga/effects'
import ChipSaga from './chip/chipSaga.ts'
import PluginSaga from './plugin/pluginSaga.ts'
import DeveloperHubPluginSaga from './developerHub/DHPlugin/dhPluginSaga.ts'
import DeveloperHubActionSaga from './developerHub/DHActions/dhActionSaga.ts'
import developerHubAuthenticaitonSaga from './developerHub/DHAuthentication/dhAuthenticationSaga.ts'
import flowJsonSaga from './flowJson/flowJsonSaga.ts'
import DHActionVersionSaga from './developerHub/DHActionVersion/dhActionVersionSaga.ts'
import InterfaceSaga from './interface/interfaceSaga.ts'
import NotificationSaga from './notification/notificationSaga'

export default function* rootSaga() {
  yield all([
    fork(ChipSaga),
    fork(PluginSaga),
    fork(DeveloperHubPluginSaga),
    fork(DeveloperHubActionSaga),
    fork(developerHubAuthenticaitonSaga),
    fork(DHActionVersionSaga),
    fork(flowJsonSaga),
    fork(InterfaceSaga),
    fork(NotificationSaga)
  ])
}
