import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import axios from '../../interceptor/interceptor'
import { errorToast } from '../../components/customToast'

function SelectOrgModal({ setShowOrgPopup }: any) {
  const [orgFields, setOrgFields] = useState([])
  const [orgId, setOrgId] = useState('')

  useEffect(() => {
    getOrgList()
  }, [])

  const getOrgList = async () => {
    try {
      const response = await (await axios.get(`${process.env.REACT_APP_API_BASE_URL}/userorg/getuserorg`)).data.data
      setOrgFields(response)
    } catch (error) {
      console.error(error)
    }
  }

  const onSelectOrg = (orgId) => {
    if (!orgId) {
      errorToast('Please select the org first')
      return
    }
    setOrgId(orgId)
    const newParams = new URLSearchParams(window.location.search)
    if (!newParams?.get('orgid')) {
      newParams.append('orgid', `${orgId}`)
    }
    // Update the URL with the new search parameters
    window.history.pushState(null, '', `${window.location.pathname}?${newParams}`)
    setShowOrgPopup(false)
  }

  return (
    <Box className='dialogMinMax'>
      <Typography className='p-3' variant='h5'>
        Select Organization
      </Typography>
      <div role='listbox' style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc', padding: '12px' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {orgFields.map((org) => (
            <li
              key={org.id}
              onClick={() => onSelectOrg(org.id)}
              onKeyDown={() => onSelectOrg(org.id)}
              aria-selected={orgId === org.id ? 'true' : 'false'}
              role='option'
              className='block'
              tabIndex={0}
              style={{ cursor: 'pointer', margin: '5px 0', padding: '4px' }}
            >
              {org.title}
            </li>
          ))}
        </ul>
      </div>
    </Box>
  )
}

export default SelectOrgModal
