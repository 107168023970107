import React from 'react'
import BasicDropDown from './basicDropdown/basicDropdown'
import BasicInput from './basicInput/basicInput'

// authentication will always be defined even if authFields is null or undefined. this is condition for destructuring
/**
 * This component renders the authentication fields based on the provided authFields.
 * It iterates through the fields array and renders either a BasicDropDown or BasicInput component
 * depending on the field type. The component also handles the state change of the authentication data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.authFields - The authentication fields object.
 * @param {Function} props.setAuthData - The function to set the authentication data.
 */
function AuthFields({ authFields = {}, setAuthData }) {
  let fields = {}

  const { authentication = {} } = authFields || {}
  fields = authentication?.fields || []

  return (
    <div className='w-100'>
      {fields?.map((field) => {
        if (field.type?.toLowerCase() === 'dropdown') return <BasicDropDown key={field?.key} field={field} setAuthData={setAuthData} />
        if (field.type?.toLowerCase() === 'string' || field.type?.toLowerCase() === 'password')
          return <BasicInput field={field} key={field?.key} setAuthData={setAuthData} />
        return null
      })}
    </div>
  )
}
export default React.memo(AuthFields)
