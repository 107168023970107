import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import LinearProgress from '@mui/material/LinearProgress'
import { useDispatch } from 'react-redux'
import config from '../../config'
import { fetchOrgs } from '../../store/orgs/orgsThunk'
import { saveUser } from '../../store/user/userThunk'
import { signUpUser } from '../../api'
import './login.scss'
import {
  getCurrentEnvironment,
  getFromCookies,
  isJSONString,
  isUserCreatedToday,
  removeCookie,
  removeUTMSource,
  sendUserUtmSource,
  setInCookies
} from '../../utils/utilities'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { THUNK_CALL_STATUS } from '../../enums'

function MyComponent() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const domain = getSubdomain()
  const environment = getCurrentEnvironment()
  const searchParams = new URLSearchParams(location.search)
  const queryParams = {}
  // Convert searchParams to an array and use array iteration
  Array.from(searchParams.entries()).forEach(([key, value]) => {
    queryParams[key] = value
  })

  async function runEffect() {
    if (process.env.REACT_APP_API_ENVIRONMENT === 'prod' && !Object.keys(queryParams).length && !getFromCookies(environment))
      window.location.replace(process.env.REACT_APP_VIASOCKET_HOME_PAGE_URL)
    if (!queryParams['proxy_auth_token'] && !getFromCookies(environment)) {
      const configuration = {
        referenceId: process.env.REACT_APP_REFERENCE_ID,
        state: queryParams['state'],
        success: (data) => {
          // get verified token in response
          console.log('success response', data)
        },
        failure: (error) => {
          // handle error
          console.log('failure reason', error)
        }
      }
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.onload = () => {
        const checkInitVerification = setInterval(() => {
          if (typeof initVerification === 'function') {
            clearInterval(checkInitVerification)
            // eslint-disable-next-line no-undef
            initVerification(configuration)
          }
        }, 100)
      }
      script.src = 'https://proxy.msg91.com/assets/proxy-auth/proxy-auth.js'
      document.body.appendChild(script)
      redirectToProject()
    }
    redirectToProject()
  }

  useEffect(() => {
    runEffect()
  }, [location.search])

  const redirectToProject = async () => {
    const token = getFromCookies(getCurrentEnvironment())
    if (!token) return
    const userResponse = await dispatch(saveUser())
    if (userResponse?.meta?.requestStatus !== THUNK_CALL_STATUS.FULFILLED) return
    const userData = userResponse.payload
    // const isOnboardingdone = Boolean(userData?.currentCompany?.meta?.timezoneIdentifier || (userData?.c_companies || [])?.length > 1)
    // const currentCompanyId = userData?.currentCompany?.id
    if (!userData) {
      removeCookie(getCurrentEnvironment())
      localStorage.clear()
      sessionStorage.clear()
      return
    }
    if (process.env.REACT_APP_API_ENVIRONMENT === 'local') {
      const response = await signUpUser({ ...userData, org_id: parseInt(queryParams['company_ref_id'], 10) })
      setInCookies(environment, response?.data?.data?.token)
    }
    const orgListResponse = await dispatch(fetchOrgs())
    if (orgListResponse?.meta?.requestStatus !== THUNK_CALL_STATUS.FULFILLED) return
    dispatch(updateAppInfoState({ currentOrgId: queryParams['company_ref_id'] }))

    const previousUrlData = JSON.parse(getFromCookies(`${getCurrentEnvironment()}-previous_url`) || '{}')
    if (isUserCreatedToday(userData?.created_at)) {
      const { utmSource, url } = removeUTMSource(previousUrlData?.previous_url)
      if (utmSource) {
        previousUrlData.previous_url = url
        sendUserUtmSource(utmSource, userData.id)
      }
      if (isJSONString(queryParams?.state)?.utm_source) {
        sendUserUtmSource(isJSONString(queryParams?.state)?.utm_source, userData.id)
      }
    }
    // if (!isOnboardingdone) navigate(`/onboarding/${currentCompanyId}`)
    if (previousUrlData?.previous_url) {
      navigate(previousUrlData.previous_url)
    } else {
      navigate(config?.orgBaseUrl)
    }

    removeCookie(`${process.env.REACT_APP_API_ENVIRONMENT}-previous_url`)
  }

  return (
    <Box className='back-to-login flex-col flex-center-center'>
      {!queryParams['proxy_auth_token'] ? (
        <div id={process.env.REACT_APP_REFERENCE_ID} />
      ) : (
        <Box className='w-25'>
          <LinearProgress />
        </Box>
      )}
    </Box>
  )
}

export default MyComponent
