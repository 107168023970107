import { SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import cloneDeep from 'lodash.clonedeep'
import { ACTION_TYPE } from '../../../pages/developerHub/constants/developerHubConstants.ts'
import {
  ActionFields
  //  ActionVersionDataType, ActionVersionFields
} from '../../../types/DHAction.ts'
import { $DHActionReduxType, actionReduxObjectType } from '../../../types/reduxCore.ts'
import actionType from '../../../types/utility.ts'
import { getInfoParamtersFromUrl } from '../../../utils/utilities'

const sampleActionReducerData = {
  allActionsData: {},
  allTriggersData: {},
  allCustomActionData: {},
  customActionCreatingByAi: false,
  isLoading: false
}
let previousStateData: actionReduxObjectType = { ...sampleActionReducerData }
export const initialState: $DHActionReduxType = {}

export const reducers: ValidateSliceCaseReducers<$DHActionReduxType, SliceCaseReducers<$DHActionReduxType>> = {
  // All Actions
  getAllActionStart(state) {
    // state.isLoading = true
    const { pluginId } = getInfoParamtersFromUrl()
    if (!state[pluginId]) {
      state[pluginId] = { ...sampleActionReducerData, isLoading: true }
    }
  },
  getAllActionSuccess(state, action: actionType<{ data: ActionFields[]; pluginId: string }>) {
    const { pluginId } = action.payload
    const allActions: { [actionId: string]: ActionFields } = {}
    const allTriggers: { [actionId: string]: ActionFields } = {}
    const allCustomActionData: { [actionId: string]: ActionFields } = {}
    action?.payload?.data?.forEach((action: ActionFields) => {
      if (action?.type === ACTION_TYPE?.action) {
        allActions[action?.rowid] = action
      } else if (action?.type === ACTION_TYPE?.trigger) {
        allTriggers[action?.rowid] = action
      } else if (action?.type === ACTION_TYPE?.customAction) {
        allCustomActionData[action?.rowid] = action
      }
    })

    state[pluginId] = {
      ...state[pluginId],
      allActionsData: allActions,
      allTriggersData: allTriggers,
      allCustomActionData: allCustomActionData,
      isLoading: false
    }
  },
  getAllActionError(state, action) {
    const { pluginId } = action.payload
    state[pluginId] = sampleActionReducerData
  },

  // GET Action Details
  getActionDetailStart(state, action) {
    const { pluginId } = action.urlData
    // Safely access the plugin-specific data using optional chaining and provide a default empty object if undefined
    const pluginData = state?.[pluginId] || {}
    const { rowid, type } = action.payload
    if (!state?.[pluginId]) {
      state[pluginId] = {
        allActionsData: { [rowid]: { isLoading: true } },
        allCustomActionData: { [rowid]: { isLoading: true } },
        allTriggersData: { [rowid]: { isLoading: true } }
      }
    }
    const { allActionsData = {}, allTriggersData = {}, allCustomActionData = {} } = pluginData

    // Check and set loading state based on action type
    if (type === 'action' && allActionsData[rowid]) {
      allActionsData[rowid].isLoading = true
    } else if (type === 'trigger' && allTriggersData[rowid]) {
      allTriggersData[rowid].isLoading = true
    } else if (type === 'customAction' && allCustomActionData[rowid]) {
      allCustomActionData[rowid].isLoading = true
    }
  },

  getActionDetailSuccess(state, action: actionType<{ data: ActionFields[]; pluginId: string }>) {
    const { pluginId = '' } = action.payload
    const { type = '', rowid = '' } = action.payload?.data?.[0]
    const { allActionsData = {}, allTriggersData = {}, allCustomActionData = {} } = state[pluginId] || {}
    if (pluginId && type && rowid) {
      if (type === ACTION_TYPE.action) {
        allActionsData[rowid] = { ...action?.payload?.data?.[0], isLoading: false }
      } else if (type === ACTION_TYPE?.trigger) {
        allTriggersData[rowid] = { ...action?.payload?.data?.[0], isLoading: false }
      } else if (type === ACTION_TYPE?.customAction) {
        allCustomActionData[rowid] = { ...action?.payload?.data?.[0], isLoading: false }
      }
    }
  },
  getActionDetailError(state, action: actionType<{ data: { rowid: string; type: 'action' | 'trigger' }; pluginId: string }>) {
    const { pluginId = '' } = action.payload
    const { allActionsData = {}, allCustomActionData = {}, allTriggersData = {} } = state[pluginId] || {}
    const { type = '', rowid = '' } = action.payload?.data
    if (pluginId && type && rowid) {
      if (type === ACTION_TYPE.action) {
        allActionsData[rowid].isLoading = false
      } else if (type === ACTION_TYPE?.trigger) {
        allTriggersData[rowid].isLoading = false
      } else if (type === ACTION_TYPE?.customAction) {
        allCustomActionData[rowid].isLoading = false
      }
    }
  },

  // Update Action Details
  updateActionDetailsStart(state, action: actionType<ActionFields>) {
    const { type = ACTION_TYPE.action } = action.payload
    const { pluginId } = action.urlData
    let sectionId = action.urlData.sectionId
    if (action.payload.rowid) {
      sectionId = action.payload.rowid
    }
    const { allActionsData, allTriggersData, allCustomActionData } = state[pluginId]
    if (type === ACTION_TYPE.action) {
      previousStateData = { ...previousStateData, allActionsData: { [sectionId]: cloneDeep(allActionsData[sectionId]) } }
      allActionsData[sectionId] = { ...allActionsData[sectionId], ...action?.payload, isLoading: true }
    } else if (type === ACTION_TYPE?.trigger) {
      previousStateData = { ...previousStateData, allTriggersData: { [sectionId]: cloneDeep(allTriggersData[sectionId]) } }
      allTriggersData[sectionId] = { ...allTriggersData[sectionId], ...action?.payload, isLoading: true }
    } else if (type === ACTION_TYPE?.customAction) {
      previousStateData = { ...previousStateData, allCustomActionData: { [sectionId]: cloneDeep(allCustomActionData[sectionId]) } }
      allCustomActionData[sectionId] = { ...allCustomActionData[sectionId], ...action?.payload, isLoading: true }
    }
  },

  updateActionDetailsSuccess(state, action: actionType<{ data: ActionFields; pluginId: string; actionId: string }>) {
    const { pluginId } = action.payload
    const { allActionsData, allCustomActionData, allTriggersData } = state[pluginId]
    const dataFromDbDash = action.payload?.data
    let actionId = action.payload?.actionId
    if (action.payload?.data?.rowid) {
      actionId = action.payload?.data?.rowid
    }
    const { type = ACTION_TYPE.action } = dataFromDbDash
    previousStateData = { ...sampleActionReducerData }
    if (type === ACTION_TYPE.action) {
      allActionsData[actionId] = { ...allActionsData[actionId], ...dataFromDbDash, isLoading: false }
    } else if (type === ACTION_TYPE.customAction) {
      allCustomActionData[actionId] = { ...allCustomActionData[actionId], ...dataFromDbDash, isLoading: false }
    } else {
      allTriggersData[actionId] = { ...allTriggersData[actionId], ...dataFromDbDash, isLoading: false }
    }
  },

  updateActionDetailsError(state, action: actionType<{ data: ActionFields; pluginId: string; actionId: string }>) {
    const { type = ACTION_TYPE.action } = action.payload?.data
    const { pluginId } = action.payload
    const { allActionsData, allTriggersData, allCustomActionData } = state[pluginId]
    let actionId = action.payload?.actionId
    if (action.payload?.data?.rowid) {
      actionId = action.payload?.data?.rowid
    }
    if (type === ACTION_TYPE.action) {
      allActionsData[actionId] = { ...previousStateData?.allActionsData?.[actionId], isLoading: false }
    } else if (type === ACTION_TYPE?.trigger) {
      allTriggersData[actionId] = { ...previousStateData?.allTriggersData?.[actionId], isLoading: false }
    } else if (type === ACTION_TYPE?.customAction) {
      allCustomActionData[actionId] = { ...previousStateData?.allCustomActionData?.[actionId], isLoading: false }
    }
  },

  // Create New Action
  createNewActionStart(state, action: actionType<ActionFields>) {
    const { type } = action.payload
    const { pluginId } = action.urlData
    const { allActionsData, allTriggersData, allCustomActionData } = state[pluginId]
    if (type === ACTION_TYPE.action) {
      allActionsData['ACTION_ID'] = { ...action?.payload, isLoading: true }
    } else if (type === ACTION_TYPE?.trigger) {
      allTriggersData['ACTION_ID'] = { ...action?.payload, isLoading: true }
    } else if (type === ACTION_TYPE?.customAction) {
      allCustomActionData['ACTION_ID'] = { ...action?.payload, isLoading: true }
    }
  },
  createNewActionSuccess(state, action: actionType<{ data: ActionFields; pluginId: string }>) {
    const { type, rowid } = action.payload?.data
    const { pluginId } = action.payload
    const { allActionsData, allCustomActionData, allTriggersData } = state[pluginId]
    if (type === ACTION_TYPE?.action) {
      allActionsData[rowid] = { ...action.payload?.data, isLoading: false }
      delete allActionsData['ACTION_ID']
    } else if (type === ACTION_TYPE?.trigger) {
      allTriggersData[rowid] = { ...action.payload?.data, isLoading: false }
      delete allTriggersData['ACTION_ID']
    } else if (type === ACTION_TYPE?.customAction) {
      allCustomActionData[rowid] = { ...action.payload?.data, isLoading: false }
      delete allCustomActionData['ACTION_ID']
    }
  },
  createNewActionError(state, action: actionType<{ data: ActionFields; pluginId: string }>) {
    const { type } = action.payload?.data
    const { pluginId } = action.payload
    const { allActionsData, allCustomActionData, allTriggersData } = state[pluginId]
    if (type === ACTION_TYPE?.action) {
      delete allActionsData['ACTION_ID']
    } else if (type === ACTION_TYPE?.trigger) {
      delete allTriggersData['ACTION_ID']
    } else if (type === ACTION_TYPE?.customAction) {
      delete allCustomActionData['ACTION_ID']
    }
  },

  deleteActionStart(state, action) {
    const { type, rowid } = action.payload
    const { pluginId } = action.urlData
    const { allActionsData, allTriggersData, allCustomActionData } = state[pluginId]
    if (type === ACTION_TYPE.action) {
      allActionsData[rowid].isLoading = true
    } else if (type === ACTION_TYPE?.trigger) {
      allTriggersData[rowid].isLoading = true
    } else if (type === ACTION_TYPE?.customAction) {
      allCustomActionData[rowid].isLoading = true
    }
  },

  deleteActionSuccess(state, action) {
    const { type, rowid } = action.payload
    const { pluginId } = action.urlData
    const { allActionsData, allCustomActionData, allTriggersData } = state[pluginId]
    if (type === ACTION_TYPE?.action) {
      delete allActionsData[rowid]
    } else if (type === ACTION_TYPE?.trigger) {
      delete allTriggersData[rowid]
    } else if (type === ACTION_TYPE?.customAction) {
      delete allCustomActionData[rowid]
    }
  },

  deleteActionError(state, action) {
    const { type, rowid } = action.payload
    const { pluginId } = action.urlData
    const { allActionsData, allTriggersData, allCustomActionData } = state[pluginId]
    if (type === ACTION_TYPE.action) {
      allActionsData[rowid].isLoading = false
    } else if (type === ACTION_TYPE?.trigger) {
      allTriggersData[rowid].isLoading = false
    } else if (type === ACTION_TYPE?.customAction) {
      allCustomActionData[rowid].isLoading = false
    }
  },
  bulkActionCreateSuccess(state, action: actionType<{ data: ActionFields[]; pluginId: string }>) {
    const pluginId = action.payload.pluginId
    const { allActionsData, allCustomActionData, allTriggersData } = state[pluginId]
    for (let i = 0; i <= action.payload.data?.length - 1; i++) {
      const data = action.payload?.data[i]
      const { type, rowid } = data
      if (type === ACTION_TYPE?.action) {
        allActionsData[rowid] = { ...data, isLoading: false }
      } else if (type === ACTION_TYPE?.trigger) {
        allTriggersData[rowid] = { ...data, isLoading: false }
      } else if (type === ACTION_TYPE?.customAction) {
        allCustomActionData[rowid] = { ...data, isLoading: false }
      }
    }
  },
  createCustomActionByAiSuccess(state, action: actionType<ActionFields>) {
    const { pluginrecordid, rowid } = action.payload
    state[pluginrecordid] = {
      ...state[pluginrecordid],
      allCustomActionData: {
        [rowid]: action.payload
      },
      customActionCreatingByAi: false
    }
  },
  createCustomActionByAiError(state, action) {
    const { pluginId } = action.payload
    state[pluginId].customActionCreatingByAi = false
  },
  setActionStateToIntial() {
    return initialState
  }
}
