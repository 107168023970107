import { createSlice } from '@reduxjs/toolkit'

// // reducer imports
import { initialState, reducers, extraReducers } from './orgsReducer'

const orgsSlice = createSlice({
  name: 'orgs',
  initialState,
  reducers,
  extraReducers
})

export const { addOrgInRedux, remove, setId } = orgsSlice.actions

export default orgsSlice.reducer
