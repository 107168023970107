import { useQuery } from '@tanstack/react-query'
import {
  getActionTrigger,
  getActionsOrTriggersQueryForAuthFn,
  getActionsQueryFn,
  getAuthDetailsQueryFn,
  getOnePlugin,
  getPluginsQueryFn,
  isAuthValidFn
} from './allPluginsQueryFunctions.ts'
import {
  getAfterSignupFields,
  getAuthenticatedPlugs,
  getCountries,
  getOneLogFromFlowHitId,
  getParterDiscountData,
  getStepMetricsFailedCount
} from '../../api/index'
import { getInitialPluginWithActionsApi } from '../../api/pluginApis/pluginApis.ts'

export function useFetchPlugins(orgId: string, categories: string[] = [], filteredPlugins: string[] = [], withTriggers: boolean = false) {
  return useQuery({
    queryKey: [withTriggers ? 'pluginsWithTriggers' : 'plugins', orgId, ...categories, ...filteredPlugins],
    queryFn: () => getPluginsQueryFn(orgId, withTriggers, categories, filteredPlugins),
    staleTime: 1000 * 60 * 60 * 24,
    enabled: Boolean(orgId),
    refetchOnWindowFocus: false
  })
}

export function useFetchActionsTriggers(
  pluginId: string,
  actionType: string = 'actions',
  allowedEvents: string[] = [],
  tableName: string = 'action',
  enabled: boolean = true
) {
  return useQuery({
    queryKey: [actionType, pluginId, ...allowedEvents],
    queryFn: () => getActionsQueryFn(pluginId ?? '', actionType, tableName, allowedEvents),
    enabled:
      enabled && pluginId !== undefined && pluginId !== '' && pluginId !== 'webhook' && pluginId !== 'function' && pluginId !== 'api',
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false
  })
}

export function useInitialPluginsWithActions(orgId: string, filteredActions: string[] = []) {
  return useQuery({
    queryKey: ['pluginsWithEvent', orgId, filteredActions],
    queryFn: () => getInitialPluginWithActionsApi(filteredActions), // get all plugins
    staleTime: 1000 * 60 * 60 * 24,
    enabled: Boolean(orgId),
    refetchOnWindowFocus: false
  })
}

export function useAllAuthenticatedPlugins(orgId: string) {
  return useQuery({
    queryKey: ['authenticatedServices', orgId],
    queryFn: () => getAuthenticatedPlugs(orgId), // get all plugins
    staleTime: 1000 * 60 * 60 * 24,
    enabled: Boolean(orgId),
    refetchOnWindowFocus: false
  })
}

export function useFailedStepCount(orgId) {
  return useQuery({
    queryKey: ['failedCount', orgId],
    queryFn: () => getStepMetricsFailedCount(orgId),
    staleTime: 1000 * 60 * 1,
    cacheTime: 1000 * 60 * 10
  })
}
/**
 * Custom hook to fetch actions for a specific plugin and table.
 *
 * @param {string} pluginId - The unique identifier for the plugin.
 * @param {string} tableName - The name of the table to fetch actions from.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns The query object containing the status of the fetch and the data.
 */

/**
 * Custom hook to fetch either actions or triggers for a specific plugin and table, used for authentication purposes.
 *
 * @param {string} pluginId - The unique identifier for the plugin.
 * @param {string} tableName - The name of the table to fetch actions or triggers from.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @returns The query object containing the status of the fetch and the data.
 */
export function useActionsOrTriggerForAuth(pluginId: string, tableName: string, enabled: boolean = true) {
  return useQuery({
    queryKey: ['actionsOrTriggerAuth', pluginId],
    queryFn: () => getActionsOrTriggersQueryForAuthFn(pluginId ?? '', tableName, true),
    enabled,
    refetchOnWindowFocus: false
    // staleTime: 1000 * 60 * 60 * 24
  })
}

/**
 * Custom hook to fetch authentication details for a specific plugin.
 *
 * @param {string} [pluginId=''] - The unique identifier for the plugin. Defaults to an empty string.
 * @param {string} [authId=''] - The unique identifier for the authentication record. Defaults to an empty string.
 * @param {boolean} [enabled=true] - Flag to enable or disable the query. Defaults to true.
 * @param {boolean} [isUserOnDH=false] - Flag indicating if the user is on Data Hub. Defaults to false.
 * @returns The query object containing the status of the fetch and the data.
 */
export function useAuth(pluginId: string = '', authId: string = '', enabled: boolean = true, isUserOnDH: boolean = false) {
  return useQuery({
    queryKey: ['authData', authId, pluginId],
    queryFn: () =>
      getAuthDetailsQueryFn(
        {
          filterField: 'pluginrecordid',
          plugId: pluginId ?? '',
          secondFilterField: 'rowid',
          authId: authId ?? '',
          tableName: 'auth',
          isUserOnDH
        },
        true
      ),
    staleTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
    refetchOnWindowFocus: false
  })
}

/**
 * Custom hook to fetch data for a single plugin.
 *
 * @param {string} pluginId - The unique identifier for the plugin.
 * @param {boolean} enabled - Flag to enable or disable the query.
 * @param {boolean} [isUserOnDH=false] - Flag indicating if the user is on Data Hub. Defaults to false.
 * @returns The query object containing the status of the fetch and the data.
 */

export function getOnePluginData(pluginId: string, enabled: boolean, isUserOnDH: boolean = false) {
  return useQuery({
    queryKey: ['pluginData', pluginId],
    queryFn: () =>
      getOnePlugin(
        {
          filterField: 'rowid',
          plugId: pluginId ?? '',
          tableName: 'plugin',
          isUserOnDH
        },
        true
      ),
    staleTime: 1000 * 60 * 60 * 24,
    enabled,
    refetchOnWindowFocus: false
  })
}

export function useGetIsAuthorizationValid(authIdentifier: string, enabled: boolean) {
  return useQuery({
    queryKey: ['authValid', authIdentifier],
    queryFn: () => isAuthValidFn(authIdentifier),
    enabled,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30
  })
}
export function getActionAndTrigger(filter: object, enabled: boolean = true) {
  const notEmpty = Object.keys(filter).length !== 0

  return useQuery({
    queryKey: ['validAction', filter],
    queryFn: () => getActionTrigger(filter),
    refetchOnWindowFocus: false,
    enabled: enabled && notEmpty,
    staleTime: 1000 * 60 * 60 * 24
  })
}

export function useFetchSingleLog(scriptId: string, parentFlowhitId: string) {
  return useQuery({
    queryKey: ['FetchSingleLog', scriptId, parentFlowhitId],
    queryFn: () => getOneLogFromFlowHitId(scriptId, parentFlowhitId),
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
    enabled: !!parentFlowhitId
  })
}

export function getPartenerDiscount() {
  return useQuery({
    queryKey: ['partnerDiscounts'],
    queryFn: getParterDiscountData,
    staleTime: 24 * 60 * 60 * 1000
  })
}

export function AfterSignupFields() {
  return useQuery({
    queryKey: ['getAfterSignupFields'],
    queryFn: getAfterSignupFields,
    staleTime: 24 * 60 * 60 * 1000
  })
}

export function getCountriesList() {
  return useQuery({
    queryKey: ['getCountries'],
    queryFn: getCountries,
    staleTime: 24 * 60 * 60 * 1000
  })
}
