import React, { useEffect, useMemo, useState } from 'react'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import FeaturedCombinationsComponent from './FeaturedCombinationsComponent.tsx'
import { getRecommendations } from '../../../api/index'
import { ParamsEnums } from '../../../enums'

interface DefaultCombinationsProps {
  createflow: () => void
  enabledServiceObject: Record<string, any>
  editor?: boolean
  firstServiceId: string
}
/**
 * DefaultCombinations component for displaying featured combinations based on recommendations.
 *
 * @param {Object} props - The component props
 * @param {Function} props.createflow - Function to create a new flow
 * @param {Object} props.enabledServiceObject - Object containing enabled services
 * @param {boolean} [props.editor=false] - Flag to indicate if in editor mode
 * @param {string} props.firstServiceId - ID of the first service
 * @returns {React.ReactElement|null} The DefaultCombinations component or null if not in production environment
 */

function DefaultCombinations({ createflow, enabledServiceObject, editor = false, firstServiceId }: DefaultCombinationsProps) {
  if (process.env.REACT_APP_API_ENVIRONMENT !== 'prod') return null
  if (firstServiceId === 'webhook') return null
  const [combinations, setCombinations] = useState({})
  useEffect(() => {
    async function fetchData() {
      const data = (await getRecommendations(firstServiceId)) || []
      const result = {}
      data.forEach((combo) => {
        result[`${combo?.trigger?.id}_${combo?.actions[0]?.id}`] = true
      })
      setCombinations(result)
    }

    fetchData()
  }, [firstServiceId])

  const filter = useMemo(() => {
    let eventsarray: Array<string> = []
    if (combinations) {
      Object.keys(combinations || {})?.forEach((combination) => {
        eventsarray = [...eventsarray, ...combination?.split('_')]
      })
    }
    return { rowid: eventsarray }
  }, [combinations])
  return filter ? (
    <FeaturedCombinationsComponent
      createflow={createflow}
      filter={filter}
      combinations={combinations}
      enabledServiceObject={enabledServiceObject}
      editor={editor}
      isDefault
    />
  ) : null
}
export default React.memo(addUrlDataHoc(React.memo(DefaultCombinations), [ParamsEnums.orgId, ParamsEnums.projectId]))
