import { ChevronLeft, Close } from '@mui/icons-material'
import { Box, Button, IconButton } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ParamsEnums, Tabnames } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { closeSliderThunk } from '../../store/stepsV2/stepThunkV2'

function CloseSliderButton({
  setSearchParams,
  versionIdOrStepId,
  versionId,
  tabName,
  isBackButton = false,
  isDoneButtonCancel = false,
  isCurrentAndSelectedTriggerSame = true,
  ignoreFullScreenCondition = false,
  type = 'button'
}: {
  setSearchParams: any
  versionIdOrStepId: boolean
  versionId: string
  tabName: string
  isDoneButtonCancel: boolean
  isBackButton: boolean
  ignoreFullScreenCondition?: boolean
}) {
  const dispatch = useDispatch()

  const sendMessageToParent = () => {
    window.parent.postMessage({ type: 'close-slider' }, '*')
  }

  window.addEventListener('message', (e) => {
    if (e?.data?.type === 'close-slider') dispatch(updateAppInfoState({ compareSliderOpen: false }))
  })

  const handleCloseButton = () => {
    dispatch(closeSliderThunk({ isCurrentAndSelectedTriggerSame, versionId, versionIdOrStepId, setSearchParams }))
  }

  return isBackButton ? (
    <Box className='w-100 fullscreen-show '>
      <Button color='info' startIcon={<ChevronLeft />} onClick={handleCloseButton}>
        Back
      </Button>
    </Box>
  ) : (
    <Box className={tabName !== Tabnames.PUBLISH && !ignoreFullScreenCondition && 'fullscreen-hide'}>
      {type === 'icon' ? (
        <IconButton onClick={tabName === 'published' ? sendMessageToParent : handleCloseButton}>
          <Close />
        </IconButton>
      ) : (
        <Button
          variant={`${isDoneButtonCancel ? 'contained' : 'text'}`}
          onClick={tabName === 'published' ? sendMessageToParent : handleCloseButton}
        >
          {isDoneButtonCancel ? 'Done' : 'Cancel'}
        </Button>
      )}
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(CloseSliderButton), [ParamsEnums?.versionIdOrStepId, ParamsEnums?.tabName, ParamsEnums.versionId])
)
