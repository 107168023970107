// this function will provide the array for the ai in short
export function processSupportedLibraryData(context) {
  if (!Array.isArray(context)) {
    return []
  }

  const result = context?.map((item) => {
    if (item.name === item.variable_name) return item.name
    return `${item.name} as ${item.variable_name}`
  })

  return result
}
