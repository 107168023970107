import { takeEvery, takeLatest } from 'redux-saga/effects'
import { createNewActionSaga, deleteActionSaga, getActionDetailsSaga, getAllActionSaga, setActionDetailsSaga } from './dhActionFunctions.ts'

export default function* DeveloperHubActionSaga() {
  yield takeEvery('DHAction/getAllActionStart', getAllActionSaga)
  yield takeLatest('DHAction/createNewActionStart', createNewActionSaga)
  yield takeEvery('DHAction/getActionDetailStart', getActionDetailsSaga)
  yield takeLatest('DHAction/updateActionDetailsStart', setActionDetailsSaga)
  yield takeLatest('DHAction/deleteActionStart', deleteActionSaga)
}
