import { Box, Button, Dialog, Typography } from '@mui/material'
import FastForwardIcon from '@mui/icons-material/FastForward'
import React, { useEffect, useState } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { ParamsEnums } from '../../enums'

function VideoPlayer({ openModal, onClose = () => {}, type, scriptId, eventId, forceOpen = false }) {
  const { scriptCount, scriptsIsLoading } = useCustomSelector((state: $ReduxCoreType) => ({
    scriptCount: Object.keys(state.scripts?.scripts)?.length || 0,
    scriptsIsLoading: state.scripts?.isLoading
  }))

  const [open, setOpen] = useState(false)
  const [details, setDetails] = useState({
    id: 'script',
    heading: 'Welcome to ViaSocket: A Quick Start Guide',
    description: 'Watch this short video to learn more about Flows!',
    embedUrl: 'https://video-faq.viasocket.com/embed/clvmcjtww0xqjsa1f4vwczf4x',
    buttonTag: 'Continue to Flow'
  })

  useEffect(() => {
    setDetailsToShow()
  }, [scriptCount, scriptsIsLoading, scriptId, eventId, openModal])

  const setDetailsToShow = () => {
    if (openModal) {
      if (scriptCount < 3 && !scriptsIsLoading) {
        setOpen(true)
        switch (type) {
          case 'appintegration':
            setDetails({
              id: 'script',
              heading: 'Welcome to ViaSocket: A Quick Start Guide',
              description: 'Watch this short video to learn more about App Integration!',
              embedUrl: 'https://video-faq.viasocket.com/embed/clw0lsked00t0ioft6u06lv2s',
              buttonTag: 'Continue to Flow'
            })
            break
          case 'webhook':
            setDetails({
              id: 'script',
              heading: 'Welcome to ViaSocket: A Quick Start Guide',
              description: 'Watch this short video to learn more about Webhooks!',
              embedUrl: 'https://video-faq.viasocket.com/embed/clw0m5m301nw2kzemqaeoowu9',
              buttonTag: 'Continue to Flow'
            })
            break
          case 'cron':
            setDetails({
              id: 'script',
              heading: 'Welcome to ViaSocket: A Quick Start Guide',
              description: 'Watch this short video to learn more about Cron!',
              embedUrl: 'https://video-faq.viasocket.com/embed/clw0mtbhi1o4mkzemm6t1whh2',
              buttonTag: 'Continue to Flow'
            })
            break
          case 'email':
            setDetails({
              id: 'script',
              heading: 'Welcome to ViaSocket: A Quick Start Guide',
              description: 'Watch this short video to learn more about Email to Flow!',
              embedUrl: 'https://video-faq.viasocket.com/embed/clw0nhmmz1ocqkzemypn9mllg',
              buttonTag: 'Continue to Flow'
            })
            break

          default:
            setDetails({
              id: 'script',
              heading: 'Welcome to ViaSocket: A Quick Start Guide',
              description: 'Watch this short video to learn more about Flows!',
              embedUrl: 'https://video-faq.viasocket.com/embed/clvmcjtww0xqjsa1f4vwczf4x',
              buttonTag: 'Continue to Flow'
            })
        }
      }
    }
  }

  return (
    <Box className=''>
      <Dialog open={forceOpen || open} maxWidth='xl'>
        <Box className='w-100'>
          <Box className='flex-col gap-2 mx-3 mt-3 '>
            <Box className='flex-spaceBetween-center w-100'>
              <Box className=' flex-center-center'>
                <Typography variant='h4' style={{ color: '#333' }} className='ml-2'>
                  {details.heading}
                </Typography>
              </Box>
              <Button
                className=''
                sx={{ borderRadius: '4px' }}
                onClick={() => {
                  setOpen(false)
                  onClose()
                }}
                variant='outlined'
                endIcon={<FastForwardIcon />}
              >
                {details.buttonTag}
              </Button>
            </Box>
            <Box className=''>
              <Box style={{ overflow: 'hidden', height: '35.75vw' }}>
                {window.parent === window && (
                  <iframe
                    src={details.embedUrl}
                    allow='clipboard-write'
                    title='viasocket-tutorial'
                    webkitallowfullscreen='true'
                    mozallowfullscreen='true'
                    style={{ width: '100%', height: '100%', border: 0 }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(VideoPlayer), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.serviceId,
    ParamsEnums.eventId
  ])
)
