import React from 'react'
import { Box, Chip } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setFunctionJsonThunkV3 } from '../../store/stepsV2/stepThunkV2'
import { addNewStepToStepData, setHasUnsavedCode, setKeyValueInstance, setSelectedActionDetails } from '../../store/stepsV2/stepSliceV3.ts'
import { errorToast } from '../customToast'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { BlockTypes, ParamsEnums, STEP_OPERATION_STATUS } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { getApiInstanceDataFromCode } from '../../store/stepsV2/stepReducerV3.ts'

function ResetButtonForSlider({ slugName, scriptId, stepId }) {
  const dispatch = useDispatch()
  const { flowJsonBlocks, hasUnsavedCode, currentStepType, triggerDetails } = useCustomSelector((state: $ReduxCoreType) => ({
    flowJsonBlocks: state?.flowJsonV2[scriptId]?.flowJson?.blocks?.[slugName],
    triggerDetails: state?.flowJsonV2[scriptId]?.flowJson?.trigger,
    hasUnsavedCode: state?.stepsDataV3?.[scriptId]?.[stepId]?.hasUnsavedCode,
    currentStepType: state?.appInfo?.currentStepType
  }))
  const handleClick = () => {
    if (stepId === 'CRON_PRE_PROCESS') {
      let dataToUpdate = {}
      if (triggerDetails?.preProcess?.type === BlockTypes.API) {
        const draftInstanceToAppend = getApiInstanceDataFromCode(triggerDetails?.preProcess?.code)
        dataToUpdate = { ...draftInstanceToAppend }
      } else if (triggerDetails.preProcess.type === BlockTypes.PLUG && triggerDetails.preProcess) {
        dispatch(
          setSelectedActionDetails({
            pluginDataFromAction: triggerDetails.preProcess.code,
            iconUrl: triggerDetails?.preProcess?.code?.iconUrl
          })
        )
        dispatch(setHasUnsavedCode({ stepId, hasUnsavedCode: false }))
        return
      } else if (triggerDetails?.preProcess?.type === BlockTypes.FUNCTION) {
        dataToUpdate = { ...triggerDetails.preProcess }
      }
      dispatch(setKeyValueInstance(dataToUpdate))
      dispatch(setHasUnsavedCode({ stepId, hasUnsavedCode: false }))
      return
    }
    if (flowJsonBlocks?.status === STEP_OPERATION_STATUS.DRAFTED) {
      errorToast('Nothing to update')
      return
    }
    if (flowJsonBlocks?.type === BlockTypes.VARIABLE || flowJsonBlocks?.type === BlockTypes.IFBLOCK) {
      handleForVariableAndIf()
      return
    }

    dispatch(setFunctionJsonThunkV3({})).then(() => {
      dispatch(
        setKeyValueInstance({
          title: slugName || '',
          hasUnsavedCode: false,
          ...(currentStepType === 'plugin' || currentStepType === 'api' ? { isVerify: true } : {})
        })
      )
    })
  }

  const handleForVariableAndIf = () => {
    const dataToUpdate = {
      type: flowJsonBlocks?.type,
      title: slugName,
      id: stepId
    }
    if (flowJsonBlocks.type === BlockTypes.VARIABLE) {
      dataToUpdate.code = flowJsonBlocks?.data
      dataToUpdate.codeHTML = flowJsonBlocks?.html
    }
    if (flowJsonBlocks.type === BlockTypes.IFBLOCK) {
      dataToUpdate.code = flowJsonBlocks?.condition
      dataToUpdate.codeHTML = flowJsonBlocks?.html
    }
    dispatch(addNewStepToStepData({ dataToUpdate }))
  }

  return (
    <Box>
      {hasUnsavedCode &&
        ((stepId !== 'CRON_PRE_PROCESS' && flowJsonBlocks?.status !== STEP_OPERATION_STATUS.DRAFTED) ||
          (stepId === 'CRON_PRE_PROCESS' && triggerDetails.preProcess)) && (
          <Chip label='Discard changes' size='medium' color='error' onClick={handleClick} />
        )}
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(ResetButtonForSlider), [ParamsEnums.slugName, ParamsEnums.scriptId, ParamsEnums.stepId]))
