import { takeEvery, takeLatest } from 'redux-saga/effects'
import {
  createNewActionVersionSaga,
  getActionVersionSaga,
  // setActionVersionDetailSaga,
  updateActionVersionSaga,
  deleteActionVersionSaga,
  deteleActionVersionSuccessSaga,
  optimzeDhCodeByAiSaga
} from './dhActionVersionFucntions.ts'

export default function* DHActionVersionSaga() {
  yield takeLatest('DHActionVersion/getActionVersionStart', getActionVersionSaga)
  yield takeLatest('DHActionVersion/createNewActionVersionStart', createNewActionVersionSaga)
  yield takeEvery('DHActionVersion/updateActionVersionDetailsStart', updateActionVersionSaga)
  yield takeLatest('DHActionVersion/deleteActionVersionStart', deleteActionVersionSaga)
  yield takeLatest('DHActionVersion/deleteActionVersionSuccess', deteleActionVersionSuccessSaga)
  yield takeLatest('DHActionVersion/optimizeCodeByAiStart', optimzeDhCodeByAiSaga)
}
