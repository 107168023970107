/*eslint-disable*/
import { useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { Box, Button, Typography } from '@mui/material'

function Onboarding() {
  const navigate = useNavigate()
  const { orgId } = useParams()

  const navigateInside = () => {
    window.openChatbot()
    window.askAi('Hi')
    navigate(`/onboarding/${orgId}/socket-ai`)
  }
  return (
    <Box className='h-100 w-100 flex-center-center column gap-5 '>
      <Typography variant='h2' fontWeight={700} className='color-white zindex-20'>
        Welcome to the World of AI Automation!
      </Typography>
      <Typography fontWeight={600} variant='h6' className='color-white zindex-20 subtitle-onboarding'>
        You're now part of something amazing. Let's build workflows that work smarter, faster, and effortlessly.
      </Typography>
      <Button variant='contained' onClick={navigateInside} className='zindex-20 bg-white color-black' endIcon={<KeyboardArrowRightIcon />}>
        Let's go
      </Button>

      {/* Vector Elements */}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(Onboarding), []))
