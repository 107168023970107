import { useEffect } from 'react'
import { useCustomSelector } from './utils/deepCheckSelector'
import { $ReduxCoreType } from './types/reduxCore.ts'
import selectActiveUser from './store/user/userSelector'

function MicrosoftClarity() {
  const { orgId, userData, orgName } = useCustomSelector((state: $ReduxCoreType) => {
    return {
      orgId: state.appInfo?.currentOrgId,
      userData: selectActiveUser(state),
      orgName: state?.orgs?.orgs?.[state.appInfo?.currentOrgId]?.name
    }
  })

  useEffect(() => {
    // Set userId and orgId as custom properties in Microsoft Clarity
    if (window?.clarity && typeof window?.clarity === 'function') {
      window.clarity('set', 'userEmail', userData?.userEmail)
      window.clarity('set', 'orgId', orgId)
      window.clarity('set', 'userId', userData?.userId)
    }

    if (window?.hj) {
      window?.hj('identify', userData?.userId, {
        orgId: orgId,
        userEmail: userData?.userEmail,
        orgName: orgName
      })
    }
  }, [userData?.email, orgId])

  return <div>{/* Microsoft clarity */}</div>
}

export default MicrosoftClarity
