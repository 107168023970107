import { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'

/**
 *
 */
export function ChatBot() {
  /**
   *
   */
  const { isChatBot, modalType } = useCustomSelector((state: $ReduxCoreType) => ({
    isChatBot: state.appInfo.isAiSliderOpen,
    modalType: state.appInfo.modalType
  }))
  // const { orgId } = useParams()

  function showChatBot() {
    if (window.openChatbot) {
      window.openChatbot()
    }
  }

  useEffect(() => {
    if (isChatBot && modalType) {
      window.closeChatbot()
    }
  }, [modalType])

  // this useEffect will check if window has SendDataToChatbot function and then it will send the data to chatbot and open chatbot
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.SendDataToChatbot) {
        clearInterval(intervalId)
        showChatBot()
        window.SendDataToChatbot({
          hideCloseButton: true
        })
      }
    }, 100) // Check every 100ms

    showChatBot()

    return () => {
      clearInterval(intervalId)
      if (typeof window.closeChatbot === 'function' && window.SendDataToChatbot) {
        window.closeChatbot()
        window.SendDataToChatbot({
          hideCloseButton: false
        })
      }
    }
  }, [])

  return null
}
export default ChatBot
