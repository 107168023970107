import cloneDeep from 'lodash.clonedeep'
import { errorToast } from '../../../components/customToast'
import { PluginInputDataType, InputFieldsType, PluginInputJsonType } from '../../../types/Plugin.ts'

export const getParsedPluginFieldsInputData = (parsedCode: PluginInputDataType): PluginInputDataType => {
  parsedCode.selectedValues.inputData = Object.keys(parsedCode.selectedValues.inputData)?.some((key) => key.includes('.'))
    ? parsedCode.selectedValues.inputData
    : Object.keys(parsedCode.selectedValues.inputData)?.some(
        (key) => typeof parsedCode.selectedValues.inputData[key] === 'object' && !Array.isArray(parsedCode.selectedValues.inputData[key])
      )
    ? flattenObject(parsedCode.selectedValues?.inputData || {})
    : parsedCode.selectedValues.inputData
  return parsedCode
}

export function flattenObject(inputObject: any = {}, parentKey = '', result: any = {}) {
  Object.keys(inputObject)?.forEach((key) => {
    if (Object.hasOwnProperty.call(inputObject, key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key
      if (typeof inputObject[key] === 'object' && inputObject[key] !== null) {
        flattenObject(inputObject[key], newKey, result)
      } else {
        result[newKey] = inputObject[key]
      }
    }
  })
  return result
}
export function flattenEveryKeyOfObject(inputObject: any = {}, parentKey = '', result: any = {}) {
  Object.keys(inputObject)?.forEach((key) => {
    if (Object.hasOwnProperty.call(inputObject, key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key
      result[newKey] = inputObject[key]
      if (typeof inputObject[key] === 'object' && inputObject[key] !== null) {
        flattenObject(inputObject[key], newKey, result)
      }
    }
  })
  return result
}

export const flattenToJSON = (flatObject: any = {}, flattenObjectToUpdate: any = {}) => {
  try {
    Object.keys(flatObject).forEach((key) => {
      // eslint-disable-next-line
      if (flatObject.hasOwnProperty(key)) {
        const keys = key?.split('.')
        let currentObject: any = flattenObjectToUpdate
        for (let i = 0; i < keys.length; i++) {
          const currentKey = keys[i]
          if (!currentObject[currentKey]) {
            if (i === keys.length - 1) {
              if (typeof flatObject[key] === 'string') currentObject[currentKey] = flatObject[key]?.trim()
              else currentObject[currentKey] = flatObject[key]
            } else {
              currentObject[currentKey] = {}
            }
          }
          currentObject = currentObject[currentKey]
        }
      }
    })
    return flattenObjectToUpdate
  } catch (error) {
    return {}
  }
}

export const functionAddDependsOnField = (inputFields: InputFieldsType[] = []) => {
  // auther sid
  if (inputFields) {
    try {
      const inputFieldToProcess: InputFieldsType[] = typeof inputFields === 'string' ? JSON.parse(inputFields) : inputFields
      const newInputFields = []
      let previousRequired = ''
      for (let i = 0; i < inputFieldToProcess.length; i++) {
        const newInputFieldToAppend = { ...inputFieldToProcess[i] }
        if (!newInputFieldToAppend.dependsOn) {
          if (previousRequired !== '') newInputFieldToAppend.dependsOn = [previousRequired]
          else newInputFieldToAppend.dependsOn = []
          if (newInputFieldToAppend.required) {
            previousRequired = newInputFieldToAppend.key
          }
        }
        newInputFields.push(newInputFieldToAppend)
      }
      return newInputFields
    } catch (error) {
      const errorMessage = `Error: ${error.message}`
      errorToast(errorMessage)
    }
  }
  return []
}

// export function addDependsOn(inputFields: InputFieldsType[]) {
//   // auther sid
//   if (inputFields) {
//     try {
//       const inputFieldToProcess: InputFieldsType[] = typeof inputFields === 'string' ? JSON.parse(inputFields) : inputFields
//       const newInputFields = []
//       let previousRequired = ''
//       for (let i = 0; i < inputFieldToProcess.length; i++) {
//         const newInputFieldToAppend = { ...inputFieldToProcess[i] }
//         if (!newInputFieldToAppend.dependsOn) {
//           newInputFieldToAppend.dependsOn = []
//         }
//         if (previousRequired && !newInputFieldToAppend?.dependsOn?.includes(previousRequired)) {
//           newInputFieldToAppend.dependsOn.push(previousRequired)
//         }

//         if (newInputFieldToAppend.required) {
//           previousRequired = newInputFieldToAppend.key
//         }
//         newInputFields.push(newInputFieldToAppend)
//       }
//       return newInputFields
//     } catch (error) {
//       const errorMessage = `Error: ${error.message}`
//       errorToast(errorMessage)
//     }
//   }
//   return []
// }

export const pluginInputJsonFromParsedCode = (newObjectToReturn: PluginInputJsonType): PluginInputJsonType => {
  newObjectToReturn.operation.inputFields = functionAddDependsOnField(newObjectToReturn?.operation?.inputFields)
  return newObjectToReturn
}

export function getFlattenLocationOfArrayInObject(object: any) {
  const addressArray: any[] = []
  function processData(data: any, parentKey: string) {
    Object.keys(data || {}).forEach((key) => {
      const finalKey = parentKey === '' ? key : `${parentKey}.${key}`
      if (Array.isArray(data[key])) {
        addressArray.push(finalKey)
        data[key].forEach((item, index) => {
          if (typeof item === 'object' && item !== null) {
            processData(item, `${finalKey}[${index}]`)
          }
        })
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        processData(data[key], finalKey)
      }
    })
  }

  processData(cloneDeep(object), '')
  return addressArray
}
