import { combineReducers } from 'redux'
import projectsReducer from './projects/projectsSlice'
import scriptsReducer from './scripts/scriptsSlice'
import userReducer from './user/userSlice'
import orgsReducer from './orgs/orgsSlice'
import authReducer from './auth/authSlice'
import appInfoReducer from './appInfo/appInfoSlice.ts'
import flowJsonReducer from './flowJson/flowJsonSliceV2.ts'
import invocationReducerV2 from './invocationV2/invocationSliceV2.ts'
import stepsDataV3Reducer from './stepsV2/stepSliceV3.ts'
import allChip from './chip/allChip/allChip.ts'
import dhPluginReducer from './developerHub/DHPlugin/dhPluginSlice.ts'
import dhActionReducer from './developerHub/DHActions/dhActionSlice.ts'
import dhAuthenticationReducer from './developerHub/DHAuthentication/dhAuthenticationSlice.ts'
import allPlugins from './plugin/allPlugins/allPlugins.ts'
import allTriggers from './plugin/allTriggers/allTriggers.ts'
import orgUserReducer from './orgUsers/orgUsersSlice'
import dhActionVersionReducer from './developerHub/DHActionVersion/dhActionVersionSlice.ts'
import InterfaceReducer from './interface/interfaceSlice.ts'
import notificationReducer from './notification/notificationSlice'
import modalReducer from './modal/modalSlice'

const allReducers = combineReducers({
  Interface: InterfaceReducer,
  projects: projectsReducer,
  scripts: scriptsReducer,
  user: userReducer,
  orgs: orgsReducer,
  auth: authReducer,
  invocationV2: invocationReducerV2,
  appInfo: appInfoReducer,
  flowJsonV2: flowJsonReducer,
  stepsDataV3: stepsDataV3Reducer,
  allChip,
  allPlugins,
  DHPlugin: dhPluginReducer,
  DHAction: dhActionReducer,
  DHActionVersion: dhActionVersionReducer,
  DHAuthentication: dhAuthenticationReducer,
  orgUsers: orgUserReducer,
  allTriggers,
  notification: notificationReducer,
  modal: modalReducer
})
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    const { orgs, user } = state
    state = { orgs, user }
  }
  return allReducers(state, action)
}

export default rootReducer
