import { call, put } from 'redux-saga/effects'
import { getAllTriggersDataV2 } from '../../../api'
import { onFetchTriggersFetchRejected, onFetchTriggersFetchSuccess } from './allTriggers.ts'

export function* startFetchTriggersData() {
  try {
    const response = yield call(getAllTriggersDataV2)
    yield put(onFetchTriggersFetchSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(onFetchTriggersFetchRejected({}))
  }
}
