import React, { useEffect, useState } from 'react'
import { Box, FormControl, LinearProgress, MenuItem, Select, Typography } from '@mui/material'
import addUrlDataHoc from '../../../../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../../../../utils/deepCheckSelector'
import { ParamsEnums } from '../../../../../enums'
import { dryRunFunction } from '../../../../../api/index'
import { errorToast } from '../../../../customToast'
import CustomMarkdown from '../../../../Markdown/CustomMarkdown'

/**
 * BasicDropDown component is a dropdown field for authentication purposes.
 * It renders a dropdown menu with options based on the source code provided.
 * It also handles the state change of the selected value and updates the auth data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.field - The field configuration.
 * @param {string} props.field.key - The key of the field.
 * @param {Array} props.field.children - The children of the field.
 * @param {string} props.field.help - The help text for the field.
 * @param {string} props.field.label - The label of the field.
 * @param {string} props.field.source - The source code for generating options.
 * @param {string} props.sectionIdOrScriptId - The section or script ID.
 * @param {string} props.versionIdOrStepId - The version or step ID.
 * @param {string} props.tabName - The tab name.
 */

function BasicDropDown(props) {
  const {
    field: { key, children, help, label, source, placeholder, required },
    sectionIdOrScriptId,
    versionIdOrStepId,
    tabName
  } = props
  const authData = useCustomSelector(
    (state) => state.stepsDataV3?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName]?.pluginData?.selectedValues?.authData
  )
  // const dynamicChildren = children // always static
  const selectedValues = authData ? authData[key] : ''
  const [value, setValue] = useState(selectedValues || '')
  const dependency = authData ? authData[key] && '' : null
  const [dynamicChildren, setDynamicChildren] = useState(children || [])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValue(authData ? authData[key] || '' : '')
  }, [dependency])

  useEffect(() => {
    getChildrenFromSource()
  }, [key])

  const getChildrenFromSource = async () => {
    setLoading(true)
    const datatosend = {
      type: 'function',
      code: source,
      name: 'authSource',
      variables: { context: selectedValues || {} }
    }
    const response = await dryRunFunction({
      scriptId: 'devhubPluginPreview',
      data: datatosend
    })
    if (!Array.isArray(response?.data?.data?.message)) {
      errorToast(`Source code is invalid. Key - ${key}`)
      return
    }
    setDynamicChildren(response?.data?.data?.message)
    setLoading(false)
  }

  const setOneFieldData = (key, value) => {
    // callback function to set auth data.
    const prevAuth = { ...authData }
    prevAuth[key] = value
  }
  const handleFieldChangeDropdown = async (event) => {
    props?.setAuthData((prevState) => ({ ...prevState, [key]: event.target.value }))
    const {
      target: { value }
    } = event
    setValue(value)
    setOneFieldData(key, value)
  }

  return (
    <Box className='w-100 mb-1'>
      <FormControl className='dropDownFormControl mt-2 w-100 flex-col gap-1'>
        <Typography> {required ? `${label}*` : label}</Typography>
        {loading && <LinearProgress />}
        <Select className='w-100' size='small' value={value} displayEmpty onChange={handleFieldChangeDropdown}>
          <MenuItem selected disabled value=''>
            <Typography className='opacity-half'>{placeholder} </Typography>
          </MenuItem>
          {dynamicChildren &&
            typeof dynamicChildren === 'object' &&
            dynamicChildren?.map((option) => (
              <MenuItem key={option} value={option?.value}>
                {option?.sample ? `${option?.label} (${option?.sample})` : option?.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {help && <CustomMarkdown help={help} />}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(BasicDropDown), [ParamsEnums.tabName, ParamsEnums.versionIdOrStepId, ParamsEnums.sectionIdOrScriptId])
)
