import { FC } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { ParamsEnums, Tabnames } from '../enums'

export default function addUrlDataHoc(WrappedComponent: FC<any>, paramsToInject?: string[]) {
  return function addUrlDataHoc(props: any) {
    const urlParams = useParams()
    const location = useLocation()
    const setSearchParams = useSearchParams()[1]
    const data: { [key: string]: string | boolean | undefined } = {}
    const queryParams = new URLSearchParams(window.location.search)

    function returnversionIdOrStepId() {
      let versionIdOrStepIdTemp = urlParams['stepId'] || urlParams['versionId'] || ''
      if (!versionIdOrStepIdTemp && (queryParams.has('stepId') || queryParams.has('versionId'))) {
        versionIdOrStepIdTemp = queryParams.get('stepId') || queryParams.get('versionId') || ''
      }
      return versionIdOrStepIdTemp
    }
    function returnSectionIdOrScriptId() {
      let sectionIdOrScriptId = urlParams['sectionId'] || urlParams['scriptId'] || ''
      if (!sectionIdOrScriptId && (queryParams.has('sectionId') || queryParams.has('scriptId'))) {
        sectionIdOrScriptId = queryParams.get('sectionId') || queryParams.get('scriptId') || ''
      }
      return sectionIdOrScriptId
    }
    const keyLogicMap: { [key: string]: () => void } = {
      [ParamsEnums.isPublishedTab]: () => {
        data[ParamsEnums.isPublishedTab] = urlParams[ParamsEnums.tabName] === Tabnames.PUBLISH
      },
      versionIdOrStepId: () => {
        data['versionIdOrStepId'] = returnversionIdOrStepId()
      },
      sectionIdOrScriptId: () => {
        data['sectionIdOrScriptId'] = returnSectionIdOrScriptId()
      },
      [ParamsEnums.isTemplate]: () => {
        data[ParamsEnums.isTemplate] = location.pathname.includes('template')
      },
      [ParamsEnums.embedding]: () => {
        data[ParamsEnums.embedding] = location.pathname.includes(ParamsEnums.embedding)
      },
      [ParamsEnums.isLogs]: () => {
        data[ParamsEnums.isLogs] = location.pathname.includes(Tabnames.LOG)
      },
      [ParamsEnums.isConfiguration]: () => {
        data[ParamsEnums.isConfiguration] = location.pathname.includes(Tabnames.CONFIGURATION)
      },
      [ParamsEnums.isSetup]: () => {
        data[ParamsEnums.isSetup] = location.pathname.includes(Tabnames.SETUP)
      },
      [ParamsEnums.search]: () => {
        data[ParamsEnums.search] = location.pathname.includes(ParamsEnums.search)
      },
      isEmbedUrl: () => {
        data['isEmbedUrl'] = window.location.pathname.startsWith('/integrations/embed')
      },
      triggerStepId: () => {
        data['triggerStepId'] = urlParams['scriptId']?.replace('scri', 'func')
      },
      [ParamsEnums.threadId]: () => {
        let threadIdToSet = ''
        const stepId = returnversionIdOrStepId()
        const scriptId = returnSectionIdOrScriptId()
        const orgId = urlParams['orgId'] || ''
        if (stepId) threadIdToSet = `${scriptId}${stepId}`
        else if (scriptId) threadIdToSet = scriptId
        else threadIdToSet = orgId
        data[ParamsEnums.threadId] = threadIdToSet
      }
    }

    paramsToInject?.forEach((key: string) => {
      if (keyLogicMap[key]) {
        keyLogicMap[key]()
      } else {
        data[key] = urlParams[key] || ''
        if (!urlParams[key] && queryParams.has(key)) {
          data[key] = queryParams.get(key) || ''
        }
      }
    })

    if (!data.tabName) data.tabName = 'draft'
    return <WrappedComponent {...props} {...data} setSearchParams={setSearchParams} />
  }
}
