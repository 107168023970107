import { SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import cloneDeep from 'lodash.clonedeep'
import { DH_SLIDERS } from '../../../pages/developerHub/constants/developerHubConstants.ts'
import { ActionVersionDataType, ActionVersionFields } from '../../../types/DHAction.ts'
import { $DHActionVersionReduxType } from '../../../types/reduxCore.ts'
import actionType from '../../../types/utility.ts'
import { checkForDraftMark } from '../DHAuthentication/dhAuthenticationReducer.ts'

const sampleActionVersionData = {
  isLoading: false,
  rowid: '',
  key: '',
  autonumber: '',
  createdat: '',
  createdby: '',
  updatedat: '',
  updatedby: '',
  actionid: '',
  name: '',
  scopes: '',
  versionid: '',
  code: {},
  accesslevel: 'Private',
  type: 'action',
  authid: '',
  authversion: '',
  authtype: 'Basic'
}
export const initialState: $DHActionVersionReduxType = {}

const draftMarkInitialState = {
  performsubscribe: false,
  performlist: false,
  performunsubscribe: false,
  perform: false,
  transferoption: false,
  modifytriggerdata: false
}

export const reducers: ValidateSliceCaseReducers<$DHActionVersionReduxType, SliceCaseReducers<$DHActionVersionReduxType>> = {
  // GET Action Versions
  getActionVersionStart(state, action: actionType<{ sectionId: string }>) {
    const { sectionId: actionId } = action.urlData
    if (!state[actionId]) {
      state[actionId] = {}
    }
  },
  getActionVersionSuccess(state, action: actionType<ActionVersionFields[]>) {
    const actionId = action.payload?.[0]?.actionid
    const tempVersionData: ActionVersionDataType = {}
    action.payload?.forEach((actionVersionDetails: ActionVersionFields) => {
      tempVersionData[actionVersionDetails.rowid] = { ...actionVersionDetails, draftMark: draftMarkInitialState }
    })
    // Update the state
    state[actionId] = tempVersionData
    // state.isLoading = false
  },
  getActionVersionError() {
    // state.isLoading = false
  },
  // Create New Action Version
  createNewActionVersionStart(
    state,
    action: actionType<{
      payload: ActionVersionFields
      navigate: any
      pluginId: string
      orgId: string
    }>
  ) {
    const sectionId: string = action?.payload?.payload?.actionid
    if (!state[sectionId]) {
      state[sectionId] = {}
    }
    state[sectionId]['SAMPLE_ACTION-VERSION_ID'] = { ...sampleActionVersionData, isLoading: true }
    // state.isLoading = true
  },
  createNewActionVersionSuccess(state, action: actionType<ActionVersionFields>) {
    const data = { ...action.payload }
    const actionId = data?.actionid
    // const tempVersionData: ActionVersionDataType = {}

    // if (data?.code && JSON.parse(data?.code)) {
    //   tempVersionData.code = JSON.parse(data.code) || {}
    // }
    state[actionId] = { ...state?.[actionId], [data?.rowid]: { ...data, isLoading: false, draftMark: draftMarkInitialState } }
    delete state[actionId]['SAMPLE_ACTION-VERSION_ID']
    // state.isLoading = false
  },
  createNewActionVersionError(state, action) {
    const { sectionId } = action.urlData
    delete state[sectionId]['SAMPLE_ACTION-VERSION_ID']
    // state.isLoading = false
  },

  updateActionVersionDetailsStart(state, action: actionType<ActionVersionFields>) {
    const sectionId = action.payload.sectionId || action.urlData.sectionId
    const versionId = action.payload.versionId || action.urlData.versionId
    const objToUpdate = action.payload.data || action.payload
    // const { sectionId, versionId } = action.urlData
    const draftMark = state?.[sectionId]?.[versionId]?.draftMark
    Object.keys(objToUpdate).forEach((key) => {
      if ([...DH_SLIDERS, 'inputjson']?.includes(key)) {
        draftMark[key] = false
      }
    })
    state[sectionId][versionId] = {
      ...state[sectionId][versionId],
      ...objToUpdate,
      draftMark,
      isLoading: true
    }
    // const keysToMarkDraft = Object.keys(action?.payload).filter((key) => OBJECTS_TO_PARSE?.actionKeys?.includes(key))
    // state.draftMark = {
    //   ...state.draftMark,
    //   ...keysToMarkDraft?.reduce((acc, item) => {
    //     acc[item] = false
    //     return acc
    //   }, {})
    // }
  },

  updateActionVersionDetailsSuccess(state, action: actionType<{ sectionId: string; versionId: string; data: ActionVersionFields }>) {
    const { sectionId, versionId } = action.payload
    // const data = action.payload?.data
    // const tempVersionData: ActionVersionDataType = {}
    // if (data?.code && JSON.parse(data?.code)) {
    //   tempVersionData.code = JSON.parse(data.code) || {}
    // }
    state[sectionId][versionId] = { ...state[sectionId][versionId], isLoading: false }
  },
  updateActionVersionDetailsError(state, action) {
    const { sectionId, versionId } = action.payload
    state[sectionId][versionId].isLoading = false
    state[sectionId][versionId].isAiOptimizing = false
  },
  updateActionVersionCodeInRedux(state, action: actionType<ActionVersionFields>) {
    const sectionId = action.urlData?.sectionId
    const versionId = action?.urlData?.versionId
    if (sectionId && versionId) {
      const key = state?.[sectionId]?.[versionId]?.key
      const code = state?.[sectionId]?.[versionId]?.code
      if (key) {
        const { payload } = action
        // Create a new 'operation' object with merged properties
        const updatedOperation = {
          ...(code?.creates?.[key]?.operation || {}), // Existing 'operation' or an empty object
          ...payload // Update with payload properties ,]
        }
        // Create the updated 'creates' object
        const updatedCreates = {
          [key]: {
            key: key,
            operation: updatedOperation // Updated 'operation'
          }
        }
        // Create the updated 'code' object
        const updatedCode = {
          ...(code || null), // Existing 'code' or null if it doesn't exist
          creates: updatedCreates // Updated 'creates'
        }
        // Update the state
        state[sectionId][versionId] = { ...state[sectionId][versionId], code: updatedCode }
      }
    }
  },
  deleteActionVersionStart(state, action: actionType<{ data: { rowid: string }; navigate: () => void }>) {
    const { sectionId } = action.urlData
    state[sectionId][action.payload.data.rowid].isLoading = true
  },
  deleteActionVersionSuccess(state, action: actionType<{ rowid: string }>) {
    const { sectionId } = action.urlData
    delete state[sectionId][action?.payload?.data.rowid]
  },
  deleteActionVersionError(state, action: actionType<{ rowid: string }>) {
    const { sectionId } = action.urlData
    state[sectionId][action.payload.rowid].isLoading = false
  },

  updateActionVersionDetailsInRedux(state, action: actionType<ActionVersionFields>) {
    const sectionId = action.payload.sectionId || action.urlData.sectionId
    const versionId = action.payload.versionId || action.urlData.versionId
    const objToUpdate = action.payload.data || action.payload

    // Ensure sectionId and versionId exist in state
    if (!state[sectionId]) {
      state[sectionId] = {}
    }
    if (!state[sectionId][versionId]) {
      state[sectionId][versionId] = {}
    }
    state[sectionId][versionId] = {
      ...state[sectionId][versionId],
      ...objToUpdate,
      draftMark: {
        ...state[sectionId][versionId]?.draftMark,
        ...checkForDraftMark(objToUpdate, [...DH_SLIDERS, 'inputjson'], state[sectionId][versionId])
      }
    }
  },

  // const keysToMarkDraft = Object.keys(action?.payload).filter((key) => OBJECTS_TO_PARSE?.actionKeys?.includes(key))
  // state.draftMark = {
  //   ...state.draftMark,
  //   ...keysToMarkDraft?.reduce((acc, item) => {
  //     acc[item] = true
  //     return acc
  //   }, {})
  // }
  // },

  updateResultOfSlider(state, action: actionType<ActionVersionFields>) {
    const { sectionId, versionId } = action.payload
    state[sectionId][versionId].result = { ...state?.[sectionId]?.[versionId]?.result, ...action.payload?.data }
  },

  updateActionVersionInputFields(state, action: actionType<{ actionId: string; versionId: string; data: [] }>) {
    const { data, actionId, versionId } = action.payload
    const dataToCheckForDraftMark = {
      inputjson: {
        ...cloneDeep(state[actionId][versionId]?.inputjson),
        inputFields: data
      }
    }
    if (actionId && versionId) {
      if (!state[actionId][versionId]) {
        state[actionId] = { [versionId]: sampleActionVersionData }
      }
      if (state[actionId][versionId]) {
        state[actionId][versionId] = {
          ...state?.[actionId][versionId],
          inputjson: {
            ...state[actionId][versionId]?.inputjson,
            inputFields: data
          },
          draftMark: {
            ...state?.[actionId]?.[versionId]?.draftMark,
            ...checkForDraftMark(dataToCheckForDraftMark, ['inputjson'], state?.[actionId]?.[versionId])
          }
        }
      }
    }
  },

  getParticularSliderCodeActionVersionSuccess(state, action) {
    const { sectionId, versionId, slugName, data } = action.payload
    state[sectionId][versionId] = {
      ...state?.[sectionId]?.[versionId],
      ...data,
      draftMark: {
        ...state?.[sectionId]?.[versionId]?.draftMark,
        [slugName]: false
      }
    }
  },
  optimizeCodeByAiStart(state, action: actionType<{ code: string }>) {
    const { sectionId, versionId } = action.urlData
    const { apiEnum } = action.payload
    state[sectionId][versionId].isAiOptimizing = {
      ...state[sectionId][versionId].isAiOptimizing,
      [apiEnum]: true
    }
  },
  optimizeCodeByAiSuccess(
    state,
    action: actionType<{
      sectionId: string
      versionId: string
      apiEnum: 'perform' | 'performsubscribe' | 'performunsubscribe'
      optimizedcode: string
    }>
  ) {
    const { sectionId, versionId, apiEnum } = action.payload
    state[sectionId][versionId].isAiOptimizing = {
      ...state[sectionId][versionId].isAiOptimizing,
      [apiEnum]: false
    }
  },
  optimizeCodeByAiError(state, action: actionType<{ sectionId: string; versionId: string }>) {
    const { sectionId, versionId, apiEnum } = action.payload
    state[sectionId][versionId].isAiOptimizing = {
      ...state[sectionId][versionId].isAiOptimizing,
      [apiEnum]: false
    }
  }
}
