import { useDispatch } from 'react-redux'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, THUNK_CALL_STATUS } from '../../enums'
import { saveScriptTemplate } from '../../store/scripts/scriptsThunk'

function EmbedWrapper({ orgId, projectId }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  window.addEventListener('message', (e) => {
    if (e.data?.type === 'script_id' && orgId && projectId) {
      if (e.data?.scriptId && e.data?.flowHitId)
        navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e.data?.scriptId}/logs?flowhitid=${e.data?.flowHitId}`)
      else if (e.data?.scriptId) navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e.data?.scriptId}/draft`)
      else if (e.data?.templateId) {
        dispatch(saveScriptTemplate({ projectId, templateId: e.data?.templateId })).then((e) => {
          if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED && e?.payload?.id)
            navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e?.payload?.id}/draft`)
        })
      } else navigate(`/integrations/embed/${orgId}/${projectId}`)
    }
  })
  return <Outlet />
}
export default React.memo(addUrlDataHoc(React.memo(EmbedWrapper), [ParamsEnums.orgId, ParamsEnums.projectId]))
