export default function OthersIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 140 140' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M128.263 125.03H119.729V117.835H128.263C129.792 117.835 131 116.534 131 114.887C131 113.24 129.792 111.94 128.263 111.94H119.729V110.899C119.729 109.252 118.522 107.952 116.992 107.952C110.149 107.952 105.238 111.246 103.467 116.708C103.226 116.621 102.984 116.534 102.662 116.534H19.1776V15.8831H120.051V85.2379C120.051 87.9254 122.144 90.1794 124.64 90.1794C127.136 90.1794 129.229 88.0121 129.229 85.2379V10.9415C129.229 8.25403 127.136 6 124.64 6H14.5888C12.0931 6 10 8.16734 10 10.9415V121.476C10 124.163 12.0126 126.417 14.5888 126.417H102.662C102.904 126.417 103.145 126.331 103.387 126.244C105.399 132.573 111.357 135 116.992 135C118.522 135 119.729 133.7 119.729 132.052V131.012H128.263C129.792 131.012 131 129.712 131 128.065C131 126.417 129.792 125.03 128.263 125.03Z'
        fill='black'
      />
      <path
        d='M67.3397 105.001C66.9835 105.624 66.3258 106 65.6079 106H64.6305C63.4134 106 62.4785 104.922 62.6506 103.717L66.4429 77.1717C66.615 75.9669 65.68 74.8889 64.463 74.8889H51.2134C47.3023 74.8889 49.7467 71.5556 49.8356 71.4222C55.4727 61.4591 63.9023 46.6841 75.1246 27.0126C75.4805 26.3888 76.1457 26 76.8639 26H77.8407C79.0578 26 79.9928 27.078 79.8206 28.2828L76.0284 54.8283C75.8563 56.0331 76.7912 57.1111 78.0083 57.1111H91.3023C93.0801 57.1111 94.0579 57.9556 93.0801 60.0444C78.1119 86.1535 69.5409 101.148 67.3397 105.001Z'
        fill='black'
      />
    </svg>
  )
}
