import { Box } from '@mui/material'

export default function IconWrapper({ component = null, size, iconUrl = 'https://cdn-icons-png.flaticon.com/512/380/380127.png' }) {
  component = component || <img src={iconUrl} alt='' className='w-100 ' />
  return (
    <Box>
      <Box className='icon-wrapper-box flex-center-center ' sx={{ height: size, width: size, padding: '8%' }}>
        {component}
      </Box>
    </Box>
  )
}
