import cloneDeep from 'lodash.clonedeep'

export const filterObjectByKey = (state, key, scriptId) => {
  if (!key) return state.invocationV2?.[scriptId]?.context

  const stepOrder = state.flowJsonV2[scriptId]?.stepOrder || []
  const keyIndex = stepOrder.indexOf(key)

  if (keyIndex === -1) return state.invocationV2?.[scriptId]?.context

  const contextObj = cloneDeep(state.invocationV2?.[scriptId]?.context)
  const keysToKeep = stepOrder.slice(0, keyIndex)

  function filterContextData(dataKey) {
    const originalData = contextObj?.context?.[dataKey] || {}
    const filteredData = {}

    keysToKeep.forEach((stepKey) => {
      if (stepKey in originalData) {
        filteredData[stepKey] = originalData[stepKey]
      }
    })

    contextObj.context[dataKey] = filteredData
  }

  filterContextData('res')
  filterContextData('vals')

  return contextObj
}
