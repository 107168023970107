import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { toggleLogsSlider, updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { MiscTypes, ParamsEnums } from '../../enums'
import { closeSuggestionBox } from '../../store/chip/allChip/allChip.ts'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'

function ProjectAndOrgUrlParams(props) {
  const { projectId, orgId, tabName } = props
  const dispatch = useDispatch()
  const mode = useCustomSelector((state) => state.appInfo?.mode)

  useEffect(() => {
    dispatch(
      updateAppInfoState({
        currentProjectId: projectId || '',
        currentOrgId: orgId || '',
        currentScriptId: '',
        currentSlugName: '',
        currentStepId: '',
        isLogSliderOpen: false,
        isProjectSliderOpen: true,
        isAllPluginSliderOpen: false,
        isDynamicSectionOpen: false,
        isTemplateSliderOpen: false,
        isAiSliderOpen: false,
        isScriptDrawerOpen: !!props?.scriptId,
        localConfiguration: false
      })
    )
  }, [projectId, orgId])

  useEffect(() => {
    if (tabName === 'logs') dispatch(toggleLogsSlider(true))
  }, [tabName])

  useEffect(() => {
    dispatch(closeSuggestionBox())
  }, [])

  useEffect(() => {
    let local_mode = mode
    if (sessionStorage.getItem('client_id')) local_mode = MiscTypes.OAUTH_MODE // Login with OAuth.
    else if (sessionStorage.getItem('ssoEmbedToken') && window.parent === window && mode !== MiscTypes.EMBED_MODE)
      local_mode = MiscTypes.SSO_MODE
    dispatch(updateAppInfoState({ mode: local_mode }))
  }, [])

  return null
}

export default React.memo(
  addUrlDataHoc(React.memo(ProjectAndOrgUrlParams), [ParamsEnums.projectId, ParamsEnums.orgId, ParamsEnums.tabName, ParamsEnums.scriptId])
)
