import { Box, Typography } from '@mui/material'

function EmbedError() {
  return (
    <Box className='h-100vh w-100vw flex-col-center-center gap-2'>
      <Typography variant='h6' align='center'>
        Your session has expired.
      </Typography>
      <Typography align='center'>
        Please refresh the page. If the issue persists, kindly contact our support team at{' '}
        <a href='mailto:support@viasocket.com'>support@viasocket.com</a>.
      </Typography>
    </Box>
  )
}

export default EmbedError
