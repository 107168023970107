import React from 'react'
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined'
import { Box, Card, CardContent, DialogTitle, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { cardData } from '../../utils/utilities'

// eslint-disable-next-line
const SupportDialog: React.FC<any> = () => {
  return (
    <Box className='flex-col gap-4 p-0'>
      <DialogTitle id='alert-dialog-title' className='text-center p-0'>
        <Typography variant='h5' fontWeight='bold'>
          Our help options
        </Typography>
        <Typography fontWeight='bold'>Explore our resources and get the help you need:</Typography>
      </DialogTitle>
      <Box className='help-options-dialog flex-center-column-gap' sx={{ width: '650px' }}>
        {cardData.map((cardDetails: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Link key={index} to={cardDetails.url} target='_blank' className='no-textdecoration w-100'>
            <Card className='card-data'>
              <CardContent className='flex-spaceBetween-center'>
                <Typography component='div' className='flex-verticalcenter gap-4'>
                  <img src={cardDetails.imgSrc} alt={cardDetails.imgAlt} style={{ width: cardDetails.imgWidth || '60px' }} />
                  <Typography component='div'>
                    <Typography variant='h6' fontWeight='bold' sx={{ fontSize: cardDetails.textSize }}>
                      {cardDetails.title}
                    </Typography>
                    <Typography className='font-12'>{cardDetails.description}</Typography>
                  </Typography>
                </Typography>
                <ArrowOutwardOutlinedIcon />
              </CardContent>
            </Card>
          </Link>
        ))}
      </Box>
    </Box>
  )
}

export default SupportDialog
