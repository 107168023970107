import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit'

// // reducer imports
import { initialState, reducers } from './invocationReducerV2.ts'
import { $InvocationReduxTypeStructure } from '../../types/reduxCore.ts'

const invocationSliceV2 = createSlice<$InvocationReduxTypeStructure, SliceCaseReducers<$InvocationReduxTypeStructure>, 'invocationV2'>({
  name: 'invocationV2',
  initialState,
  reducers
})

export const {
  addInvocationInitialState,
  setInvocationDataV2,
  handleAddStepsV2,
  handleDeleteStepsV2,
  setInvocationAuthData,
  removeKeysFromAuthData,
  setInputDataOfJson,
  removeDhInvocation,
  removeInvocationDataV2,
  updatedUsedVariables,
  removeInvocationForDH,
  addPaginateDataKeyInContext,
  removePaginateDataKeyInContext,
  addInputFieldsJsonSuggestion,
  removeInputFieldsJsonSuggestion,
  setShowUsedVariables,
  updateTriggerDataInInvocation,
  setWhenResponseSnapshotJsonDataInInvocationJson,
  removeKeysFromInputDataJson
} = invocationSliceV2.actions

export default invocationSliceV2.reducer
