import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

import { Box } from '@mui/material'
import '../../App.css'
import { useRef } from 'react'

const StyledInputBase = styled(InputBase)(({ color, disabled }) => ({
  color: color || 'inherit',
  '& .MuiInputBase-input': {
    fontSize: '12px',
    padding: '4px'
  },
  cursor: disabled ? 'not-allowed' : 'text', // Change cursor style when disabled
  pointerEvents: disabled ? 'none' : 'auto'
}))

/**
 * SearchBar component for searching functionality.
 *
 * @param {Function} handleSearchBar - Function to handle search bar input change.
 * @param {boolean} darkbg - Flag to determine if the search bar has a dark background.
 * @param {string} height - Height of the search bar.
 * @param {boolean} autoFocus - Flag to determine if the search bar should be autofocused.
 * @param {Object} parentRef - Reference to the parent element.
 * @param {string} placeholder - Placeholder text for the search bar.
 * @param {string} value - Value of the search bar input.
 * @param {Function} onFocus - Function to handle search bar focus event.
 * @param {Function} onKeyDown - Function to handle key down event on the search bar.
 */

function SearchBar({
  handleSearchBar = () => {},
  darkbg = false,
  height = '40px',
  autoFocus = true,
  parentRef = { current: null },
  placeholder = 'Search…',
  value = undefined,
  disabled = false,
  onFocus = () => {},
  onKeyDown = () => {}
}) {
  const searchbarRef = useRef()
  const sentValueFromSearchbar = (e) => {
    handleSearchBar(e.target.value)
  }
  return (
    <Box
      id='search-bar-boundary'
      onChange={sentValueFromSearchbar}
      className=' flex-start-center searchbar gap-2 w-100 p-1'
      sx={{ height }}
    >
      <SearchIcon fontSize='small' color={darkbg ? 'white' : 'gray'} />
      <StyledInputBase
        className='searchbar__input'
        defaultValue=''
        disabled={disabled}
        inputRef={searchbarRef}
        autoFocus={autoFocus}
        onKeyDown={(e) => {
          e.stopPropagation()
          onKeyDown(e)
          if (e.key === 'Enter') {
            handleSearchBar(e.target.value)
          }
        }}
        color={darkbg ? '#fff' : ''}
        onBlur={() => {
          parentRef?.current?.classList?.remove('large-width')
          parentRef?.current?.classList?.add('normal-width')
        }}
        onFocus={() => {
          parentRef?.current?.classList?.add('large-width')
          parentRef?.current?.classList?.remove('normal-width')
          onFocus()
        }}
        id='search'
        placeholder={placeholder}
        inputProps={{ 'open-sans': 'search' }}
        value={value}
      />
    </Box>
  )
}

export default SearchBar
