import React from 'react'
import Markdown from 'react-markdown'

const LinkRenderer = React.memo(({ href, children }) => (
  <a href={href} target='_blank' rel='noreferrer'>
    {children}
  </a>
))

LinkRenderer.displayName = 'LinkRenderer'

function CustomMarkdown({ remarkPlugins = [], components, help, children, ...rest }) {
  const defaultComponents = { a: LinkRenderer }

  return (
    <Markdown
      remarkPlugins={remarkPlugins} // Use destructured props
      components={{ ...defaultComponents, ...components }}
      {...rest} // Spread the remaining props
    >
      {help || children}
    </Markdown>
  )
}

export default CustomMarkdown
