import { createRoot } from 'react-dom/client'
import { Alert, AlertTitle, IconButton, Box, Slide } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

class ToastManager {
  constructor() {
    this.toasts = []
    this.container = document.createElement('div')
    document.body.appendChild(this.container)
    this.root = createRoot(this.container)
    this.update = this.update.bind(this)
    this.addToast = this.addToast.bind(this)
    this.removeToast = this.removeToast.bind(this)
  }

  addToast(toast) {
    this.toasts = [...this.toasts, { ...toast, open: true }]
    this.update()
    setTimeout(() => {
      this.removeToast(toast.id)
    }, toast.duration || 3000)
  }

  removeToast(id) {
    // Set open to false to trigger exit animation
    this.toasts = this.toasts.map((toast) => (toast.id === id ? { ...toast, open: false } : toast))
    this.update()
    // Remove the toast after animation duration
    setTimeout(() => {
      this.toasts = this.toasts.filter((t) => t.id !== id)
      this.update()
    }, 300) // Adjust based on animation duration
  }

  update() {
    this.root.render(<ToastContainer toasts={this.toasts} removeToast={this.removeToast} />)
  }
}

const toastManager = new ToastManager()

function ToastContainer({ toasts, removeToast }) {
  return (
    <Box
      className='column gap-1 pos-fix'
      sx={{
        bottom: 16,
        left: 16,
        zIndex: 9999
      }}
    >
      {toasts.map((toast) => (
        <Slide key={toast.id} direction='right' in={toast.open} mountOnEnter unmountOnExit timeout={{ enter: 300, exit: 100 }}>
          <Alert
            variant='filled'
            severity={toast.severity}
            action={
              <IconButton color='inherit' size='small' onClick={() => removeToast(toast.id)}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            sx={{ mb: 1, minWidth: '300px' }}
          >
            <AlertTitle>{toast.severity}</AlertTitle>
            {toast.message}
          </Alert>
        </Slide>
      ))}
    </Box>
  )
}

export function showSnackbar(alert) {
  const id = new Date().getTime() + Math.random()
  const toast = { ...alert, id }
  toastManager.addToast(toast)
}

export function successToast(message) {
  showSnackbar({ severity: 'success', message })
}

export function errorToast(message) {
  showSnackbar({ severity: 'error', message })
}

export function warningToast(message) {
  showSnackbar({ severity: 'warning', message })
}

export function infoToast(message) {
  showSnackbar({ severity: 'info', message })
}
