import axios from 'axios'

export const sendAlert = async (payload: any, from: string, title: string = '') => {
  try {
    if (process.env.REACT_APP_API_ENVIRONMENT === 'local') return
    const ALERT_API = 'https://flow.sokt.io/func/scriZ0jU8rFA'
    if (!ALERT_API) throw new Error('ALERT_API is required')
    await axios.post(ALERT_API, {
      payload,
      from,
      title: `${process.env.REACT_APP_API_ENVIRONMENT} | ${title}`
    })
  } catch (error: any) {
    console.error('ERROR IN sendAlert', error.message)
  }
}
