import { takeLatest } from 'redux-saga/effects'
import { startFetchPluginData } from './allPlugins/fetchPluginSaga.ts'
import { getAuthDetails, verifyAuth } from './currentSelectedPlugin/fetchPluginDataByIdSaga.ts'
import { startFetchTriggersData } from './allTriggers/fetchPluginSaga.ts'

export default function* PluginSaga() {
  yield takeLatest('allPlugins/fetchPluginData', startFetchPluginData)
  yield takeLatest('stepsDataV3/startVerifyAuth', verifyAuth)
  yield takeLatest('allTriggers/fetchTriggersData', startFetchTriggersData)
  yield takeLatest('stepsDataV3/setAuthFieldsStart', getAuthDetails)
}
