import { createSlice } from '@reduxjs/toolkit'
import { initialState, reducers } from './dhActionReducer.ts'

const developerHubActionsSlice = createSlice({
  name: 'DHAction',
  initialState,
  reducers
})

export const {
  getAllActionStart,
  getAllActionSuccess,
  getAllActionError,

  createNewActionSuccess,
  createNewActionStart,
  createNewActionError,

  getActionDetailStart,
  getActionDetailSuccess,
  getActionDetailError,

  updateActionDetailsStart,
  updateActionDetailsSuccess,
  updateActionDetailsError,

  setActionStateToIntial,
  deleteActionStart,
  deleteActionSuccess,
  deleteActionError,

  // bulk create actions
  bulkActionCreateSuccess,

  // create custom action by ai
  createCustomActionByAiSuccess,
  createCustomActionByAiError
} = developerHubActionsSlice.actions
export default developerHubActionsSlice.reducer
