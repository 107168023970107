import { takeEvery, takeLatest } from 'redux-saga/effects'
import {
  getAuthDetailSaga,
  createAuthDetailSaga,
  updateAuthDetailSaga,
  // isAuthPublishedSaga,
  // deleteAuthSaga,
  getActionIdAndAuthIdCountSaga
} from './dhAuthenticationFunctions.ts'

export default function* developerHubAuthenticaitonSaga() {
  yield takeEvery('DHAuthentication/getAuthDetailStart', getAuthDetailSaga)
  yield takeLatest('DHAuthentication/getActionIdAndAuthIdCountStart', getActionIdAndAuthIdCountSaga)
  yield takeLatest('DHAuthentication/createAuthDetailsStart', createAuthDetailSaga)
  yield takeLatest('DHAuthentication/updateAuthDetailStart', updateAuthDetailSaga)
  // yield takeEvery('DHAuthentication/setAuthDetailStart', isAuthPublishedSaga)
  // yield takeEvery('DHAuthentication/deleteAuthStart', deleteAuthSaga)
}
