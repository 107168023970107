import { Box, Card } from '@mui/material'
import React from 'react'
import { ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
/**
 * ShowMoreButton component for displaying a button to show more integrations.
 *
 * @param {Object} props - The component props
 * @param {Array} [props.leftCombinations=[]] - Array of remaining combinations to be shown
 * @param {Function} props.setShowMore - Function to handle showing more integrations
 * @returns {React.ReactElement|null} The ShowMoreButton component or null if no combinations are left
 */

function ShowMoreButton({ leftCombinations = [], setShowMore }) {
  return leftCombinations?.length ? (
    <Box className='grid-item p-2'>
      <Card className='service-block' onClick={setShowMore}>
        See More Integrations
      </Card>
    </Box>
  ) : null
}
export default React.memo(addUrlDataHoc(React.memo(ShowMoreButton), [ParamsEnums.projectId, ParamsEnums.orgId, ParamsEnums.eventId]))
