export const selectActiveProjects = (allProjects, id) => {
  if (!allProjects) return []

  const entries = Object.entries(allProjects)

  const arr = []

  entries.forEach((ele) => {
    if (ele[1].org_id === id) {
      arr.push(ele[1])
    }
  })

  return arr
}

export const getProjectById = (state, id, orgId, type = 'flow') => {
  if (!id) return {}
  const projects = type === 'embed' ? state.projects?.embedProject : state.projects?.projects
  const obj = projects[orgId]?.active?.[id] || projects[orgId]?.deleted?.[id]
  if (!obj) return {}
  return obj
}

export const getProjectId = (state, id) => {
  const { scripts: obj } = state.scripts
  if (!obj) return null
  const currentScript = obj[id]
  if (!currentScript) return null
  return currentScript?.project_id
}

export const getEmbedProjectListInArray = (payload) => {
  if (Object.keys(payload).length === 0) return []
  const keysArray = Object.keys(payload)
  const embedProjectInArray = keysArray.map((key) => payload[key])
  return embedProjectInArray
}
