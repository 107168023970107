/**
 * Renders an SVG icon representing an edit pencil.
 *
 * This component allows customization of the icon size through the `width` and `height` props,
 * and supports additional class names via the `className` prop for further styling.
 *
 * @component
 * @example
 * // Basic usage
 * <EditPencil />
 * // With custom size
 * <EditPencil width="24px" height="24px" />
 * // With custom class
 * <EditPencil className="custom-icon-style" />
 */
export default function EditPencil({
  width = '16px',
  height = '16px',
  className
}: {
  width?: string
  height?: string
  className?: string
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      aria-hidden='true'
      role='img'
      className={`${className} iconify iconify--fxemoji`}
      preserveAspectRatio='xMidYMid meet'
    >
      <path
        fill='#FF6E83'
        d='M462.555 51.464c-27.492-27.492-58.857-40.701-70.055-29.503l-75.72 75.72l99.558 99.558l75.72-75.72c11.198-11.198-2.011-42.562-29.503-70.055z'
      />
      <path
        fill='#BFBCAF'
        d='M414.557 99.462c-31.367-31.367-67.151-46.437-79.927-33.661l-77.54 77.54L370.678 256.93l77.54-77.54c12.776-12.776-2.294-48.561-33.661-79.928z'
      />
      <path
        fill='#2B2622'
        d='M115.545 449.979L47.25 479.683c-4.97 2.162-10.752-.115-12.913-5.085a9.863 9.863 0 0 1 0-7.828l29.705-68.295c8.622-19.823 31.681-28.903 51.504-20.281c19.823 8.622 28.903 31.681 20.281 51.504c-4.102 9.429-11.55 16.462-20.282 20.281z'
      />
      <path
        fill='#FFD469'
        d='M403.416 223.955c.073-.104.147-.208.216-.315c9.851-14.117-5.223-48.099-35.188-78.064c-29.966-29.966-63.949-45.039-78.065-35.187c-.105.068-.208.141-.311.213c-.127.094-.259.183-.383.281c-.348.268-.688.551-1.007.87l-.161.161h-.001v.001L108.477 291.954s-50.978 126.152-31.77 145.359s145.359-31.77 145.359-31.77l180.2-180.2c.319-.319.603-.659.871-1.008c.097-.123.185-.254.279-.38z'
      />
      <path
        fill='#E5AA6E'
        d='M108.477 291.954l-4.002 24.94c-.589 3.668 2.588 6.835 6.254 6.237l17.873-2.919c3.53-.576 6.647 2.349 6.296 5.908l-1.99 20.174c-.349 3.542 2.737 6.461 6.253 5.915l18.063-2.802c3.693-.573 6.853 2.66 6.195 6.339l-3.09 17.282c-.636 3.559 2.309 6.739 5.906 6.376l20.33-2.05c3.43-.346 6.314 2.542 5.963 5.971l-2.096 20.51c-.361 3.529 2.696 6.455 6.206 5.941l25.871-3.79l-145.8 61.009c11.631-11.631-18.485-41.748-30.117-30.117l61.885-144.924z'
      />
      <path
        fill='#FFB636'
        d='M145.892 298.543a6.79 6.79 0 0 1-4.801-11.591l150.356-150.356a6.79 6.79 0 0 1 9.602 9.603L150.693 296.554a6.766 6.766 0 0 1-4.801 1.989zm43.211 36.421l150.355-150.356a6.79 6.79 0 1 0-9.602-9.603L179.5 325.362a6.79 6.79 0 0 0 9.603 9.602zm38.41 38.409l150.355-150.355a6.79 6.79 0 1 0-9.602-9.603L217.91 363.771a6.79 6.79 0 0 0 9.603 9.602z'
      />
    </svg>
  )
}
