import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import AddIcon from '@mui/icons-material/Add'
import EditSharpIcon from '@mui/icons-material/EditSharp'
import CloseIcon from '@mui/icons-material/Close'
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp'
import RefreshIcon from '@mui/icons-material/Refresh'
import ApiSharpIcon from '@mui/icons-material/ApiSharp'
import DataObjectIcon from '@mui/icons-material/DataObject'
import CodeIcon from '@mui/icons-material/Code'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import SearchIcon from '@mui/icons-material/Search'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useState } from 'react'
import SearchBar from './components/projectdashboard/searchBar'

const typographyVariants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'base', 'base1', 'base2', 'base3']
function DesignSystem() {
  const [selectboxWidth, setSelectboxWidth] = useState('small')
  const [textfieldWidth, setTextfieldWidth] = useState('small')
  const [autocompleteWidth, setAutocompleteWidth] = useState('small')
  const [listWidth, setListWidth] = useState('small')
  const [searchbarWidth, setSearchbarWidth] = useState('small')
  const [activeTab, setActiveTab] = useState(0)
  const classNameOfEachItem = 'flex-spaceBetween-center w-100 m-1 minHeight-8vh'
  return (
    <div className=' m-2 maincontainerofmuicomponentspage '>
      <Typography variant='overline'>Viasocket Design System</Typography>
      <Box className='componentspagebox'>
        <div className={classNameOfEachItem}>
          <div className='flex mr-1'>
            <Button variant='primarybtn'>Primary</Button>
            <Button variant='outlined'>Secondary</Button>
            <Button variant='text'>Text</Button>
          </div>
          <div className='flex gap-2 '>
            <Button variant='primarybtn'>Primary</Button>
            <Button variant='primarybtn-lite'>Primary</Button>
            <Button variant='primarybtn-txt'>Primary</Button>
            <Button variant='primarybtn-out'>Primary</Button>
            <Button variant='darkbtn'>Dark</Button>
            <Button variant='darkbtn-out'>Dark Outlined</Button>
            <Button variant='linkbtn'>Link</Button>
            <Button variant='linkbtn-lite'>Link Lite</Button>
            <Button variant='linkbtn-txt'>Link Text</Button>
            <Button variant='linkbtn-out'>Link Outlined</Button>
            <Button variant='secondbtn'>Secondary</Button>
            <Button variant='dangerbtn'>Danger</Button>
            <Button variant='dangerbtn-lite'>Danger Lite</Button>
            <Button variant='dangerbtn-txt'>Danger Text</Button>
            <Button variant='dangerbtn-out'>Danger Outlined</Button>
          </div>
          <FormControl className='mr-1'>
            <RadioGroup defaultValue={0} row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group'>
              <FormControlLabel value={0} control={<Radio size='small' id='0' />} label='hello' />
              <FormControlLabel value={1} control={<Radio size='small' id='1' />} label='okk' />
              <FormControlLabel value={2} control={<Radio size='small' id='2' />} label='bye' />
            </RadioGroup>
          </FormControl>
          <Tabs className='mr-1' value={activeTab} onChange={(e, newVal) => setActiveTab(newVal)}>
            <Tab value={0} selected label='hello' />
            <Tab value={1} label='Okk' />
            <Tab value={2} label='bye' />
          </Tabs>
        </div>

        <div className={classNameOfEachItem}>
          <CircularProgress className='mr-1' />

          <Box className='flex-center-center mr-1'>
            <IconButton>
              <FullscreenIcon />
            </IconButton>
            <ApiSharpIcon />

            <IconButton>
              <ExpandMoreIcon />
            </IconButton>

            <AddIcon />
            <IconButton>
              <FullscreenExitIcon />
            </IconButton>
            <DataObjectIcon />

            <IconButton>
              <EditSharpIcon />
            </IconButton>
            <CodeIcon />

            <IconButton>
              <CloseIcon />
            </IconButton>
            <AccessAlarmIcon />

            <IconButton>
              <DeleteSharpIcon />
            </IconButton>
            <InsertCommentIcon />

            <IconButton>
              <ContentCopySharpIcon />
            </IconButton>
            <CheckCircleOutlineIcon color='success' />

            <IconButton>
              <RefreshIcon />
            </IconButton>
            <DoDisturbIcon color='error' />
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Box>
          <div className='flex mr-1'>
            <Switch size='small' defaultChecked color='success' />
            <Switch size='small' defaultChecked />
          </div>
        </div>

        <div className='flex-spaceBetween-center w-100 m-1 minHeight-8vh'>
          <Accordion className='mr-1'>
            <AccordionSummary expandIcon={<KeyboardArrowRightIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>Heading</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {' '}
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi, rerum veniam fugiat deserunt sunt ipsa provident ipsum ad
                culpa qui itaque aspernatur expedita error aliquam consequuntur, maiores labore facere. Pariatur cum asperiores nulla libero
                aspernatur. Iste aperiam libero officia dolorum, quas similique odio quod nulla atque ad necessitatibus suscipit error.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className='flex-spaceBetween-center w-100 m-1 minHeight-8vh'>
          <ButtonGroup variant='text'>
            <Button
              disabled={textfieldWidth === 'small'}
              onClick={() => {
                setTextfieldWidth('small')
              }}
            >
              Small
            </Button>
            <Button
              disabled={textfieldWidth === 'medium'}
              onClick={() => {
                setTextfieldWidth('medium')
              }}
            >
              Medium
            </Button>
            <Button
              disabled={textfieldWidth === 'large'}
              onClick={() => {
                setTextfieldWidth('large')
              }}
            >
              Large
            </Button>
          </ButtonGroup>
          <TextField className='mr-1' defaultValue='Text goes here' width={textfieldWidth} />
        </div>

        <div className='flex-spaceBetween-center w-100 m-1 minHeight-8vh'>
          <ButtonGroup variant='text'>
            <Button
              disabled={autocompleteWidth === 'small'}
              onClick={() => {
                setAutocompleteWidth('small')
              }}
            >
              Small
            </Button>
            <Button
              disabled={autocompleteWidth === 'medium'}
              onClick={() => {
                setAutocompleteWidth('medium')
              }}
            >
              Medium
            </Button>
            <Button
              disabled={autocompleteWidth === 'large'}
              onClick={() => {
                setAutocompleteWidth('large')
              }}
            >
              Large
            </Button>
          </ButtonGroup>
          <Autocomplete
            id='free-solo-demo'
            freeSolo
            className='mr-1'
            options={['hello', 'okk', 'bye']}
            renderInput={(params) => <TextField {...params} width={autocompleteWidth} label='freeSolo' />}
          />
        </div>

        <div className='flex-spaceBetween-center w-100 m-1 minHeight-8vh'>
          <ButtonGroup variant='text'>
            <Button
              disabled={selectboxWidth === 'small'}
              onClick={() => {
                setSelectboxWidth('small')
              }}
            >
              Small
            </Button>
            <Button
              disabled={selectboxWidth === 'medium'}
              onClick={() => {
                setSelectboxWidth('medium')
              }}
            >
              Medium
            </Button>
            <Button
              disabled={selectboxWidth === 'large'}
              onClick={() => {
                setSelectboxWidth('large')
              }}
            >
              Large
            </Button>
          </ButtonGroup>
          <FormControl className='mr-1'>
            {' '}
            <Select width={selectboxWidth} value='selectbox'>
              <MenuItem value='selectbox'>Select Box</MenuItem>
              <MenuItem value='selectbox2'>Select Box2</MenuItem>
              <MenuItem value='selectbox3'>Select Box3</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className='flex-spaceBetween-center w-100 m-1 minHeight-8vh'>
          <ButtonGroup variant='text'>
            <Button
              disabled={searchbarWidth === 'small'}
              onClick={() => {
                setSearchbarWidth('small')
              }}
            >
              Small
            </Button>
            <Button
              disabled={searchbarWidth === 'medium'}
              onClick={() => {
                setSearchbarWidth('medium')
              }}
            >
              Medium
            </Button>
            <Button
              disabled={searchbarWidth === 'large'}
              onClick={() => {
                setSearchbarWidth('large')
              }}
            >
              Large
            </Button>
          </ButtonGroup>
          <div className='mr-1'>
            <SearchBar width={searchbarWidth} />
          </div>
        </div>
        <div className='flex-spaceBetween-center w-100 m-1 minHeight-8vh'>
          <ButtonGroup variant='text'>
            <Button
              disabled={listWidth === 'small'}
              onClick={() => {
                setListWidth('small')
              }}
            >
              Small
            </Button>
            <Button
              disabled={listWidth === 'medium'}
              onClick={() => {
                setListWidth('medium')
              }}
            >
              Medium
            </Button>
            <Button
              disabled={listWidth === 'large'}
              onClick={() => {
                setListWidth('large')
              }}
            >
              Large
            </Button>
          </ButtonGroup>
          <List width={listWidth} className='mr-1'>
            <ListItem>
              <ListItemButton>
                <ListItemText>hello</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemText>okk</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemText>bye</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </div>

        {typographyVariants.map((variant) => (
          <div className='flex-spaceBetween-center w-100 m-1 minHeight-8vh' key={variant}>
            <Typography variant='h5'>{variant}</Typography>
            <Typography variant={variant} className='mr-1'>
              Hello Okk Bye
            </Typography>
          </div>
        ))}
      </Box>
    </div>
  )
}

export default DesignSystem
