import { put, takeLatest } from 'redux-saga/effects'
import { updatedUsedVariables } from '../invocationV2/invocationSliceV2.ts'
import actionType from '../../types/utility.ts'

export default function* flowJsonSaga() {
  yield takeLatest('flowJsonV2/setFlowJsonAndUsedVariables', updateGroupSuggestion)
}
function* updateGroupSuggestion(action: actionType<{ [key: string]: any }>) {
  yield put(updatedUsedVariables(action.payload.usedVariables))
}
