import { getInfoParamtersFromUrl } from '../../utils/utilities'

export const selectActiveOrgs = (state) => {
  const { orgs: obj } = state.orgs
  if (!obj) return []

  const entries = Object.entries(obj)
  const arr = []

  entries.forEach((ele) => {
    arr.push(ele[1])
  })

  return arr
}

export const getCurOrg = (state) => {
  const { orgId: id } = getInfoParamtersFromUrl()
  const { orgs: obj } = state.orgs
  if (!obj) return {}
  const entries = Object.values(obj)
  let curOrg = {}

  entries.forEach((value) => {
    if (value.id === parseInt(id, 10)) curOrg = value
  })
  return curOrg
}

export const getOrgStatus = (state) => {
  const { status } = state.orgs
  return status
}

export const getOrgCreator = (state, orgId) => {
  const { orgs } = state.orgs
  return orgs[orgId]?.created_by
}
