import { createSlice } from '@reduxjs/toolkit'

import { initialState, reducers } from './authReducer'

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers
})

export const { remove, addCurrentAuth } = authSlice.actions

export default authSlice.reducer
