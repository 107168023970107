import WebhookIcon from '@mui/icons-material/Webhook'
import { Box, Card, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames } from '../../../enums'
import config from '../../../config'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { getAppIntegrationIds } from '../../../store/scripts/scriptsSelector'

type FlowListProps = {
  title: string
  flows: string[]
  scriptList: { [key: string]: any }
  handleNavigate: (scriptId: string) => void
}
/**
 * Component to render a list of flows with their respective details.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title of the flow list.
 * @param {string[]} props.flows - An array of flow IDs.
 * @param {Object} props.scriptList - An object containing script details keyed by script ID.
 * @param {Function} props.handleNavigate - The function to navigate to a different URL.
 */

function FlowList({ title, flows, scriptList, handleNavigate }: FlowListProps) {
  return flows?.length && flows?.some((id) => scriptList[id]) ? (
    <Box className='column w-100 pt-2'>
      <Typography variant='smallHeading' className='px-2'>
        {title}
      </Typography>
      <Box className='container w-100'>
        {flows?.map((scriptId) => {
          const script = scriptList[scriptId]
          if (!script) return null
          return (
            <Box className='grid-item p-2' key={scriptId}>
              <Card
                variant='outlined'
                onClick={() => handleNavigate(scriptId)}
                className='service-block service-block__no-description flex-start-center gap-2'
              >
                {script?.json_script?.trigger?.iconUrl ? (
                  <IconWrapper iconUrl={script?.json_script?.trigger?.iconUrl} size='32px' />
                ) : (
                  <IconWrapper component={<WebhookIcon className='w-100 h-100' />} size='32px' />
                )}
                {script?.title}
              </Card>
            </Box>
          )
        })}
      </Box>
    </Box>
  ) : null
}

type DeletedAndPausedFlowsProps = {
  orgId: string
  firstServiceId: string
  isEmbedUrl: boolean
  projectId: string
}
/**
 * Component to display lists of paused and deleted flows.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.orgId - The organization ID.
 * @param {string} props.firstServiceId - The first service ID.
 * @param {boolean} props.isEmbedUrl - Flag to determine if the URL is an embedded URL.
 * @param {string} props.projectId - The project ID.
 * @returns {JSX.Element} The rendered component.
 */

function DeletedAndPausedFlows({ orgId, firstServiceId, isEmbedUrl, projectId }: DeletedAndPausedFlowsProps) {
  const navigate = useNavigate()
  const { pausedFlows, deletedFlows, scriptList } = useCustomSelector((state) => {
    const flowsObject = getAppIntegrationIds(state, projectId)?.[firstServiceId]
    return {
      pausedFlows: flowsObject?.pause,
      deletedFlows: flowsObject?.delete,
      scriptList: state.scripts.scripts
    }
  })

  const handleNavigate = (scriptId: string) => {
    const flowUrl = isEmbedUrl
      ? `/integrations/embed/${orgId}/${projectId}${firstServiceId ? `/service/${firstServiceId}` : ''}/workflow/${scriptId}/${
          Tabnames.DRAFT
        }`
      : `${config.projectsBaseUrl}/${orgId}/${projectId}${firstServiceId ? `/service/${firstServiceId}` : ''}${
          config.workflowBaseUrl
        }/${scriptId}/${Tabnames.DRAFT}`
    navigate(flowUrl)
  }

  return (
    <Box className='w-100 column mt-4'>
      <FlowList title='Paused Flows' flows={pausedFlows} scriptList={scriptList} handleNavigate={handleNavigate} />
      <FlowList title='Deleted Flows' flows={deletedFlows} scriptList={scriptList} handleNavigate={handleNavigate} />
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(DeletedAndPausedFlows), [ParamsEnums.orgId, ParamsEnums.projectId, ParamsEnums.isEmbedUrl])
)
