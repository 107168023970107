import { createSlice } from '@reduxjs/toolkit'
import { initialState, reducers } from './modalReducer'

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers
})

export const { addModalData } = modalSlice.actions

export default modalSlice.reducer
