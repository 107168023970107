import { Box, Button } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Chatbot from 'interface-chatbot'
import { useLocation, useNavigate } from 'react-router-dom'
import { getSupportedLibraries } from '../../api/index'
import config from '../../config'
import { BRIDGES, ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { closeAiSlider, openAiSlider, setSupportedLibraries, updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { processSupportedLibraryData } from '../../utils/aiUtility/common.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { getAvailableVariables, getProxyAuthToken } from '../../utils/utilities'
import './ChatbotInitialize.scss'
import { closeSuggestionBox } from '../../store/chip/allChip/allChip.ts'
import DrawerWithSearch from '../globalData/DrawerWithSearch'
import { getContextFromInvocation } from '../plugin/pluginUtils/plugin_utility.ts'

interface ChatbotIrameComponentType {
  scriptId: string
  stepId: string
  // slugName: string
  tabName: string
  sectionId: string
  projectId: string
  orgId: string
  versionId: string
}

const getAllQueryParams = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const params = {}

  // Iterate over query parameters using forEach
  queryParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}

/**
 *
 * @param root0
 * @param root0.scriptId
 * @param root0.stepId
 * @param root0.tabName
 * @param root0.sectionId
 * @param root0.projectId
 * @param root0.orgId
 * @param root0.versionId
 */

// this function is used to send threadId, bridgeNames and variables to the chatbot, depends on which page I'm. Different bridgeNames for different purposes.
function ChatBotIframComponent({ scriptId, stepId, tabName, sectionId, projectId, orgId, versionId }: ChatbotIrameComponentType) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = getAllQueryParams()
  const {
    threadId,
    bridgeName,
    allVariables,
    stepInstance,
    stepOrder,
    appInfo,
    dhActionVersion,
    timezone,
    functionName,
    librarySupported,
    context,
    isPublishedActionOrTrigger,
    chatbottoken
  } = useCustomSelector((state: $ReduxCoreType) => ({
    threadId: state.Interface?.threadId || '',
    bridgeName: state.Interface?.bridgeName || BRIDGES.ROOT,
    allVariables: state.invocationV2?.groupedSuggestions,
    stepInstance: state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName],
    stepOrder: state.flowJsonV2?.[scriptId]?.stepOrder,
    context: getContextFromInvocation(state?.invocationV2?.[scriptId]),
    appInfo: state?.appInfo,
    dhActionVersion: state?.DHActionVersion,
    timezone: {
      timezoneIdentifier: state?.orgs?.orgs[state?.orgs?.currentOrg]?.meta?.timezoneIdentifier,
      timezone: state?.orgs?.orgs[state?.orgs?.currentOrg]?.timezone
    },
    functionName: state.appInfo.currentSlugName,
    librarySupported: state.appInfo.librarySupported,
    isPublishedActionOrTrigger: (() => {
      if (!versionId) return false
      return state.DHActionVersion?.[sectionId]?.[versionId]?.status === 'published'
    })(),
    chatbottoken: state.appInfo.chatbottoken
  }))
  const [chatbotData, setChatbotData] = React.useState<{ variables: any; threadId: string; bridgeName: string }>({
    variables: {},
    threadId: '',
    bridgeName: ''
  })
  const [actionId, setActionId] = React.useState('')
  const [triggerId, setTriggerId] = React.useState('')
  const location = useLocation()
  const [authId, setAuthId] = React.useState('')

  const url = new URL(window.location.href)
  const pathParts = url.pathname.split('/')
  const actionIndex = pathParts.indexOf('action')
  const triggerIndex = pathParts.indexOf('trigger')
  const authIndex = pathParts.indexOf('auth')

  const isonboarding = location.pathname.startsWith('/onboarding')
  useEffect(() => {
    if (actionIndex !== -1 && actionIndex + 1 < pathParts.length) {
      setActionId(pathParts[actionIndex + 1])
    }
    if (triggerIndex !== -1 && triggerIndex + 1 < pathParts.length) {
      setTriggerId(pathParts[triggerIndex + 1])
    }
    if (authIndex !== -1 && authIndex + 1 < pathParts.length) {
      setAuthId(pathParts[authIndex + 1])
    }
    GetSupportedLibrary()
  }, [])

  const GetSupportedLibrary = async () => {
    if (librarySupported?.length === 0) {
      const data = await getSupportedLibraries()
      dispatch(setSupportedLibraries(data))
    }
  }
  const availableVariables = useMemo(() => {
    return getAvailableVariables(stepOrder, allVariables) || ''
  }, [stepOrder, allVariables])

  const processedLibraries =
    useMemo(() => {
      return librarySupported?.length ? processSupportedLibraryData(librarySupported) : []
    }, [librarySupported]) || []

  /**
   * this function is used to get the threadId and brigeNames, depends upon which page I'm
   */
  function getThreadIdAndBridgeName() {
    if (isonboarding)
      return {
        threadId: `onboarding-${orgId}`,
        bridgeName: 'onboarding'
      }
    if (
      stepId?.length > 0 &&
      (appInfo?.currentStepType === 'api' ||
        appInfo?.currentStepType === 'function' ||
        appInfo?.currentStepType === 'plugin' ||
        appInfo?.currentStepType === 'variable' ||
        appInfo?.currentStepType === 'if')
    ) {
      const bridgeNameToSend = appInfo.currentStepType
      return {
        threadId: stepId,
        bridgeName: bridgeNameToSend
      }
    }

    if (versionId || sectionId) {
      return {
        threadId: versionId || sectionId,
        bridgeName: 'plugin'
      }
    }

    if (stepId === '') {
      return {
        threadId: scriptId || projectId || orgId,
        bridgeName: scriptId ? 'FlowByAi-singleBridge' : projectId ? 'project' : 'org'
      }
    }

    return {
      threadId: '',
      bridgeName: 'root'
    }
  }

  /**
   * this function is used to get the variable I have to pass from that page to chatbot
   */
  async function getData() {
    const { threadId, bridgeName } = getThreadIdAndBridgeName()
    let variables: any = {
      type: bridgeName,
      currentCode: '',
      availableVariables: ''
    }

    switch (bridgeName) {
      case 'api':
        variables = {
          type: 'api',
          currentCode: JSON.stringify(stepInstance),
          availableVariables,
          libraries: processedLibraries,
          functionName: functionName
        }
        break
      case 'function':
        variables = {
          type: 'function',
          currentCode: stepInstance?.code,
          availableVariables,
          libraries: processedLibraries,
          functionName: functionName
        }
        break
      case 'variable':
        variables = {
          type: 'variable',
          currentCode: stepInstance?.code,
          availableVariables,
          libraries: processedLibraries
        }
        break
      case 'if':
        variables = {
          type: 'if',
          currentCode: stepInstance?.code,
          availableVariables,
          libraries: processedLibraries
        }
        break
      case 'plugin':
        variables = {
          type: 'plugin',
          libraries: processedLibraries
        }
        break
      case 'action':
        variables = {
          type: 'action',
          currentInputFields: dhActionVersion?.[triggerId]?.[params?.versionId]?.inputjson?.inputFields || [],
          libraries: processedLibraries
        }
        break
      case 'project':
        variables = {
          type: 'project',
          proxy_auth_token: getProxyAuthToken()
        }
        break
      case 'org':
        variables = {
          variables: JSON.stringify({
            orgId,
            proxy_auth_token: getProxyAuthToken()
          })
        }
        break
      case 'FlowByAi-singleBridge':
        variables = {
          variables: {
            env: process.env.REACT_APP_API_ENVIRONMENT,
            proxy_auth_token: getProxyAuthToken(),
            orgId,
            scriptId
          },
          context
        }
        break

      case 'transferOption':
        variables = {
          type: 'transferOption',
          currentCode: dhActionVersion?.[triggerId][params?.versionId]?.transferoption,
          availableVariables,
          libraries: processedLibraries
        }
        break
      case 'onboarding':
        variables = {
          proxy_auth_token: getProxyAuthToken(),
          orgId,
          environment: process.env.REACT_APP_API_ENVIRONMENT
        }
        break
      // Add cases for other bridgeNames here
      default:
        // Default variables if bridgeName doesn't match any specific case
        variables = {
          type: bridgeName,
          currentCode: '',
          availableVariables: '',
          libraries: processedLibraries
        }
    }
    let finalBridgeName = bridgeName
    if (['flow', 'if', 'transferOption', 'script'].includes(bridgeName)) {
      finalBridgeName = 'root'
    } else if (['action', 'trigger', 'auth'].includes(bridgeName)) {
      finalBridgeName = 'plugin'
    } else if (['org', 'project'].includes(bridgeName)) {
      finalBridgeName = 'orgOrProject'
    }

    setChatbotData({
      variables: { ...variables, timezone },
      threadId,
      bridgeName: finalBridgeName
    })
    return {
      variables,
      threadId: threadId,
      bridgeName: finalBridgeName
    }
  }

  const conditionForCssAndNavigation = () => {
    return window.location?.pathname?.endsWith('chatbot')
  }

  useEffect(() => {
    getData()
  }, [
    threadId,
    bridgeName,
    stepInstance?.code,
    stepId,
    scriptId,
    projectId,
    allVariables,
    stepInstance?.type,
    sectionId,
    actionId,
    triggerId,
    authId,
    orgId,
    versionId,
    location.pathname
  ])

  useEffect(() => {
    /**
     *
     * @param e
     */
    function handleNavigate(e: any) {
      if (e?.data?.type === 'close' && conditionForCssAndNavigation()) {
        navigate(`${config.projectsBaseUrl}/${orgId}`)
      }
    }
    window.addEventListener('message', handleNavigate)
    return () => {
      window.removeEventListener('message', handleNavigate)
    }
  }, [orgId])

  return (
    <>
      <Chatbot
        hideIcon
        hideCloseButton={isonboarding}
        embedToken={chatbottoken}
        fullScreen={conditionForCssAndNavigation()}
        onOpen={() => {
          dispatch(openAiSlider())
          dispatch(closeSuggestionBox())

          if (tabName === 'draft') {
            dispatch(updateAppInfoState({ compareSliderOpen: false, isProjectSliderOpen: false }))
          }
        }}
        onClose={() => {
          dispatch(closeAiSlider())
        }}
        threadId={chatbotData.threadId}
        bridgeName={chatbotData.bridgeName}
        variables={chatbotData.variables}
        iconColor='light'
      />
      {!isPublishedActionOrTrigger &&
        !isonboarding &&
        orgId &&
        !location.pathname.includes('notification') &&
        (location.pathname.startsWith('/projects') ? (
          <DrawerWithSearch />
        ) : (
          <Box className='open-chatbot-button'>
            <Button
              variant='contained'
              className='pointer-auto'
              onClick={() => {
                window.openChatbot()
              }}
            >
              Ask AI
            </Button>
          </Box>
        ))}
    </>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(ChatBotIframComponent), [
    ParamsEnums.scriptId,
    ParamsEnums.stepId,
    ParamsEnums.versionId,
    // ParamsEnums.slugName,
    ParamsEnums.tabName,
    ParamsEnums.sectionId,
    ParamsEnums.projectId,
    ParamsEnums.orgId,
    ParamsEnums.versionId,
    ParamsEnums.pluginId
  ])
)
