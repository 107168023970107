import { createAsyncThunk } from '@reduxjs/toolkit'
import { getLastInvocation } from '../../api/index'
import { removeInvocationDataV2, setInvocationDataV2 } from './invocationSliceV2.ts'
import { InvocationDataType } from '../../types/Invocation.ts'

export const parseJSONProperties = (response: InvocationDataType) => {
  if (response && Object.keys(response)?.length) {
    response.requestSnapshotJsonData = JSON.parse(response?.requestSnapshot || '{}')
    response.requestSnapshot = response?.requestSnapshotJsonData
    response.responseSnapshotJsonData = JSON.parse(response?.responseSnapshot || '{}')
    response.responseSnapshot = JSON.parse(response?.responseSnapshot || '{}')
    response.valsJson = JSON.parse(response.vals || '{}')
    response.vals = response?.valsJson
    response.stepRequestSnapshot = JSON.parse(response.stepRequestSnapshot || '{}')
  }
}

export const stingifyJSONProperties = (response: InvocationDataType) => {
  response.requestSnapshot = JSON.stringify(response?.requestSnapshot)
  response.responseSnapshot = JSON.stringify(response?.responseSnapshot)
  response.vals = JSON.stringify(response.vals)
  response.stepRequestSnapshot = JSON.stringify(response.stepRequestSnapshot)
  if (typeof response.output === 'object') {
    response.output = JSON.stringify(response.output)
  }
}

export const getLastInvocationDataThunk = createAsyncThunk(
  'invocationV2/getLastInvocationDataThunk',
  async (payload: string, { dispatch }) => {
    try {
      let response = {}
      response = await getLastInvocation(payload?.scriptId)
      if (response) parseJSONProperties(response)

      if (response) {
        // const scriptId = getInfoParamtersFromUrl().scriptId
        // const flowJsonBlock = getState().flowJsonV2[scriptId]?.flowJson.blocks
        const headersToRemove = ['postman-token', 'content-length', 'accept-encoding', 'user-agent', 'connection', 'authorization', 'host']
        const filteredHeaders = response?.requestSnapshot?.headers ?? {}
        headersToRemove.forEach((header) => delete filteredHeaders[header])
        response.requestSnapshot.headers = filteredHeaders
        response.requestSnapshotJsonData.headers = filteredHeaders

        // Object.keys(response?.responseSnapshot).forEach((key) => {
        //   if (!flowJsonBlock?.[key]) {
        //     delete response?.responseSnapshot?.[key]
        //   }
        // })

        // Object.keys(response?.vals).forEach((key) => {
        //   if (!flowJsonBlock?.[key]) {
        //     delete response?.vals?.[key]
        //   }
        // })
        response.responseSnapshotJsonData = response.responseSnapshot
        response.valsJson = response.vals

        stingifyJSONProperties(response)

        const stepOrderJsonData = response?.stepOrderJsonData
        const stepOrderjson = {}
        for (let i = 0; i < stepOrderJsonData?.length; i++) {
          const item = stepOrderJsonData[i]
          stepOrderjson[item.id] = item
        }
        response['stepOrderjson'] = stepOrderjson
        dispatch(setInvocationDataV2(response))
        return response
      }
      dispatch(removeInvocationDataV2({}))
      return []
    } catch (error: any) {
      dispatch(removeInvocationDataV2({}))
      return error.message // or dispatch an error action
    }
  }
)
