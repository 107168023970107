import { createAsyncThunk } from '@reduxjs/toolkit'
/* eslint-disable-next-line import/no-cycle */
import { getCurrentUser } from '../../api/index'

export const saveUser = createAsyncThunk('user/saveUser', async () => {
  const data = await getCurrentUser()
  return data?.data?.data?.[0]
})

export const removeUser = createAsyncThunk('user/removeUser', async () => {
  return 5
})
