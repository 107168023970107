import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import React from 'react'
import { Box, Button, Chip } from '@mui/material'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import { useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'

interface SelectedServicesProps {
  orgId: string
  addmore?: boolean
  selectedServicesArray?: string[]
}

/**
 * Component for displaying selected services.
 *
 * This component renders a list of selected services as chips and optionally
 * provides a 'Next' button to navigate to the service page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.orgId - The ID of the current organization.
 * @param {boolean} [props.addmore=false] - Flag to indicate if more services can be added.
 * @param {string[]} [props.selectedServicesArray=[]] - Array of selected service IDs.
 *
 * @returns {React.ReactElement} The SelectedServices component.
 */

function SelectedServices({ orgId, addmore = false, selectedServicesArray = [] }: SelectedServicesProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: plugins } = selectedServicesArray?.length ? useFetchPlugins(orgId, [], selectedServicesArray) : { data: {} }

  return (
    <Box className='flex-spaceBetween-end w-100 gap-5 p-2'>
      <Box className='flex-start-center w-100 gap-1 flex-wrap'>
        {Object.entries(plugins || {})?.map(([serviceId, service]) => {
          return (
            <Chip
              icon={<img src={service.iconurl} width={18} alt='' />}
              className='grid-item p-2'
              size='large'
              key={serviceId}
              label={service.name}
            />
          )
        })}
      </Box>
      {!addmore && (
        <Button
          variant='contained'
          className='px-3'
          disabled={selectedServicesArray?.length < 3}
          endIcon={<ChevronRightIcon />}
          onClick={() => {
            navigate(`/projects/${orgId}/appsexplore/servicepage`)
            dispatch(closeModalRedux())
          }}
        >
          Next
        </Button>
      )}
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(SelectedServices), [ParamsEnums.orgId, ParamsEnums.projectId]))
