import { Box, Skeleton } from '@mui/material'

export function EventPageSkeleton() {
  return (
    <Box className='flex gap-2 flex-wrap'>
      <Skeleton variant='rounded' width={210} height={72} />
      <Skeleton variant='rounded' width={340} height={72} />
      <Skeleton variant='rounded' width={280} height={72} />
      <Skeleton variant='rounded' width={390} height={72} />
      <Skeleton variant='rounded' width={110} height={72} />
      <Skeleton variant='rounded' width={160} height={72} />
    </Box>
  )
}
