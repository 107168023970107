import { errorToast } from '../components/customToast'
import axios from '../interceptor/interceptor'

export const createNewTable = async (name, orgId) => {
  try {
    return await axios.post(`${process.env.REACT_APP_TABLE_BASE_URL}/dbs/${orgId}/dbs`, {
      name: name
    })
  } catch (e) {
    errorToast('Something went wrong!')
    return null
  }
}
export const getAllTablesList = async (orgId) => {
  try {
    return await axios.get(`${process.env.REACT_APP_TABLE_BASE_URL}/dbs/${orgId}/dbs`)
  } catch (e) {
    errorToast('Something went wrong!')
    return null
  }
}
