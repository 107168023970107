import cloneDeep from 'lodash.clonedeep'
import { call, put, select } from 'redux-saga/effects'
import { dhCreateApi, dhGetActionIdAndAuthIdCountApi, dhGetApi, dhUpdateApi } from '../../../api/developerHubApis/developerHubApis.ts'
import { errorToast } from '../../../components/customToast'
import { DH_FLOW_FILTERS, OBJECTS_TO_PARSE, tableName } from '../../../pages/developerHub/constants/developerHubConstants.ts'
import { removeKeys, stringifyAuthDataUtility } from '../../../pages/developerHub/utils/utility.ts'
import { AuthFieldsType } from '../../../types/DHAuthentication.ts'
import actionType from '../../../types/utility.ts'
import { getInfoParamtersFromUrl } from '../../../utils/utilities'
import {
  createAuthDetailError,
  createAuthDetailSuccess,
  getActionIdAndAuthIdCountSuccess,
  getAuthDetailError,
  getAuthDetailSuccess,
  updateAuthDetailError,
  updateAuthDetailSuccess
} from './dhAuthenticationSlice.ts'
import { $ReduxCoreType } from '../../../types/reduxCore.ts'
import { updatePluginDetailsStart } from '../DHPlugin/dhPluginSlice.ts'

export function* getAuthDetailSaga(action: actionType<{ pluginId: string }>) {
  try {
    const { pluginId } = action.payload
    const payload = {
      ...action.payload,
      filter: DH_FLOW_FILTERS.GET_AUTH_DETAIL_START,
      identifier: action.payload.pluginId
    }
    const { isSuccess, data } = yield call(dhGetApi, payload, tableName.oauthdetails)
    if (isSuccess) {
      yield put(getAuthDetailSuccess({ data, pluginId }))
    } else {
      yield put(getAuthDetailError({}))
      errorToast('error in getting auth details')
    }
  } catch (error) {
    yield put(getAuthDetailError({}))
    errorToast('error in getting auth details')
  }
}

export function* createAuthDetailSaga(action: actionType<any>): any {
  try {
    const { payload: dataToSend, navigate, setCreatingNewAuthVersion } = action.payload
    const { orgId, pluginId } = action.urlData
    let payload = removeKeys(cloneDeep(dataToSend), ['rowid', 'domain', 'pluginname', 'navigate'])
    payload = stringifyAuthDataUtility(payload)
    const allAuthData = yield select((state) => state.DHAuthentication?.[pluginId])
    const newVersionid: string = `V${parseInt(Object.keys(allAuthData || {})?.length.toString(), 10) + 1}`
    payload.authversion = newVersionid

    const { pluginId: currentPluginId } = getInfoParamtersFromUrl()
    const { isSuccess, data } = yield call(dhCreateApi, { ...payload, pluginrecordid: currentPluginId }, tableName.oauthdetails)
    if (isSuccess) {
      yield put(createAuthDetailSuccess({ data, pluginId: currentPluginId }))
      // successToast('Your data is saved successfully')
      if (setCreatingNewAuthVersion) {
        setCreatingNewAuthVersion(false)
      }
      const isPrefferedAuthPresent = yield select(
        (state: $ReduxCoreType) => !!state.DHPlugin?.[orgId]?.[currentPluginId]?.preferedauthversion
      )
      if (!isPrefferedAuthPresent) {
        yield put(updatePluginDetailsStart({ preferedauthversion: data?.rowid }))
      }
      navigate(`/developer/${orgId}/plugin/${pluginId}/auth/${data.rowid}`)
    } else {
      yield put(createAuthDetailError({}))
      errorToast('error in creating auth!!. Please Try Again')
    }
  } catch (e) {
    yield put(createAuthDetailError({}))
    if (action.payload?.setCreatingNewAuthVersion) {
      action.payload?.setCreatingNewAuthVersion(false)
    }
    errorToast('error in creating auth!!. Please Try Again')
  }
}

export function* updateAuthDetailSaga(action: actionType<AuthFieldsType>): any {
  // const currentAuthRecordId = yield select((state: $ReduxCoreType) => state?.DHAuthentication?.fields?.rowid)
  const { sectionId: currentAuthRecordId } = action.urlData
  const payload = {
    dataToUpdate: stringifyAuthDataUtility(action.payload, OBJECTS_TO_PARSE.authKeysToParse),
    filter: DH_FLOW_FILTERS.UPDATE_AUTH_DETAIL_START,
    identifier: currentAuthRecordId
  }
  const { isSuccess } = yield call(dhUpdateApi, payload, tableName.oauthdetails)
  if (isSuccess) {
    yield put(updateAuthDetailSuccess({}))
    if (action.payload?.setIsFieldDataSaved) {
      action.payload?.setIsFieldDataSaved({ [action.payload?.componentToRender]: true })
    }
    // successToast('Your data is saved successfully')
  } else {
    yield put(updateAuthDetailError(action.payload))
    errorToast('error in updating auth details')
  }
}

export function* getActionIdAndAuthIdCountSaga(action: any) {
  const { isSuccess, data } = yield call(dhGetActionIdAndAuthIdCountApi, action?.payload[0]?.pluginrecordid)
  if (isSuccess) {
    yield put(getActionIdAndAuthIdCountSuccess({ pluginId: action?.payload[0]?.pluginrecordid, data }))
  }
}
