import { BlockTypes } from '../enums'
import { $CurrentSelectedPluginType } from '../types/reduxCore.ts'

const ApiInitialInstance = {
  type: BlockTypes.API,
  title: '',
  url: '',
  httpRequestType: 'post',
  queryParams: '',
  headers: 'Content-Type: application/json',
  bodyFormData: [
    {
      name: '',
      value: ''
    }
  ],
  bodyFormUrlencoded: [
    {
      name: '',
      value: ''
    }
  ],
  bodyJsonScript: '',
  bodyRawScript: '',
  bodyRawScriptType: 'Text (text/plain)',
  requestBodyType: '2',
  result: {},
  payload: {},
  editToggle: false,
  oauth: {},
  iconUrl: '',
  customPayload: {}
}

const FunctionInitialInstance = {
  id: '',
  code: '',
  type: BlockTypes.FUNCTION,
  title: '',
  optimizedCode: ''
}

const VariableInitialInstance = {
  id: '',
  title: '',
  type: BlockTypes.VARIABLE,
  code: '',
  codeHTML: '',
  optimizedCode: ''
}

const IfInitialInstance = {
  id: '',
  type: BlockTypes.IFBLOCK,
  code: '',
  codeHTML: '',
  result: '',
  optimizedCode: '',
  statement: ''
}

const initialStatePlugin: $CurrentSelectedPluginType = {
  isAuthLoading: false,
  isVerify: false,
  pluginData: {
    source: '',
    selectedValues: {
      authData: {},
      inputData: {}
    },
    selectedCreate: '',
    actionName: '',
    actionVersionId: '',
    serviceId: '',
    serviceName: '',
    iconUrl: '',
    inputFields: [],
    authIdLookup: '',
    type: ''
  },
  requiredFieldsList: { root: [] },
  buttonStatus: {
    dryRunButtonState: {
      isLoading: false
    },
    saveButtonState: {
      isLoading: false
    },
    sourceDryRun: {
      isLoading: false
    }
  },
  optionalFieldVisibility: {}
}

export { ApiInitialInstance, FunctionInitialInstance, VariableInitialInstance, IfInitialInstance, initialStatePlugin }
