import React, { useState } from 'react'
import { ClickAwayListener, Button, Box } from '@mui/material'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import Search from './Search'

/**
 * DrawerWithSearch component
 *
 * This component renders a search drawer that can be toggled open or closed.
 * It uses Material-UI's Drawer and ClickAwayListener components to handle the drawer's behavior.
 * The drawer contains a Search component and is controlled by a state variable.
 * The component also conditionally renders a SearchBar or SearchIcon based on the isProjectSliderOpen state.
 *
 * @param {Object} props - The component props
 * @param {string} props.scriptId - The script ID used to determine the width of the container
 * @returns {JSX.Element} The rendered component
 */

function DrawerWithSearch() {
  const [open, setOpen] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box className='open-chatbot-button search-and-ai  flex-end-center'>
        {open ? (
          <Search />
        ) : (
          <Button
            variant='contained'
            onClick={() => {
              setOpen(true)
            }}
            className='pointer-auto'
          >
            Search Or Ask AI
          </Button>
        )}
      </Box>
    </ClickAwayListener>
  )
}

export default React.memo(addUrlDataHoc(React.memo(DrawerWithSearch), []))
