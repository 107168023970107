import { call, delay, put, select } from 'redux-saga/effects'
import { dhCreateApi, dhGetApi, dhUpdateApi } from '../../../api/developerHubApis/developerHubApis.ts'
import { errorToast } from '../../../components/customToast'
import { ACTION_TYPE, DH_FLOW_FILTERS, STATUS_TYPE, tableName } from '../../../pages/developerHub/constants/developerHubConstants.ts'
import { ActionFields } from '../../../types/DHAction.ts'
import { $ReduxCoreType } from '../../../types/reduxCore.ts'
import actionType from '../../../types/utility.ts'
import { getProxyAuthToken } from '../../../utils/utilities'
import { updateAppInfoState } from '../../appInfo/appInfoSlice.ts'
import {
  createNewActionError,
  createNewActionSuccess,
  deleteActionError,
  deleteActionSuccess,
  getActionDetailError,
  getActionDetailSuccess,
  getAllActionError,
  getAllActionSuccess,
  updateActionDetailsError,
  updateActionDetailsSuccess
} from './dhActionSlice.ts'

export function* getAllActionSaga(action: actionType<{ pluginId: string }>) {
  const payload = {
    ...action.payload,
    filter: DH_FLOW_FILTERS.GET_ALL_ACTION_START,
    identifier: action.payload?.pluginId
  }
  const { isSuccess, data } = yield call(dhGetApi, payload, tableName.actions)
  if (isSuccess) {
    yield put(getAllActionSuccess({ data, pluginId: action.payload?.pluginId }))
  } else {
    errorToast('error in fetching all actions')
    yield put(getAllActionError({ pluginId: action.payload?.pluginId }))
  }
}

export function* setActionDetailsSaga(action: actionType<ActionFields>): any {
  const { pluginId } = action.urlData
  let sectionId = action.urlData.sectionId
  if (action.payload.rowid) {
    sectionId = action.payload.rowid
  }

  const payload = {
    dataToUpdate: action?.payload,
    filter: DH_FLOW_FILTERS.UPDATE_ACTION_DETAILS_START,
    identifier: sectionId
  }
  const { isSuccess, data } = yield call(dhUpdateApi, payload, tableName.actions)
  if (isSuccess) {
    // successToast('updated action detail')
    yield put(updateActionDetailsSuccess({ data, pluginId, actionId: sectionId }))
  } else {
    errorToast('error in updating actions')
    yield put(updateActionDetailsError({ data: action.payload, pluginId, actionId: sectionId }))
  }
}

export function* createNewActionSaga(
  action: actionType<{
    payload: { name: string; description: string; status: string; key: string; isvisible: boolean; authid: string; type: string }
    navigate: any
  }>
): any {
  const { pluginId, orgId } = action?.urlData
  const { payload: dataToSend, navigate } = action.payload
  const pluginDomain = yield select((state: $ReduxCoreType) => state.DHPlugin[orgId][pluginId].domain)
  const { isSuccess, data, actionVersionData } = yield call(dhCreateApi, dataToSend, tableName.actions)
  if (isSuccess && data?.rowid) {
    yield put(createNewActionSuccess({ data, pluginId }))
    if (data?.type === ACTION_TYPE.customAction) {
      navigate(`/developer/${orgId}/plugin/${pluginId}/customAction/${data?.rowid}`)
    } else {
      const variables = JSON.stringify({
        environment: process.env.REACT_APP_API_ENVIRONMENT,
        functionId: actionVersionData.data[0].rowid,
        orgId,
        proxy_auth_token: getProxyAuthToken(),
        isUserOnDh: true
      })
      if (typeof window?.SendDataToChatbot === 'function')
        window?.SendDataToChatbot({
          bridgeName: 'plugin',
          threadId: actionVersionData.data[0].rowid,
          variables: {
            actionVersionRowId: actionVersionData.data[0].rowid,
            pluginId: pluginId,
            actionName: data.name,
            actionId: data?.rowid,
            oldInputFields: [],
            domain: pluginDomain || '',
            oldPerformApi: '// code',
            variables
          },
          askAi: `create action to ${data.name} of ${pluginDomain}`
        })
      window.openChatbot()
      navigate(
        `/developer/${orgId}/plugin/${pluginId}/${data?.type}/${data?.rowid}${
          actionVersionData.data[0].rowid ? `?versionId=${actionVersionData.data[0].rowid}` : ''
        }`
      )
      yield delay(500) // Wait for 0.5 seconds
      yield put(updateAppInfoState({ isAiSliderOpen: true, isProjectSliderOpen: false, isPluginDashboard: false }))
    }
  } else {
    errorToast('error in creating action')
    yield put(createNewActionError({ data: action.payload, pluginId }))
  }
}

export function* getActionDetailsSaga(action: actionType<{ rowid: string }>) {
  const { pluginId } = action?.urlData
  const payload = {
    ...action.payload,
    filter: DH_FLOW_FILTERS.GET_ACTION_DETAIL_START,
    identifier: action.payload?.rowid
  }
  const { isSuccess, data } = yield call(dhGetApi, payload, tableName.actions)
  if (isSuccess) {
    yield put(getActionDetailSuccess({ data, pluginId }))
  } else {
    errorToast('error in getting action details')
    yield put(getActionDetailError({ data: action?.payload, pluginId }))
  }
}

export function* deleteActionSaga(action: actionType<{ rowid: string; type: string }>) {
  const payload = {
    dataToUpdate: { status: STATUS_TYPE.deleted },
    filter: DH_FLOW_FILTERS.DELETE_ACTION_START,
    identifier: action.payload?.rowid
  }
  const { isSuccess } = yield call(dhUpdateApi, payload, tableName.actions)
  if (isSuccess) {
    yield put(deleteActionSuccess({ rowid: action.payload.rowid, type: action.payload.type }))
    // successToast('Successfully deleted action')
  } else {
    errorToast('Error in deleting action')
    yield put(deleteActionError({ rowid: action.payload.rowid, type: action.payload.type }))
  }
}
