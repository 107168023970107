import { createSlice } from '@reduxjs/toolkit'
import { initialState, reducers } from './dhActionVersionReducer.ts'

const dhActionVersionSlice = createSlice({
  name: 'DHActionVersion',
  initialState,
  reducers
})

export const {
  getActionVersionStart,
  getActionVersionSuccess,
  getActionVersionError,
  // setActionVersionDetailStart,

  createNewActionVersionStart,
  createNewActionVersionSuccess,
  createNewActionVersionError,

  updateActionVersionDetailsStart,
  updateActionVersionDetailsSuccess,
  updateActionVersionDetailsError,
  updateActionVersionDetailsInRedux,
  updateResultOfSlider,
  updateActionVersionCodeInRedux,
  updateActionVersionInputFields,
  getParticularSliderCodeActionVersionSuccess,

  setActionStateToIntial,
  deleteActionVersionStart,
  deleteActionVersionSuccess,
  deleteActionVersionError,

  // ai
  optimizeCodeByAiStart,
  optimizeCodeByAiSuccess,
  optimizeCodeByAiError
} = dhActionVersionSlice.actions

export default dhActionVersionSlice.reducer
