import { createAsyncThunk } from '@reduxjs/toolkit'
/* eslint-disable-next-line import/no-cycle */
import { getUsersByOrgId } from '../../api'

const getAllUsers = createAsyncThunk('orgUsers/getAllUsersThunk', async () => {
  try {
    const usersArray = await fetchAllUsers()
    return { users: usersArray }
  } catch (error) {
    console.error('Error in getOldLogsDataThunk:', error)
    return error?.message
  }
})

async function fetchAllUsers(page = 1) {
  const response = await getUsersByOrgId(page)
  const users = response.data?.data?.data
  const totalPageCount = response?.data?.data?.totalPageCount
  if (page < totalPageCount) {
    const nextPageUsers = await fetchAllUsers(page + 1)
    return users.concat(nextPageUsers)
  }
  return users
}
export default getAllUsers
