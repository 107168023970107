const timezoneData = [
  { identifier: 'Africa/Abidjan', offSet: '+00:00' },
  { identifier: 'Africa/Accra', offSet: '+00:00' },
  { identifier: 'Africa/Addis_Ababa', offSet: '+03:00' },
  { identifier: 'Africa/Algiers', offSet: '+01:00' },
  { identifier: 'Africa/Asmara', offSet: '+03:00' },
  { identifier: 'Africa/Bamako', offSet: '+00:00' },
  { identifier: 'Africa/Bangui', offSet: '+01:00' },
  { identifier: 'Africa/Banjul', offSet: '+00:00' },
  { identifier: 'Africa/Bissau', offSet: '+00:00' },
  { identifier: 'Africa/Blantyre', offSet: '+02:00' },
  { identifier: 'Africa/Brazzaville', offSet: '+01:00' },
  { identifier: 'Africa/Bujumbura', offSet: '+02:00' },
  { identifier: 'Africa/Cairo', offSet: '+02:00' },
  { identifier: 'Africa/Casablanca', offSet: '+00:00' },
  { identifier: 'Africa/Ceuta', offSet: '+01:00' },
  { identifier: 'Africa/Conakry', offSet: '+00:00' },
  { identifier: 'Africa/Dakar', offSet: '+00:00' },
  { identifier: 'Africa/Dar_es_Salaam', offSet: '+03:00' },
  { identifier: 'Africa/Djibouti', offSet: '+03:00' },
  { identifier: 'Africa/Douala', offSet: '+01:00' },
  { identifier: 'Africa/El_Aaiun', offSet: '+00:00' },
  { identifier: 'Africa/Freetown', offSet: '+00:00' },
  { identifier: 'Africa/Gaborone', offSet: '+02:00' },
  { identifier: 'Africa/Harare', offSet: '+02:00' },
  { identifier: 'Africa/Johannesburg', offSet: '+02:00' },
  { identifier: 'Africa/Juba', offSet: '+03:00' },
  { identifier: 'Africa/Kampala', offSet: '+03:00' },
  { identifier: 'Africa/Khartoum', offSet: '+02:00' },
  { identifier: 'Africa/Kigali', offSet: '+02:00' },
  { identifier: 'Africa/Kinshasa', offSet: '+01:00' },
  { identifier: 'Africa/Lagos', offSet: '+01:00' },
  { identifier: 'Africa/Libreville', offSet: '+01:00' },
  { identifier: 'Africa/Lome', offSet: '+00:00' },
  { identifier: 'Africa/Luanda', offSet: '+01:00' },
  { identifier: 'Africa/Lubumbashi', offSet: '+02:00' },
  { identifier: 'Africa/Lusaka', offSet: '+02:00' },
  { identifier: 'Africa/Malabo', offSet: '+01:00' },
  { identifier: 'Africa/Maputo', offSet: '+02:00' },
  { identifier: 'Africa/Maseru', offSet: '+02:00' },
  { identifier: 'Africa/Mbabane', offSet: '+02:00' },
  { identifier: 'Africa/Mogadishu', offSet: '+03:00' },
  { identifier: 'Africa/Monrovia', offSet: '+00:00' },
  { identifier: 'Africa/Nairobi', offSet: '+03:00' },
  { identifier: 'Africa/Ndjamena', offSet: '+01:00' },
  { identifier: 'Africa/Niamey', offSet: '+01:00' },
  { identifier: 'Africa/Nouakchott', offSet: '+00:00' },
  { identifier: 'Africa/Ouagadougou', offSet: '+00:00' },
  { identifier: 'Africa/Porto-Novo', offSet: '+01:00' },
  { identifier: 'Africa/Sao_Tome', offSet: '+00:00' },
  { identifier: 'Africa/Tripoli', offSet: '+02:00' },
  { identifier: 'Africa/Tunis', offSet: '+01:00' },
  { identifier: 'Africa/Windhoek', offSet: '+02:00' },
  { identifier: 'America/Adak', offSet: '-10:00' },
  { identifier: 'America/Anchorage', offSet: '-09:00' },
  { identifier: 'America/Anguilla', offSet: '-04:00' },
  { identifier: 'America/Antigua', offSet: '-04:00' },
  { identifier: 'America/Araguaina', offSet: '-03:00' },
  { identifier: 'America/Argentina/Buenos_Aires', offSet: '-03:00' },
  { identifier: 'America/Argentina/Catamarca', offSet: '-03:00' },
  { identifier: 'America/Argentina/Cordoba', offSet: '-03:00' },
  { identifier: 'America/Argentina/Jujuy', offSet: '-03:00' },
  { identifier: 'America/Argentina/La_Rioja', offSet: '-03:00' },
  { identifier: 'America/Argentina/Mendoza', offSet: '-03:00' },
  { identifier: 'America/Argentina/Rio_Gallegos', offSet: '-03:00' },
  { identifier: 'America/Argentina/Salta', offSet: '-03:00' },
  { identifier: 'America/Argentina/San_Juan', offSet: '-03:00' },
  { identifier: 'America/Argentina/San_Luis', offSet: '-03:00' },
  { identifier: 'America/Argentina/Tucuman', offSet: '-03:00' },
  { identifier: 'America/Argentina/Ushuaia', offSet: '-03:00' },
  { identifier: 'America/Aruba', offSet: '-04:00' },
  { identifier: 'America/Asuncion', offSet: '-04:00' },
  { identifier: 'America/Atikokan', offSet: '-05:00' },
  { identifier: 'America/Bahia', offSet: '-03:00' },
  { identifier: 'America/Bahia_Banderas', offSet: '-06:00' },
  { identifier: 'America/Barbados', offSet: '-04:00' },
  { identifier: 'America/Belem', offSet: '-03:00' },
  { identifier: 'America/Belize', offSet: '-06:00' },
  { identifier: 'America/Blanc-Sablon', offSet: '-04:00' },
  { identifier: 'America/Boa_Vista', offSet: '-04:00' },
  { identifier: 'America/Bogota', offSet: '-05:00' },
  { identifier: 'America/Boise', offSet: '-07:00' },
  { identifier: 'America/Cambridge_Bay', offSet: '-07:00' },
  { identifier: 'America/Campo_Grande', offSet: '-04:00' },
  { identifier: 'America/Cancun', offSet: '-05:00' },
  { identifier: 'America/Caracas', offSet: '-04:00' },
  { identifier: 'America/Cayenne', offSet: '-03:00' },
  { identifier: 'America/Cayman', offSet: '-05:00' },
  { identifier: 'America/Chicago', offSet: '-06:00' },
  { identifier: 'America/Chihuahua', offSet: '-07:00' },
  { identifier: 'America/Ciudad_Juarez', offSet: '-07:00' },
  { identifier: 'America/Costa_Rica', offSet: '-06:00' },
  { identifier: 'America/Creston', offSet: '-07:00' },
  { identifier: 'America/Cuiaba', offSet: '-04:00' },
  { identifier: 'America/Curacao', offSet: '-04:00' },
  { identifier: 'America/Danmarkshavn', offSet: '+00:00' },
  { identifier: 'America/Dawson', offSet: '-08:00' },
  { identifier: 'America/Dawson_Creek', offSet: '-07:00' },
  { identifier: 'America/Denver', offSet: '-07:00' },
  { identifier: 'America/Detroit', offSet: '-05:00' },
  { identifier: 'America/Dominica', offSet: '-04:00' },
  { identifier: 'America/Edmonton', offSet: '-07:00' },
  { identifier: 'America/Eirunepe', offSet: '-05:00' },
  { identifier: 'America/El_Salvador', offSet: '-06:00' },
  { identifier: 'America/Fort_Nelson', offSet: '-07:00' },
  { identifier: 'America/Fortaleza', offSet: '-03:00' },
  { identifier: 'America/Glace_Bay', offSet: '-03:00' },
  { identifier: 'America/Goose_Bay', offSet: '-03:00' },
  { identifier: 'America/Grand_Turk', offSet: '-04:00' },
  { identifier: 'America/Grenada', offSet: '-04:00' },
  { identifier: 'America/Guadeloupe', offSet: '-04:00' },
  { identifier: 'America/Guatemala', offSet: '-06:00' },
  { identifier: 'America/Guayaquil', offSet: '-05:00' },
  { identifier: 'America/Guyana', offSet: '-04:00' },
  { identifier: 'America/Halifax', offSet: '-03:00' },
  { identifier: 'America/Havana', offSet: '-05:00' },
  { identifier: 'America/Hermosillo', offSet: '-07:00' },
  { identifier: 'America/Indiana/Indianapolis', offSet: '-05:00' },
  { identifier: 'America/Indiana/Knox', offSet: '-06:00' },
  { identifier: 'America/Indiana/Marengo', offSet: '-05:00' },
  { identifier: 'America/Indiana/Petersburg', offSet: '-05:00' },
  { identifier: 'America/Indiana/Tell_City', offSet: '-06:00' },
  { identifier: 'America/Indiana/Vevay', offSet: '-05:00' },
  { identifier: 'America/Indiana/Vincennes', offSet: '-05:00' },
  { identifier: 'America/Indiana/Winamac', offSet: '-05:00' },
  { identifier: 'America/Inuvik', offSet: '-07:00' },
  { identifier: 'America/Iqaluit', offSet: '-04:00' },
  { identifier: 'America/Jamaica', offSet: '-05:00' },
  { identifier: 'America/Juneau', offSet: '-08:00' },
  { identifier: 'America/Kentucky/Louisville', offSet: '-05:00' },
  { identifier: 'America/Kentucky/Monticello', offSet: '-05:00' },
  { identifier: 'America/Kralendijk', offSet: '-04:00' },
  { identifier: 'America/La_Paz', offSet: '-04:00' },
  { identifier: 'America/Lima', offSet: '-05:00' },
  { identifier: 'America/Los_Angeles', offSet: '-08:00' },
  { identifier: 'America/Lower_Princes', offSet: '-04:00' },
  { identifier: 'America/Maceio', offSet: '-03:00' },
  { identifier: 'America/Managua', offSet: '-06:00' },
  { identifier: 'America/Manaus', offSet: '-04:00' },
  { identifier: 'America/Marigot', offSet: '-04:00' },
  { identifier: 'America/Martinique', offSet: '-04:00' },
  { identifier: 'America/Matamoros', offSet: '-06:00' },
  { identifier: 'America/Mazatlan', offSet: '-07:00' },
  { identifier: 'America/Menominee', offSet: '-06:00' },
  { identifier: 'America/Merida', offSet: '-06:00' },
  { identifier: 'America/Metlakatla', offSet: '-08:00' },
  { identifier: 'America/Mexico_City', offSet: '-06:00' },
  { identifier: 'America/Miquelon', offSet: '-03:00' },
  { identifier: 'America/Moncton', offSet: '-03:00' },
  { identifier: 'America/Monterrey', offSet: '-06:00' },
  { identifier: 'America/Montevideo', offSet: '-03:00' },
  { identifier: 'America/Montserrat', offSet: '-04:00' },
  { identifier: 'America/Nassau', offSet: '-05:00' },
  { identifier: 'America/New_York', offSet: '-05:00' },
  { identifier: 'America/Nome', offSet: '-09:00' },
  { identifier: 'America/Noronha', offSet: '-02:00' },
  { identifier: 'America/North_Dakota/Beulah', offSet: '-06:00' },
  { identifier: 'America/North_Dakota/Center', offSet: '-06:00' },
  { identifier: 'America/North_Dakota/New_Salem', offSet: '-06:00' },
  { identifier: 'America/Nuuk', offSet: '-03:00' },
  { identifier: 'America/Ojinaga', offSet: '-07:00' },
  { identifier: 'America/Panama', offSet: '-05:00' },
  { identifier: 'America/Paramaribo', offSet: '-03:00' },
  { identifier: 'America/Phoenix', offSet: '-07:00' },
  { identifier: 'America/Port-au-Prince', offSet: '-05:00' },
  { identifier: 'America/Port_of_Spain', offSet: '-04:00' },
  { identifier: 'America/Porto_Velho', offSet: '-04:00' },
  { identifier: 'America/Puerto_Rico', offSet: '-04:00' },
  { identifier: 'America/Punta_Arenas', offSet: '-03:00' },
  { identifier: 'America/Rainy_River', offSet: '-06:00' },
  { identifier: 'America/Rankin_Inlet', offSet: '-06:00' },
  { identifier: 'America/Recife', offSet: '-03:00' },
  { identifier: 'America/Regina', offSet: '-06:00' },
  { identifier: 'America/Resolute', offSet: '-06:00' },
  { identifier: 'America/Rio_Branco', offSet: '-05:00' },
  { identifier: 'America/Santarem', offSet: '-03:00' },
  { identifier: 'America/Santiago', offSet: '-04:00' },
  { identifier: 'America/Santo_Domingo', offSet: '-04:00' },
  { identifier: 'America/Sao_Paulo', offSet: '-03:00' },
  { identifier: 'America/Scoresbysund', offSet: '-01:00' },
  { identifier: 'America/Sitka', offSet: '-09:00' },
  { identifier: 'America/St_Barthelemy', offSet: '-04:00' },
  { identifier: 'America/St_Johns', offSet: '-03:30' },
  { identifier: 'America/St_Kitts', offSet: '-04:00' },
  { identifier: 'America/St_Lucia', offSet: '-04:00' },
  { identifier: 'America/St_Thomas', offSet: '-04:00' },
  { identifier: 'America/St_Vincent', offSet: '-04:00' },
  { identifier: 'America/Swift_Current', offSet: '-06:00' },
  { identifier: 'America/Tegucigalpa', offSet: '-06:00' },
  { identifier: 'America/Thule', offSet: '-04:00' },
  { identifier: 'America/Thunder_Bay', offSet: '-05:00' },
  { identifier: 'America/Tijuana', offSet: '-08:00' },
  { identifier: 'America/Toronto', offSet: '-05:00' },
  { identifier: 'America/Tortola', offSet: '-04:00' },
  { identifier: 'America/Vancouver', offSet: '-08:00' },
  { identifier: 'America/Whitehorse', offSet: '-08:00' },
  { identifier: 'America/Winnipeg', offSet: '-06:00' },
  { identifier: 'America/Yakutat', offSet: '-09:00' },
  { identifier: 'America/Yellowknife', offSet: '-07:00' },
  { identifier: 'Antarctica/Casey', offSet: '+08:00' },
  { identifier: 'Antarctica/Davis', offSet: '+07:00' },
  { identifier: 'Antarctica/DumontDUrville', offSet: '+10:00' },
  { identifier: 'Antarctica/Macquarie', offSet: '+11:00' },
  { identifier: 'Antarctica/Mawson', offSet: '+05:00' },
  { identifier: 'Antarctica/McMurdo', offSet: '+13:00' },
  { identifier: 'Antarctica/Palmer', offSet: '-03:00' },
  { identifier: 'Antarctica/Rothera', offSet: '-03:00' },
  { identifier: 'Antarctica/Syowa', offSet: '+03:00' },
  { identifier: 'Antarctica/Troll', offSet: '+02:00' },
  { identifier: 'Antarctica/Vostok', offSet: '+06:00' },
  { identifier: 'Arctic/Longyearbyen', offSet: '+01:00' },
  { identifier: 'Asia/Aden', offSet: '+03:00' },
  { identifier: 'Asia/Almaty', offSet: '+06:00' },
  { identifier: 'Asia/Amman', offSet: '+02:00' },
  { identifier: 'Asia/Anadyr', offSet: '+12:00' },
  { identifier: 'Asia/Aqtau', offSet: '+05:00' },
  { identifier: 'Asia/Aqtobe', offSet: '+05:00' },
  { identifier: 'Asia/Ashgabat', offSet: '+05:00' },
  { identifier: 'Asia/Atyrau', offSet: '+05:00' },
  { identifier: 'Asia/Baghdad', offSet: '+03:00' },
  { identifier: 'Asia/Bahrain', offSet: '+03:00' },
  { identifier: 'Asia/Baku', offSet: '+04:00' },
  { identifier: 'Asia/Bangkok', offSet: '+07:00' },
  { identifier: 'Asia/Barnaul', offSet: '+07:00' },
  { identifier: 'Asia/Beirut', offSet: '+02:00' },
  { identifier: 'Asia/Bishkek', offSet: '+06:00' },
  { identifier: 'Asia/Brunei', offSet: '+08:00' },
  { identifier: 'Asia/Chita', offSet: '+09:00' },
  { identifier: 'Asia/Choibalsan', offSet: '+08:00' },
  { identifier: 'Asia/Kolkata', offSet: '+05:30' },
  { identifier: 'Asia/Damascus', offSet: '+02:00' },
  { identifier: 'Asia/Dhaka', offSet: '+06:00' },
  { identifier: 'Asia/Dili', offSet: '+09:00' },
  { identifier: 'Asia/Dubai', offSet: '+04:00' },
  { identifier: 'Asia/Dushanbe', offSet: '+05:00' },
  { identifier: 'Asia/Famagusta', offSet: '+02:00' }
]

export default timezoneData
