import { createSlice } from '@reduxjs/toolkit'
import { reducers } from './orgUsersReducer'
import { extraReducers } from './orgUsersExtraReducer'

export const initialState = {
  orgUsers: {
    status: 'idle',
    data: {}
  }
}

const orgUsersSlice = createSlice({
  name: 'orgUsers',
  initialState,
  reducers,
  extraReducers
})

export const { setUsersOfOrg } = orgUsersSlice.actions
export default orgUsersSlice.reducer
