import React from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import WorkspacesComponent from './WorkspacesComponent.tsx'

function SelectedWorkspace({ orgId }) {
  const { orgObj } = useCustomSelector((state) => ({
    orgObj: state?.orgs?.orgs?.[orgId]
  }))

  return <WorkspacesComponent isModal selectedName={orgObj?.name} />
}
export default React.memo(addUrlDataHoc(React.memo(SelectedWorkspace), [ParamsEnums.orgId, ParamsEnums.projectId]))
