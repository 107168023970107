export const reducers = {
  setUsersOfOrg(state, action) {
    const usersData = action.payload.users.data.data
    const usersDataObj = {}
    usersData.forEach((element) => {
      usersDataObj[element.id] = element
    })
    state.data = usersDataObj
  }
}
