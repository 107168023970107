import React, { useMemo } from 'react'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import FeaturedCombinationsComponent from './FeaturedCombinationsComponent.tsx'
import { MiscTypes, ParamsEnums } from '../../../enums'
import { useCustomSelector } from '../../../utils/deepCheckSelector'

function CustomCombinationsComponent({ createflow, enabledServiceObject, projectId, orgId, editor = false }) {
  const { integrationConfig } = useCustomSelector((state) => ({
    integrationConfig:
      state.appInfo.mode === MiscTypes.EMBED_MODE
        ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config
        : state.appInfo.localConfiguration
  }))
  const filter = useMemo(() => {
    let eventsarray: Array<string> = []
    if (integrationConfig?.featuredCombinations) {
      Object.keys(integrationConfig?.featuredCombinations || {})?.forEach((combination) => {
        eventsarray = [...eventsarray, ...combination?.split('_')]
      })
    }
    return { rowid: eventsarray }
  }, [integrationConfig?.featuredCombinations])
  return filter ? (
    <FeaturedCombinationsComponent
      createflow={createflow}
      filter={filter}
      combinations={integrationConfig?.featuredCombinations}
      enabledServiceObject={enabledServiceObject}
      editor={editor}
    />
  ) : null
}
export default React.memo(addUrlDataHoc(React.memo(CustomCombinationsComponent), [ParamsEnums.orgId, ParamsEnums.projectId]))
