import { Box, Skeleton } from '@mui/material'

export function ServicePageSkeleton() {
  return (
    <Box className='flex gap-2 flex-wrap'>
      <Skeleton variant='rounded' width={210} height={60} />
      <Skeleton variant='rounded' width={340} height={60} />
      <Skeleton variant='rounded' width={280} height={60} />
      <Skeleton variant='rounded' width={390} height={60} />
      <Skeleton variant='rounded' width={110} height={60} />
      <Skeleton variant='rounded' width={160} height={60} />
      <Skeleton variant='rounded' width={360} height={60} />
      <Skeleton variant='rounded' width={290} height={60} />
      <Skeleton variant='rounded' width={170} height={60} />
      <Skeleton variant='rounded' width={250} height={60} />
      <Skeleton variant='rounded' width={170} height={60} />
      <Skeleton variant='rounded' width={190} height={60} />
      <Skeleton variant='rounded' width={180} height={60} />
      <Skeleton variant='rounded' width={160} height={60} />
      <Skeleton variant='rounded' width={210} height={60} />
      <Skeleton variant='rounded' width={340} height={60} />
      <Skeleton variant='rounded' width={280} height={60} />
      <Skeleton variant='rounded' width={390} height={60} />
      <Skeleton variant='rounded' width={110} height={60} />
      <Skeleton variant='rounded' width={160} height={60} />
      <Skeleton variant='rounded' width={360} height={60} />
      <Skeleton variant='rounded' width={190} height={60} />
    </Box>
  )
}
