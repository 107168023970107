import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import config from '../config'
import { getCurrentEnvironment, setInCookies } from '../utils/utilities'
import { MiscTypes } from '../enums'
import { updateAppInfoState } from '../store/appInfo/appInfoSlice.ts'

function WithAuth({ children }) {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const dispatch = useDispatch()
  // Convert searchParams to an array and use array iteration
  window.addEventListener('message', (event) => {
    const receivedData = event.data
    try {
      if (typeof receivedData === 'string' && JSON.parse(receivedData)) {
        if (JSON.parse(receivedData)?.viasocketEmbedToken) {
          navigate(`${config.verficationEmbed}?viasocketEmbedDetails=${receivedData}`)
        } else if (JSON.parse(receivedData)?.intefaceDetails) {
          navigate(`/i?intefaceDetails=${receivedData}`)
        }
      }
    } catch (error) {
      console.error(error)
    }
  })

  const domain = getCurrentEnvironment()

  Array.from(searchParams.entries()).forEach(([key, value]) => {
    if (key === 'proxy_auth_token') {
      localStorage.setItem(key, value)
      key = domain
      dispatch(updateAppInfoState({ mode: MiscTypes.FLOW }))
    }
    setInCookies(key, value)
  })

  return children
}
export default WithAuth
